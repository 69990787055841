<template>
    <button class="cancel-button"><slot></slot></button>
</template>

<script>


export default {
  components: {

  },
  props: [],

  data() {
    return {}
  },

  methods: {
    
  },

  computed: {
    
  },

  mounted() {

  },
};

</script>

<style scoped lang="scss">
.cancel-button {
  color: $blue;
  text-decoration: underline;
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px;
  
}
</style>
  