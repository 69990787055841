<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
    <transition name="modal">
        <div class="modal-mask" v-show="show">
            <div class="modal-container">
                <div class="modal-body">
                  <slot></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>



export default {
  components: {},

  props: {"show":{default:false}},

  data() {
    return {

    };
  },

  watch: {

  },

  methods: {
    savePost(){
      this.$emit('close');
    }
  },

  computed: {


  },

  mounted() {

  },
};
</script>

<style scoped lang="scss">


    HTML CSS JS

    Result
    Skip Results Iframe

EDIT ON

* {
    box-sizing: border-box;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
}

.modal-container {
    width: 90%;
    max-width: 500px;
    height: 90%;
    max-height: 775px;
    overflow: auto;
    margin: 40px auto 0;
    background-color: $white;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  height: auto;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.text-right {
    text-align: right;
}

.form-label {
    display: block;
    margin-bottom: 1em;
}

.form-label > .form-control {
    margin-top: 0.5em;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.5em 1em;
    line-height: 1.5;
    border: 1px solid #ddd;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}



</style>
