<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div>
    <dashboard></dashboard>
  </div>
</template>

<script>
// import Component from "../component_location"
import Dashboard from '@/views/Dashboard.vue'
import { user, verifyAuthenticated,signOut } from "@/scripts/Authentication.js";

export default {
  components: {Dashboard},

  props: [],

  data() {
    return {
    };
  },

  watch: {},

  methods: {
  },

  computed: {},


  mounted() {
    console.log(this.$route.meta.sign_out)
    if(this.$route.meta.sign_out){
      console.log("Signing Out")
       signOut()
    }
  },
};
</script>

<style></style>
