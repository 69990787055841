<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>

<drop-down-menu title="Portal" :links="portal_menu">
</drop-down-menu>



</template>

<script>
import DropDownMenu from "../components/DropDownMenu.vue"
import Breadcrumbs from "../components/Breadcrumbs.vue"
import NavbarDash from "../components/NavbarDash.vue"

export default {
  components: { DropDownMenu, Breadcrumbs, NavbarDash },

  props: [],

  data() {
    return {
      portal_menu:[{name:'one',url:'/create'},{name:'two',url:'/'}]
    };
  },

  watch: {},

  methods: {
  },

  computed: {},


  mounted() {},
};
</script>

<style>

</style>
