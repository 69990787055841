<template>
  <nav class="mt-6 pr-4 border-r border-gray-300">
    <ul class="overflow-hidden">
      <li class="relative pb-10" v-for="(step, index) in steps">
        <!-- </li> -->
        <!-- <li class="relative pb-10"> -->
        <!-- Complete Step -->
        <div
          v-if="
            currentStep > index || (currentStep == 0 && index != nbSteps - 1)
          "
          class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-blue-600"
        ></div>

        <div
          v-if="currentStep <= index && index != nbSteps - 1"
          class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
        ></div>

        <a
          href="#"
          class="relative flex items-start space-x-4 group
              focus:outline-none"
        >
          <div class="h-9 flex items-center" v-if="index < currentStep">
            <span
              class="relative z-10 w-8 h-8 flex items-center justify-center
              bg-blue-600 rounded-full group-hover:bg-blue-800
              group-focus:bg-blue-800 transition ease-in-out duration-150"
            >
              <!-- Heroicon name: check -->
              <svg
                class="w-5 h-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1
                  1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </div>

          <div class="h-9 flex items-center" v-if="index == currentStep">
            <span
              class="relative z-10 w-8 h-8 flex items-center justify-center
                  bg-white border-2 border-blue-600 rounded-full"
            >
              <span class="h-2.5 w-2.5 bg-blue-600 rounded-full"></span>
            </span>
          </div>

          <!-- GREATER THAN CURRENT STEP -->
          <div class="h-9 flex items-center" v-if="index > currentStep">
            <span
              class="relative z-10 w-8 h-8 flex items-center justify-center
                  bg-white border-2 border-gray-300 rounded-full
                  group-hover:border-gray-400 group-focus:border-gray-400
                  transition ease-in-out duration-150"
            >
              <span
                class="h-2.5 w-2.5 bg-transparent rounded-full
                    group-hover:bg-gray-300 group-focus:bg-gray-300 transition
                    ease-in-out duration-150"
              ></span>
            </span>
          </div>

          <div class="min-w-0">
            <h3 class="text-xs leading-4 font-semibold uppercase tracking-wide">
              {{ step.title }}
            </h3>
            <p class="text-sm leading-5 text-gray-500">
              {{ step.description }}
            </p>
          </div>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
// import Component from "../component_location"

export default {
  components: {},

  props: ['steps', 'currentStep'],

  data() {
    return {};
  },

  watch: {},

  methods: {},

  computed: {
    nbSteps() {
      return this.steps.length;
    },
  },

  mounted() {},
};
</script>

<style></style>
