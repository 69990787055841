<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div
    class=" w-1/2 mt-4 overflow-hidden
    sm:rounded-md mb-6"
  >
    <ul
      class="p-4 border border-blue-500 overflow-scroll rounded-md"
      style="min-height: 600px"
    >
      <li class="border border-gray-200 mb-6 rounded-md" v-for="(repo, index) in repos">
        <router-link
          :to="{
            name: 'Repo',
            params: { username: username, name: repo.name },
          }"
          class="block hover:bg-blue-100 focus:outline-none focus:bg-gray-50
          transition duration-150 ease-in-out"
        >
          <div class="px-4 py-4 sm:px-6">
            <div class="flex items-center justify-between">
              <div
                class="text-lg leading-5 font-medium font-semibold
                text-blue-600 truncate"
              >
                {{ repo.name }}
              </div>
              <div class="ml-2 flex-shrink-0 flex">
                <span
                  class="px-2 inline-flex text-xs leading-5 font-semibold
                  uppercase rounded-full bg-blue-200 text-green-800"
                >
                  {{ repo.privacySetting }}
                </span>
              </div>
            </div>

            <div class="pt-1 text-gray-700">
              {{ repo.description }}
            </div>

            <div class="mt-2 sm:flex sm:justify-between">
              <div class="sm:flex">
                <div
                  class="mr-6 px-2 py-1 rounded flex items-center text-sm
                  text-gray-100 bg-blue-400"
                  v-for="(tag, index) in repo.tags.split(',')"
                >
                  {{ tag }}
                </div>
              </div>
              <div
                class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0"
              >
                <!-- Heroicon name: calendar -->
                <svg
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2
                    0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0
                    00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span>
                  Created on
                  <time datetime="2020-11-15">{{
                    repo.createdAt.slice(0, 10)
                  }}</time>
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
// import Component from "../component_location"

export default {
  components: {},

  props: ["username", "repos"],

  data() {
    return {};
  },

  watch: {},

  methods: {},

  computed: {},

  mounted() {},
};
</script>

<style></style>
