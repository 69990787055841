<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <main class="ml-4 mb-4 w-1/2 mt-6 ">
    HI
    <ul>
      <li
        class="mb-6 border border-gray-400 shadow bg-gray-50 rounded-md"
        v-for="(desc, index) in dataset.descriptors"
        v-if="desc.name == 'description'"
      >
        <div class="p-4">
          <label
            for="dataType"
            class="block text-sm mb-1 leading-5 font-medium text-gray-600 w-2/3"
            >{{ desc.name.toUpperCase() }}</label
          >
          <textarea
            v-model="desc.description"
            class=" border border-gray-600 text-gray-500 rounded-md w-full p-2"
          ></textarea>
        </div>

        <div class="px-4 pb-4">
          <label
            for="dataType"
            class="block mb-1 text-sm leading-5 font-medium text-gray-600 w-2/3"
            >Example image</label
          >
          <span class="text-gray-400">
            {{ desc.examples }}
            <img class="rounded-md shadow-md" :src="dataset.files[0].url" />
          </span>
        </div>
      </li>
      <div class="flex justify-end">
        <span class="ml-0 mt-2 inline-flex rounded-md shadow-sm">
          <button
            @click="completeAnnotations"
            class="inline-flex justify-center py-2 px-4 border border-transparent
          text-sm leading-5 font-medium rounded-md text-white bg-light-blue-500
          hover:bg-light-blue-400 focus:outline-none
          focus:border-light-blue-600 focus:shadow-outline-blue
          active:bg-light-blue-600 transition duration-150 ease-in-out"
          >
            Complete annotations
          </button>
        </span>
      </div>
    </ul>
  </main>
</template>

<script>
// import Component from "../component_location"
import { reportError, EventBus } from "@/utils.js";

export default {
  components: {},

  props: ["dataset"],

  data() {
    return {};
  },

  watch: {},

  methods: {
    complete() {
      this.$emit("complete");
    },

    completeAnnotations() {
      this.$store.dispatch("datasets/completeAnnotations", this.dataset);
    },
  },

  computed: {},

  mounted() {
    EventBus.$on("annotations-complete", this.complete);
  },
};
</script>

<style></style>
