<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
	<div>
		<div class="edit_profile">
			<div class="left_panel"></div>
			<div class="right_panel">
				<div>
					<input-form title="City: " placeholder="City" size="65">{{ city }}</input-form>
					<!-- <label for="City">City</label>
					<br />
					<input
						class="form_control"
						size="75"
						type="text"
						placeholder="City"
					/> -->

					<input-form title="State: " placeholder="State" size="65">{{ state }}</input-form>
					<!-- <label for="State">State</label>
					<br />
					<input
						class="form_control"
						size="75"
						type="text"
						placeholder="State"
					/> -->

					<input-form title="Zip Code: " placeholder="Zip Code" size="65">{{ zip_code }}</input-form>
					<!-- <label for="Zip Code">Zip Code</label>
					<br />
					<input
						class="form_control"
						size="75"
						type="text"
						placeholder="Zip Code"
					/> -->
					<!-- <button type="button">
					Update Account
				</button> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import InputForm from "@/views/sandbox/components/input_form.vue";
	export default {
		components: { InputForm },

		props: {},

		data() {
			return {
				city: "",
				state: "",
				zip_code: "",
			};
		},

		updated: function() {},
		watch: {},
		methods: {},

		computed: {},

		mounted() {},
	};
</script>

<style scoped>
	input {
		margin-bottom: 10px;
	}
	.change_pic {
		text-align: center;
		color: #afafaf;
	}
	.edit_profile {
		display: flex;
		flex-flow: row wrap;
		max-width: 1550px;
	}

	.right_panel {
		max-width: 800px;
	}

	.left_panel {
		padding: 10px;
		width: 300px;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
	}
	.container {
		display: flex;
		flex-flow: row wrap;
		max-width: 800px;
	}

	.form_group {
		box-sizing: border-box;
		display: block;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 20px;
	}

	.form_control {
		font-size: 15px;
		line-height: 1.6;
		color: black;
		background-color: transparent;
		border-radius: 1px;
	}

	.form_control:focus {
		box-shadow: 0 0 5px rgba(81, 203, 238, 1);
		padding: 3px 0px 3px 3px;
		margin: 5px 1px 3px 0px;
		border: 1px solid rgba(81, 203, 238, 1);
	}

	/* button {
		position: relative;
		font-size: 20px;
		font-weight: 500;
		display: block;

		margin-left: 200px;
		margin-top: 30px;
		margin-bottom: 10px;
		color: white;
		background-color: grey;
		padding: 5px;
		border-radius: 10px;
		width: 35%;
		transition: all 300ms ease-in-out;
	}

	button:hover {
		background-color: #9e9e9e;
		box-shadow: 0 0 5px black;
	}

	button:focus{
		outline: 0;
		background-color: white;
		color: grey;
		border: 3px solid grey;
		border-radius: 10px;
		box-shadow: 0 0 5px black;
	}

	@media screen and (max-width: 605px) {

		button {

		margin: 0 auto;
		}

	}

	@media screen and (max-width: 360px) {
		button {
			font-size: 17px;
			font-weight: 500;
		}
	}

	@media screen and (max-width: 309px) {
		button {
			font-size: 15px;
			font-weight: 600;
		}
	} */
</style>
