<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>

<div>
  <modal :show="showModal" @close="showModal = false">

    <slot name="body">
      <narrative-settings-form title="Narrative Settings"
        description="Let’s get started by filling in the information below to create your new narrative."
        submit-button-text="Create this narrative"
        :loading="loading"
        :username="username"
        :narrative="narrative"
        :isNewNarrative="true"
        @save="createNarrative">
      </narrative-settings-form>
    </slot>

  </modal>

  <div class="top">
    <div class="head">
      <drag-icon @drag="drag" @drop="drop"></drag-icon>
    </div>
    <div ref="narrative" id="narrative" @resize="resize" class="narrative" @scroll="scroll">

      <div class="wrapper edit">
          <background :backgrounds="narrative.backgrounds"></background>
          <narrative  :content="narrative.content" ref="parent" :scrollTop="scrollTop" ></narrative>
      </div>
    </div>


    <!--<div class="foot">
        springmatter.com
    </div>-->

</div>
  <panel class="editor"></panel>
</div>







</template>

<script>
import NarrativeSettingsForm from '@/components/NarrativeSettingsForm.vue';
import Modal from '@/views/Narrator/modal.vue';
import Vue from 'vue';
import DragIcon from "@/views/Narrator/DragIcon.vue";
//import Placeholder from "@/views/prototype/Placeholder.vue"
import Narrative from "@/views/Narrator/Narrative.vue";
import Panel from "@//views/Narrator/panel.vue";
import Background from "@/views/Narrator/Background.vue";
import BackgroundOverlay from "@/views/Narrator/BackgroundOverlay.vue";
import { user } from "@/scripts/Authentication.js";
//import SettingsIcon from "@/views/prototype/SettingsIcon.vue"
//import Narrator from "@/views/narrator.vue"
//import dropzone from 'vue2-dropzone'
//import 'vue2-dropzone/dist/vue2Dropzone.min.css'

//var ComponentClass = Vue.extend(Placeholder)

window.$events = new Vue({})

const pointInRect = (x,y, rect) => (
  (x > rect.left && x < rect.right) && (y > rect.top && y < rect.bottom)
)



export default {
  components: {
    DragIcon,
    Panel,
    Narrative,
    Background,
    BackgroundOverlay,
    NarrativeSettingsForm,
    Modal,

  },

  props: {narrativeid:{default:"UNKNOWN"}},

  async mounted() {
    window.$editing = false;
    var self = this;

    window.$narrative_height = this.$refs.narrative.clientHeight;
    //document.body.onclick = this.click
    document.body.onmousedown = this.mouse_down;
    document.body.onmouseup = this.mouse_up;
    document.body.onmousemove = this.mouse_move;
    document.body.onmousehover = this.mouse_hover;

    $events.$on("save", this.save);
    $events.$on("image_added",this.image_added);
    $events.$on("delete", this.delete_element);

    this.narrative = {content:[]}

    //var default_content = {full:false, tags:"", content:[]}
    //this.narrative.content.push(default_content)
    console.log("this.narrativeid:", this.narrativeid)
    if(this.narrativeid=="UNKNOWN")
      this.showModal = true;
    else
    {
      var ret = await $samsa.load_narrative(this.narrativeid)

      if(ret!=null){

        this.narrative = ret;
        if(this.narrative.content.length==0)
          this.grow();
      }
      else {
        //this.$router.push({name:"New_Prototype"})
      }
    }

  },
  data() {
    return {
      selected:null,
      showModal:false,
      scrollTop:0,
      text:null,
      placeholder:null,
      loading:false,
      username:"",
      mouse_down_point:[0,0],
      narrative:{name:"", description:"", tags:"", privacySetting:"public", backgrounds:[], content:[], thumbnail:null},
      is_dragging: false,
      allow_drag: false,
      allow_edit: false
    };
  },

  watch: {
    async narrative_id(narrative_id,before){

      console.log("Narrative ID:", narrative_id)
      if(narrative_id == null || narrative_id == "UNKNOWN"){
        this.showModal = true;
      }
      else{
        var ret = await $samsa.load_narrative(narrative_id)
        console.log("Narrative ID:", narrative_id)

        if(ret!=null){
          console.log("Narrative Loaded: ", ret)
          this.narrative = ret;
          if(this.narrative.content.length == 0)
            this.grow();

        }
        else {
          //this.$router.push({name:"Narrator"})
        }
    }
  }
  },
  methods: {
    delete_element(element) {
      const index = this.narrative.content.indexOf(element);

      if (index > -1) {
        this.narrative.content.splice(index, 1);
      }

    },
    resize(ev){
      console.log("HEIGHT:", this.$refs.narrative.clientHeight)
    },
    mouse_hover(ev){
      console.log("Mouse Hover!")
    },
    mouse_move(ev){
      if(!this.allow_drag)
        return;
      if(!this.is_dragging && ev.buttons == 1){
        let x = ev.clientX;
        let y = ev.clientY;
        let dist = Math.sqrt(Math.pow(x-this.mouse_down_point[0],2)+Math.pow(y-this.mouse_down_point[1],2))

        if (dist>50 && this.selected!=null){
          this.is_dragging = true;
          ev.preventDefault();
          window.$events.$emit("highlight", null)
          window.$events.$emit("move_start", this.selected.__vue__.content)
        }
      }
    },
    mouse_down(ev){
      //Need to check for editor and bail if it's in the element List.
      //ev.preventDefault();



      this.is_dragging = false;
      let x = ev.clientX;
      let y = ev.clientY;
      this.mouse_down_point = [x,y];
      var elements = document.elementsFromPoint(x, y);

      let mode = "any"
      elements.forEach((item, i) => {
        if(item.tagName=='P' || item.tagName=='LI')
          mode = "text"
        if(item.tagName=='BUTTON')
        {
          mode = "break"
        }
      });
      if(mode=="break"){
        ev.preventDefault();
        return
      }

      let editorFound = false;
      elements.some((item, i) => {

        if(item.classList.contains("cd-panel__content")){
          console.log("Editor!")
          editorFound = true;
          this.allow_edit = false;
          this.allow_drag = false;
          return true;
        }
        if(item.classList.contains(mode)){
          if(item == this.selected){
            editorFound = true;
            this.allow_edit = true;
          }
          else{
            this.select(item)
            this.allow_edit = true;
          }
          this.allow_drag = true;
          ev.preventDefault();
          return true;
        }
      });

      if($editing && !editorFound){
        console.log("Closing")
        $events.$emit("close_edit");
        this.allow_edit = false;
        return true;
      }
    },
    mouse_up(ev){
      let x = ev.clientX;
      let y = ev.clientY;

      var elements = document.elementsFromPoint(x, y);
      var mode = ""
      elements.forEach((item, i) => {
        if(item.tagName=='BUTTON')
        {
          mode = "break"
        }
      });
      if(mode=="break"){
        ev.preventDefault();
        return
      }

      let dist = Math.sqrt((x-this.mouse_down_point[0])**2-(y-this.mouse_down_point[1])**2)

      if(dist < 10 && this.allow_edit)
        window.$events.$emit("edit", this.selected.__vue__.content)
        //this.select(null);

    },
    select(item){
        this.selected = item;
        window.$events.$emit("highlight", item);
    },
    image_added(image_url){
      console.log("Adding Thumbnail", image_url)
      if(this.narrative.thumbnail == null || this.narrative.thumbnail == ""){
        console.log("Thumbnail added!")
        this.narrative.thumbnail = image_url
        this.save()
      }
      else {
        console.log("existing thumbnail", this.narrative.thumbnail)
      }
    },
    async createNarrative(config){
      console.log("Creating New Narrative:", config)

      var options = {
        narrative_id:"",
        name:config.name,
        user_id: $data.user.username,
        tags: config.tags,
        description: config.description,
        privacySetting: config.privacySetting,
        tags: config.tags}


      this.showModal = false;

      var ret = await $samsa.create_narrative(options);
      this.narrative = ret
      console.log(ret)

      // named route
      console.log("EDIT NA")
      this.$router.push({ name: 'EditNarrator', params: { narrative_id: ret.narrativeId } })
    },

    save(){
      console.log("Saving: ", this.narrative)
      let ret = $samsa.save_narrative(this.narrative)
    },
    load(){
      var self = this;
      this.tiles = JSON.parse(this.save)

    },
    addBackground(y){
      var elements = document.elementsFromPoint(10, y);
      var blank = null;
      for (var idx in elements){
        if(elements[idx].classList.contains("dropped")){
          blank = elements[idx];
          break
        }
      }
      if(blank!=null){
        console.log("Adding Background: ", blank.__vue__)
        var cy = blank.getBoundingClientRect()
        console.log("y: ", y - cy.y)
      }

    },
    grow(tile){
      //var default_tile = {full:false, text_layer:{top:[],bottom:[],middle:[],left:[],right:[]}, image_layer:{top:[],bottom:[],middle:[],left:[],right:[]}}
      //this.narrative.content.push(default_tile)
    },
    scroll(x){
      this.scrollTop = this.$refs.narrative.scrollTop;
      console.log(this.scrollTop)
    },
    click(ev){
      console.log("CLICK",ev)
      var x = ev.clientX;
      var y = ev.clientY;
      //var elements = document.elementsFromPoint(x, y);

      //for(var idx in elements){
      //  console.log(elements[idx].id)
      //}

      //console.log("TOP LEVEL CLICK:", elements)
    },
    open(){
      console.log("OPEN")
      //this.$refs.dropzone.dropzone.hiddenFileInput.click()
    },
    success(){
      console.log("success")
    },
    complete(){
      console.log("complete")
    },
    drag(x,y, type, content) {

      this.selected = null;

      /*
      if(this.placeholder!=null){
        //var rect = this.placeholder.parentNode.parentNode.getBoundingClientRect()
        if(!pointInRect(x,y,this.placeholder.getBoundingClientRect())){
          this.placeholder.__vue__.clear()
          this.placeholder = null;
          return
        }
      }
      */

      if(this.placeholder==null){
        var elements = document.elementsFromPoint(x, y);
        var blank = null;
        for (var idx in elements){
          if(elements[idx].classList.contains("dropped")){
            blank = elements[idx];
            this.placeholder = blank;
            break;
            if(blank != this.placeholder){
              if(this.placeholder)
                this.placeholder.__vue__.clear()
              this.placeholder = blank;

              //CML- We can scroll if necessary... makes dragging stuff around wonky...
              //blank.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
              break
          }
        }
      }
    }

      if(this.placeholder){
        y += this.$refs.narrative.scrollTop;
        this.placeholder.__vue__.place(x,y,type, this.placeholder, content)
      }



      /*
      if(this.placeholder == null && elem == this.$refs.narrative){
        console.log("DRAGGING!!!",x,y)
        if(this.placeholder==null){
            console.log("Dropping Tile")
            var instance = new TileClass()
            instance.$mount()
            this.placeholder = instance
            document.getElementById("narrative").appendChild(instance.$el);
          }
      }

      console.log(elem)
      if(elem && elem.getAttribute("tile")){
        console.log("FOUND TILE",elem.__vue__)
        this.placeholder = elem.__vue__
      }

      if(this.placeholder && this.placeholder.dropped){
        console.log("PLACEHOLDER", this.placeholder.dropped)
        this.placeholder.ghost(x,y,type)
      }
      */
    },

    drop(type) {

      if(this.placeholder){
        this.placeholder.__vue__.drop(this.placeholder, type)
        this.placeholder=null;

    }
  }

  },

  computed: {},


};
</script>

<style scoped>

.wrapper{
  display:inline-block;
  position:relative;
  width:100%;
}

.top{
  display:flex;
  flex-direction: column;
  height:99.92vh;
  z-index:0;
}


.head{
  background-color:white;
 -webkit-box-shadow: 0px 0px 11px rgba(0, 0, 0, .15);
  box-shadow: 0px 0px 11px rgba(0, 0, 0, .15);
  height:72px;
  width:100%;
}

.narrative{
  height:100%;
  overflow-y:scroll;
  overflow-x: visible;
  z-index:0;
}


.element{
  background-color:#fafbfc;

}

.foot{
  background-color: #FAFBFC;
  height:50px;
  width:100%;
  padding:3px;
}

.txt{
width:100px;
height:100px;
background-color:green;
}

.parent {
  display: flex;
  height:200px;
  flex-direction: row;
  justify-content: center;
  border: 1px solid gray;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid orangered;
}

.parent:hover .content {
  flex: 1;
}

.dummy {
  flex: 0;
  transition: all 1s;
  border: 1px solid limegreen;
  background-color: green;
}

.parent:hover .hovered {
  flex: 1;
}

.tile{
background-color: #FAFBFC;
color:white;
transition: width 2s, transform 2s;
}

.row{
display:flex;
flex-direction:row;
margin:auto;
width:100%;
justify-content: space-around;
background-color:blue;
color:white;
}

.pardent{
  margin:0px;
  background-color:red;
  height:100%;
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
}

html, body, #app{

  width:100%;
  margin:0px;
}

body{
  display:flex;
  margin:5px;

}


.footer{

}
</style>
