<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div>
    <section id="titlechart">
      <div id="description">
        <h1 class="badge gsap">Custom Containers</h1>
        <h2>One page can have multiple scroll containers.</h2>
        <p>
          A scroll container can be any element (usually a div though), that has
          scrolling happening inside of it.
        </p>
        <p>
          While textareas have scrolling enabled by default, it needs to be
          explicitly set for divs, using the css style
          <code>overflow: scroll</code>.
        </p>
        <a href="#" class="viewsource">view source</a>
      </div>
    </section>
    <div class="spacer s2"></div>
    <div class="spacer s0"></div>
    <section class="demowrap">
      <div
        id="container1"
        class="scrollContainer"
        style="width: 500; height: 200px; border: 1px solid blue; overflow:scroll"
      >
        <section class="demo">
          <div class="spacer s2"></div>
          <div class="spacer s0" id="trigger1"></div>
          <div class="animated box1 red disablePointerEvents"  style='background-color:red'>
            <p>Wait for green.</p>
            <a href="#" class="viewsource">view source</a>
          </div>
        </section>
      </div>
    </section>
    <div class="spacer s3"></div>
    <div class="spacer s0"></div>
    <section class="demowrap">
      <div
        id="container2"
        class="scrollContainer horizontal"
        style="width: 500px; height: 300px; border: 1px solid blue;"
      >
        <section class="demo">
          <div class="spacer s4"></div>
          <div class="spacer s0" id="trigger2"></div>
          <div class="animated box1 red disablePointerEvents">
            <p>Wait for green.</p>
            <a href="#" class="viewsource">view source</a>
          </div>
          <div class="spacer s4"></div>
        </section>
      </div>
    </section>
    <div class="spacer s5"></div>
  </div>
  <!-- <div> -->
  <!--   <video -->
  <!--     class="w&#45;full h&#45;full object&#45;cover fixed" -->
  <!--     autoplay="autoplay" -->
  <!--     loop="loop" -->
  <!--     muted -->
  <!--   > -->
  <!--     <source src="@/assets/videos/time_lapse_sky_small.mp4" type="video/mp4" /> -->
  <!--   </video> -->
  <!--   <span class="text&#45;white text&#45;xl4 absolute" style="z&#45;index:1000"> -->
  <!--     Hello -->
  <!--   </span> -->
  <!-- </div> -->
</template>

<script>
// import Component from "../component_location"

export default {
  components: {},

  props: [],

  data() {
    return {};
  },

  watch: {},

  methods: {},

  computed: {},

  mounted() {
    var controller1 = new ScrollMagic.Controller({
      container: "#container1",
    });

    // build scene
    var scene = new ScrollMagic.Scene({
      triggerElement: "#trigger1",
      duration: 300,
    })
      .addTo(controller1)
      .setTween(
        TweenMax.to("#container1 .animated", 0.5, {
          backgroundColor: "green",
        })
      )
      .addIndicators() // add indicators (requires plugin)
      .setPin("#container1 .animated");
  },
};
</script>

<style>

.spacer  {
  height: 150px;
}

</style>
