<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
	<div>
		<div class="edit_profile">
			<div class="left_panel">
				<img v-if="user.picture" :src="user.picture" />
				<div v-else>
					{{ first_name }}
					<svg
						class="h-full w-full text-gray-300"
						fill="currentColor"
						viewBox="0 0 24 24"
					>
						<path
							d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
						/>
					</svg>
				</div>
				<div class="change_pic" @click="upload">
					change profile picture
				</div>
			</div>
			<div class="right_panel">
				<div class="container">
					<input-form
					title="First Name: "
					placeholder="First Name">
					{{  first_name  }}
					</input-form>

					<input-form
					title="Last Name: "
					placeholder="Last Name">
					{{  last_name  }}
					</input-form>
				</div>
				<div>
					<input-form
					title="Email Address: "
					placeholder="Email Address"
					size="65">
					{{ user.email }}
					</input-form>
					<!-- <label for="Email Address">Email Address: {{ user.email }}</label>
					<br /> -->
					<!-- <input
						class="form_control"
						size="75"
						type="text"
						placeholder="Email Address"
					/> -->
					<label class="form_group" for="Username"> Username: {{ user.username }}</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import InputForm from "@/views/sandbox/components/input_form.vue";
	import { openFilePicker, EventBus } from "@/utils.js";
	export default {
		components: { InputForm },

		props: {},

		data() {
			return {
				first_name: "",
				last_name: "",
			};
		},

		updated: function() {},
		watch: {},
		methods: {
			upload() {
				const client = openFilePicker();
				var acceptable;
				acceptable = ["image/*"];
				var options = {
					accept: acceptable,
					onUploadDone: this.filesUploaded,
					onCancel: (e) => {
						this.uploading = false;
					},
					maxFiles: 1,
					transformations: { crop: false, circle: false, rotate: false },
				};
				client.picker(options).open();
			},
			filesUploaded(fileInfo) {
				var self = this;
				var filesUploaded = fileInfo.filesUploaded;
				if (filesUploaded.length == 1) {
					console.log(self.user);
					self.user.picture = filesUploaded[0].url;
					$samsa.setProfilePic
						.post(this.user.username, filesUploaded[0].url)
						.then((res) => {});
				}
			},
		},

		computed: {
			user() {
				return this.$store.state.users.user;
			},
		},

		mounted() {},
	};
</script>

<style scoped>
	input {
		margin-bottom: 10px;
	}
	.change_pic {
		text-align: center;
		color: #afafaf;
	}
	.edit_profile {
		display: flex;
		flex-flow: row wrap;

		max-width: 1550px;
	}

	.right_panel {
		max-width: 800px;
	}

	.left_panel {
		padding: 10px;
		width: 300px;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
	}
	.container {
		display: flex;
		flex-flow: row wrap;
		max-width: 800px;
	}

	.form_group {
		box-sizing: border-box;
		display: block;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 20px;
	}

	.form_control {
		font-size: 15px;
		line-height: 1.6;
		color: black;
		background-color: transparent;

		border-radius: 1px;
	}
	.form_control:focus {
		box-shadow: 0 0 5px rgba(81, 203, 238, 1);
		padding: 3px 0px 3px 3px;
		margin: 5px 1px 3px 0px;
		border: 1px solid rgba(81, 203, 238, 1);
	}
</style>
