<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
	<div class="form_group">
		{{ title }}
		<div class="form-group">
			<input
			class="form_control"
			type="text"
			:size="size"
			@focus="show = !show"
			:value="value"
			@blur="show = !show"
			:placeholder="placeholder"
			@input="$emit('input', $event.target.value)"
		/>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			show: false,
		}),
		computed: {
			filled() {
				if (!this.show && this.value) {
					return "has-content";
				}
				return "";
			},
		},
		props: {
			value: { type: String, required: false, default: "" },
			title: { default: "title" },
			placeholder: { default: "" },
			size: { default: 33 },
		},
	};
</script>

<style scoped>
	input {
		width: calc(100% - 20px);

	}

	.form_control {
		display: block;
		width: 200%;
		height: 40px;
		padding: 6px 12px;
		font-size: 15px;
		line-height: 1.6;
		color: black;
		background-color: transparent;

		border-radius: 1px;
	}
	.form_group {
		box-sizing: border-box;
		display: block;
		margin-left: 20px;
		margin-bottom: 20px;
		margin-right: 20px;
	}

	.form_control:focus{
		box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  		padding: 3px 0px 3px 3px;
  		margin: 5px 1px 3px 0px;
 	    border: 1px solid rgba(81, 203, 238, 1);
	}
</style>
