<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
<div class="upload-component">
    <div v-if="uploadStart">
      <div class="progress-text">Please do not close the sidebar while uploading.</div>
      <div id="progressbar">
        <div :style="'width:' + progress + '%;'"></div>
      </div>
      <div class="progress-text">{{progress}}%</div>
    </div>
    <div v-else class="placeholder" @click="click">
        <img :src="imageType" />
        <div class="text">
        {{ uploadText }}
        </div>
        <input @change="filesChanged" ref="file_input" class="file_input" type="file" />
    </div>
    <div class="delete" @click="delete_element">Delete</div>
</div>

</template>

<script>
import { APIPREFIX } from "@/api.js";
export default {
  components: {},

  props: ["content", "uploadText", "iconType", "imageType"],

  data() {
    return {
      text:"",
      files:[],
      progress: 0,
      uploadStart: false
    };
  },

  watch: {
    // files(after,before){
    //   console.log("I HAVE FILES", after)
    // }
  },

  methods: {
    delete_element: function(   ){
      $events.$emit("delete", this.content);
    },
    click: function(){
      this.$refs.file_input.click();
    },
    filesChanged: function(ev){

      if(this.$refs.file_input.files.length > 0)
        this.getSignedRequest(this.$refs.file_input.files[0]);
    },
    //upload: function(file){

      //CML--- Should no longer be using this upload_video function call...
      // let self = this;
      // let url = APIPREFIX + "/upload_video"
      //
      // let formData = new FormData();
      // formData.append("file", file);
      // axios.post(url, formData, { headers: {'Content-Type': 'multipart/form-data'}}).then(function(ret) {
      //   self.$refs.img.src = ret.data.url;
      //   self.content.url = ret.data.url;
      //   window.$events.$emit("close_edit");
      // });
    // },
    getSignedRequest: async function(file){

      let ret = await $samsa.sign_s3(file.name,file.type);
      this.uploadFile(file, ret.data, ret.url);

    },
    uploadFile(file, s3Data, url){
      let self = this;

      let xhr = new XMLHttpRequest();
      xhr.open("POST", s3Data.url);

      var postData = new FormData();
      for(let key in s3Data.fields){
        postData.append(key, s3Data.fields[key]);
      }
      postData.append('file', file);
      console.log("PROGRESS");
      xhr.upload.onprogress = function(evt) {
        self.uploadStart = true;

        if(evt.lengthComputable) {
          self.progress = Math.floor((evt.loaded / evt.total) * 100);
        }
      }
      xhr.upload.onloadend = function() {
        self.uploadStart = false;
      }

      xhr.onreadystatechange = function() {
        if(xhr.readyState === 4){
          if(xhr.status === 200 || xhr.status === 204){
            console.log(this.content);
            self.content.url = url;
            $events.$emit("close_edit");
          }
          else{
            alert("Could not upload file.");
          }
       }
      };
      xhr.send(postData);
    },
  },

  computed: {

  },

  mounted() {

  },
};
</script>

<style scoped lang="scss">

.cd-panel__header {
  .close {
    color: #757575;
  }
}
.delete{
  display: inline-block;
  text-align: center;
  line-height: 1em;
  font-size: 1em;
  width: auto;
  height: auto;
  margin: 20px;
  background-color: #d20000;
  color: #ffffff;
  border: 0;
  border-radius: 5px;
  padding: 1em 4em;
  transition: all .3s ease-in;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 2em 5%;
  }
  &:hover{
    background-color: #fff;
    color: #d20000;
    cursor: pointer;
    transition: all .3s ease-in;
  }
}
.upload-component {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;

  i {
      font-size: 38px;
  }
  .placeholder {
    margin-top: 10px;
    color: #fff;
    font-size: 0.95em;
    img {
      width: 15%;
      margin: auto;
    }
    .text {
      color: #fff;
      text-align: center;
      margin: 7px auto;
    }
    input {
      background: none;
      border: 0;
      width: 70%;
      text-align: center;
      margin-left: 5em;
    }
  }


    #progressbar {
      background-color: $white;
      border: 1px solid $black;
      border-radius: 13px;
      padding: 3px;
    }

    #progressbar > div {
      background-color: $blue;
      height: 20px;
      border-radius: 10px;
    }
    .progress-text {
      font-weight: 600;
      font-size: 18px;
      text-align: center;
    }


}

.placeholder {
  padding: 10px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  overflow: hidden;
  width: 100%;

  .search{
    display:flex;
    flex-direction: row;
  }

  .search_image{
    height: 40px;
    margin-left: 5px;
    border: 1px solid $blue;
    background-color: $white;
    border-radius: 10px;
  }

  .search_input{
    width: 100%;
    margin-bottom: 5px;
    border-radius: 10px;
  }

  .file_input{
    display: none;
  }
}


</style>
