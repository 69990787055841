<template>
	<div>
		<div>
			<table>
				<thead>
					<tr>
						<td>
							Name:
							<input v-model="job_name" />
						</td>
						<td>
							Description:
							<input v-model="job_desc" />
						</td>

						<!--td>Employment Date:</!--td>-->
						<td>
							<button class="button" @click="addJob">Add</button>
						</td>
					</tr>
					<tr v-for="(job, index) in jobs.workHistory" :key="index">
						<td>
							{{ job.jobName }}
						</td>
						<td>
							{{ job.jobDesc }}
						</td>
						<td>
							<button class="button" @click="deleteJob">Remove</button>
						</td>
					</tr>
				</thead>
			</table>
		</div>
	</div>
</template>
<script>
	export default {
		components: {},

		props: ["jobs"],

		data() {
			return {
				job_name: "",
				job_desc: "",
			};
		},

		watch: {},

		methods: {
			addJob: function() {
				this.$emit("addJob", this.jobs, this.job_name, this.job_desc);
			},

			deleteJob: function() {
				this.$emit("deleteJob", this.jobs, this.job_name, this.job_desc);
			},
		},

		computed: {},

		mounted() {
			/*let ret = await $samsa.getDgreen("Darryl","Green");
	   let item = ret.data;
	   this.myArray = ret.data, ['firstname', 'lastname'];*/
		},
	};
</script>

<style>
	table {
		width: 100%;
	}
	table,
	th,
	td {
		border: 1px solid black;
		border-collapse: collapse;
	}
	th,
	td {
		padding: 15px;
		text-align: left;
	}
	#t01 tr:nth-child(even) {
		background-color: #eee;
	}
	#t01 tr:nth-child(odd) {
		background-color: #fff;
	}
	#t01 th {
		background-color: black;
		color: white;
	}
</style>
