<template>
  <div>
    <div class="input-container" v-for="(label, index) in labels" :key="index">
      <input
        :id="id + index"
        type="radio"
        class="input-container__radio"
        :name="name"
        :value="label.value"
        @input="updateText(label.value)"
      />
      <label :for="id + index" class="input-container__label">
        <span>{{ label.label }}</span>

        <i class="fas fa-check-circle"></i>
      </label>
    </div>
  </div>

</template>

<script>


export default {
  components: {

  },
  props: {"labels":{default: []}, "name":{default: ""}, "id":{default: ""}, "value":{default: ""}},

  data() {
    return{}
  },

  watch: {

  },

  methods: {
    updateText(text) {
      this.$emit('input', text);
    }
  },

  computed: {

  },

  mounted() {

  },
};

</script>

<style scoped lang="scss">
.input-container {

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $greyFont;
    border: 1px solid;
    border-color: #ccc #e0e0e0 #e0e0e0 #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 0;
  }

  &__radio {
    position: fixed;
    opacity: 0;
    width: 0;
  }

}

.input-container__radio:checked + .input-container__label {
  background: $blue;
  color: $white;
  border-color: lighten($blue, 25%);
}

</style>
