<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div
    class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6
    lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-20 w-auto"
        src="@/assets/images/Springmatter-Icon.png"
        alt="SPRINGMATTER LOGO"
      />
      <h2
        class="mt-6 text-center text-3xl leading-9 font-extrabold
        text-gray-400"
      >
        Sign in to Springmatter
      </h2>
      <p class="mt-2 text-center text-sm leading-5 text-gray-300 max-w"></p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form v-on:submit.prevent="signIn" method="POST">
          <p v-if="error" class="error">The credentials you entered are incorrect.<br />Please try again.</p>
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Email address
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                id="email"
                type="email"
                v-model="email"
                required
                class="appearance-none block w-full px-3 py-2 border
                border-gray-300 rounded-md placeholder-gray-400
                focus:outline-none focus:shadow-outline-blue
                focus:border-blue-300 transition duration-150 ease-in-out
                sm:text-sm sm:leading-5"
              />
            </div>
          </div>

          <div class="mt-6">
            <label
              for="password"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Password
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                id="password"
                type="password"
                v-model="password"
                required
                class="appearance-none block w-full px-3 py-2 border
                border-gray-300 rounded-md placeholder-gray-400
                focus:outline-none focus:shadow-outline-blue
                focus:border-blue-300 transition duration-150 ease-in-out
                sm:text-sm sm:leading-5"
              />
            </div>
          </div>

          <div class="mt-6 flex items-center justify-between">
            <div class="flex items-center">
              <input
                id="remember_me"
                type="checkbox"
                class="form-checkbox h-4 w-4 text-blue-600 transition
                duration-150 ease-in-out"
              />
              <label
                for="remember_me"
                class="ml-2 block text-sm leading-5 text-gray-900"
              >
                Remember me
              </label>
            </div>

            <div class="text-sm leading-5">
              <router-link 
                :to="{ name: 'ResetPassword'}" 
                name="ResetPassword" 
                class="font-medium text-blue-600 hover:text-blue-500
                focus:outline-none focus:underline transition ease-in-out
                duration-150">
                  Forgot your password?
              </router-link>
            </div>
          </div>

          <div class="mt-6">
            <span class="block w-full rounded-md shadow-sm">
              <button
                type="submit"
                class="w-full flex justify-center py-2 px-4 border
                border-transparent text-sm font-medium rounded-md text-white
                bg-blue-600 hover:bg-blue-500 focus:outline-none
                focus:border-blue-700 focus:shadow-outline-indigo
                active:bg-blue-700 transition duration-150 ease-in-out"
              >
                Sign in
              </button>
            </span>
          </div>
        </form>

        <div class="pt-4 flex justify-center">
          <router-link :to="{ name: 'SignUp' }" class="text-blue-600 underline">
            Or Sign Up
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Component from "../component_location"
import { EventBus } from "@/utils.js";
import { user, verifyAuthenticated,signIn } from "@/scripts/Authentication.js";

export default {
  components: {},

  props: [],

  data() {
    return {
      email: "",
      password: "",
      error: false
    };
  },

  watch: {},

  methods: {

    signIn() {
      var self = this;
      var loginInfo = { email: this.email, password: this.password };
      signIn(loginInfo).then(function(res){
          console.log("SignIn:", res.username)
          self.$router.push({
             name: 'Portal',
           });
      }).catch((error) => {
        this.error = true;
        console.log(error);
      });

      // this.$samsa
      //   .signIn(this.email, this.password)
      //   .then(res => {
      //     this.$store.commit('signIn', res.data);
      //     this.$router.push({
      //       name: 'Dashboard',
      //       params: {username: res.data.username},
      //     });
      //   })
      //   .catch(err => {
      //     EventBus.$emit('error', err.request.response);
      //   });
    },
  },

  computed: {},

  mounted() {},
};
</script>

<style scoped lang="scss">
p.error {
  color: #d20000;
  line-height: 1.25em;
  margin-bottom: 1em;
}
</style>
