<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="flex flex-col earth-background" id = "blurry">

    <div class="content about main eula">
      <transition name="fade" appear>
        <div class="header-section">
          <h1>END USER LICENSE AGREEMENT</h1>
        </div>
      </transition>
      <div id="about-section">
      <div class="WordSection1">

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">This End
User License Agreement (“Agreement”) is a binding agreement between you <span class="GramE">(”End</span> User” or “you”) and <span style="background:yellow !msorm;
mso-highlight:yellow !msorm"><span style="mso-prop-change:&quot;Brett Northcutt&quot; 20210413T1100">Springmatter,
LLC</span></span> with offices located at 301 W Michigan Ave, Suite 319, Ypsilanti MI 48197
(”Company”).
This Agreement governs your use of the <span class="msoIns"><ins cite="mailto:Brett%20Northcutt" datetime="2021-04-13T11:00">Springmatter
</ins></span>application, including all related documentation, the
“Application”). The Application is licensed, not sold, to you.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">BY
CLICKING THE “AGREE” BUTTON, DOWNLOADING, INSTALLING, OR USING, YOU (A)
ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT
THAT YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT
THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT
AGREE TO THESE TERMS, DO NOT DOWNLOAD, INSTALL, OR USE THE APPLICATION AND
DELETE IT FROM YOUR MOBILE DEVICE.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">1.<span style="mso-spacerun:yes">&nbsp; </span>License Grant. Subject to the terms of this
Agreement, Company grants you a limited, non-exclusive, and nontransferable
license to:<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(a)<span style="mso-spacerun:yes">&nbsp;&nbsp;
</span>download, install, and use the Application for your personal,
non-commercial use on a single mobile device owned or otherwise controlled by
you <span class="GramE">(”Mobile</span> Device”) strictly in accordance with the
Application’s documentation; and <o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(b)<span style="mso-spacerun:yes">&nbsp;
</span>access, stream, download, and use, on such Mobile Device, the Content
and Services (as defined in Section 5) made available in or otherwise
accessible through the Application, strictly in accordance with this Agreement
and the <router-link to="/terms" name="eula-terms">Terms of Use</router-link> applicable to such Content and Services as set forth in
Section 5.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">2.<span style="mso-spacerun:yes">&nbsp; </span>License Restrictions. Licensee shall not:<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(a)<span style="mso-spacerun:yes">&nbsp;
</span>copy the Application, except as expressly permitted by this <span class="GramE">license;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(b)<span style="mso-spacerun:yes">&nbsp;
</span>modify, translate, adapt, or otherwise create derivative works or
improvements, whether or not patentable, of the <span class="GramE">Application;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(c)<span style="mso-spacerun:yes">&nbsp;
</span>reverse engineer, disassemble, decompile, decode, or otherwise attempt
to derive or gain access to the source code of the Application or any part <span class="GramE">thereof;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(d)<span style="mso-spacerun:yes">&nbsp;
</span>remove, delete, alter, or obscure any trademarks or any copyright,
trademark, patent, or other intellectual property or proprietary rights notices
from the Application, including any copy <span class="GramE">thereof;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(e)<span style="mso-spacerun:yes">&nbsp;
</span>rent, lease, lend, sell, sublicense, assign, distribute, publish,
transfer, or otherwise make available the Application, or any features or
functionality of the Application, to any third party for any reason, including
by making the Application available on a network where it is capable of being
accessed by more than one device at any <span class="GramE">time;</span> <o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(f)<span style="mso-spacerun:yes">&nbsp;
</span>remove, disable, circumvent, or otherwise create or implement any
workaround to any copy protection, rights management, or security features in
or protecting the Application; or<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(g)<span style="mso-spacerun:yes">&nbsp;
</span>use the Application in, or in association with, the design,
construction, maintenance, or operation of any hazardous environments or
systems, including any power generation systems; aircraft navigation or
communication systems, air traffic control systems, or any other transport
management systems; safety-critical applications, including medical or
life-support systems, vehicle operation applications or any police, fire, or
other safety response systems; and military or aerospace applications, weapons
systems, or environments.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">3.<span style="mso-spacerun:yes">&nbsp; </span>Reservation of Rights. You acknowledge and
agree that the Application is provided under license, and not sold, to you. You
do not acquire any ownership interest in the Application under this Agreement,
or any other rights thereto other than to use the Application in accordance
with the license granted, and subject to all terms, conditions, and
restrictions, under this Agreement. Company and its licensors and service
providers reserve and shall retain their entire right, title, and interest in
and to the Application, including all copyrights, trademarks, patent rights and
other intellectual property rights therein or relating thereto, except as
expressly granted to you in this Agreement.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">4.<span style="mso-spacerun:yes">&nbsp; </span>Collection and Use of Your Information. You
acknowledge that when you download, install, or use the Application, Company
may use automatic means (including, for example, cookies and web beacons) to
collect information about your Mobile Device and about your use of the
Application. You also may be required to provide certain information about
yourself as a condition to downloading, installing, or using the Application or
certain of its features or functionality, and the Application may provide you
with opportunities to share information about yourself with others. All
information we collect through or in connection with this Application is
subject to our <router-link to="/privacy" name="eula-privacy">Privacy Policy</router-link>. By downloading, installing, using, and
providing information to or through this Application, you consent to all
actions taken by us with respect to your information in compliance with the
Privacy Policy.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">5.<span style="mso-spacerun:yes">&nbsp; </span>Content and Services. The Application may
provide you with access to Company’s website located at <span class="msoIns"><ins cite="mailto:Brett%20Northcutt" datetime="2021-04-13T11:01">springmatter.com</ins></span>
(the “Website”) and products and services accessible thereon, and certain
features, functionality, and content accessible on or through the Application
may be hosted on the Website (collectively, “Content and Services”). Your
access to and use of such Content and Services are governed by Website’s Terms
of Use and Privacy Policy per the links above, which are incorporated herein by
this reference. Your access to and use of such Content and Services may require
you to acknowledge your acceptance of such Terms of Use and Privacy Policy
and/or to register with the Website, and your failure to do so may restrict you
from accessing or using certain of the Application’s features and
functionality. Any violation of such Terms of Use will also be deemed a
violation of this Agreement.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">6.<span style="mso-spacerun:yes">&nbsp; </span>Geographic Restrictions. You acknowledge that
you may not be able to access all or some of the Content and Services outside
of the United States and that access thereto may not be legal by certain
persons or in certain countries. If you access the Content and Services from
outside the United States, you are responsible for compliance with local
laws.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">7.<span style="mso-spacerun:yes">&nbsp; </span>Updates. Company may from time to time in its
sole discretion develop and provide Application updates, which may include
upgrades, bug fixes, patches, other error corrections, and/or new features
(collectively, including related documentation, “Updates”). Updates may also
modify or delete in their entirety certain features and functionality. You
agree that Company has no obligation to provide any Updates or to continue to
provide or enable any particular features or functionality. Based on your Mobile
Device settings, when your Mobile Device is connected to the internet either:<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(a)<span style="mso-spacerun:yes">&nbsp;
</span>the Application will automatically download and install all available
Updates; or<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(b)<span style="mso-spacerun:yes">&nbsp;
</span>you may receive notice of or be prompted to download and install available
Updates.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">You shall
promptly download and install all Updates and acknowledge and agree that the
Application or portions thereof may not properly operate should you fail to do
so. You further agree that all Updates will be deemed part of the Application
and be subject to all terms and conditions of this Agreement.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">8.<span style="mso-spacerun:yes">&nbsp; </span>Third-Party Materials. The Application may
display, include, or make available third-party content (including data,
information, applications, and other products, services, and/or materials) or
provide links to third-party websites or services, including through
third-party advertising <span class="GramE">(”Third</span>-Party Materials”). You
acknowledge and agree that Company is not responsible for Third-Party
Materials, including their accuracy, completeness, timeliness, validity,
copyright compliance, legality, decency, quality, or any other aspect thereof.
Company does not assume and will not have any liability or responsibility to
you or any other person or entity for any Third-Party Materials. Third-Party
Materials and links thereto are provided solely as a convenience to you, and
you access and use them entirely at your own risk and subject to such third
parties’ terms and conditions.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">9.<span style="mso-spacerun:yes">&nbsp; </span>Term and Termination.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(a)<span style="mso-spacerun:yes">&nbsp;
</span>The term of Agreement commences when you click accept or download or install
the Application (whichever is earlier) and will continue in effect until
terminated by you or Company as set forth in this Section 9.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(b)<span style="mso-spacerun:yes">&nbsp;
</span>You may terminate this Agreement by deleting the Application and all
copies thereof from your Mobile Device.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(c)<span style="mso-spacerun:yes">&nbsp;
</span>Company may terminate this Agreement at any time without notice. In
addition, this Agreement will terminate immediately and automatically without
any notice if you violate any of the terms and conditions of this Agreement.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(d)<span style="mso-spacerun:yes">&nbsp;
</span>Upon termination:<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;line-height:normal"><span style="font-size:
12.0pt;font-family:&quot;Arial&quot;,sans-serif">(<span class="SpellE">i</span>)<span style="mso-spacerun:yes">&nbsp; </span>all rights granted to you under this
Agreement will also terminate; and<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;line-height:normal"><span style="font-size:
12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.5in;text-align:justify;line-height:normal"><span style="font-size:
12.0pt;font-family:&quot;Arial&quot;,sans-serif">(ii)<span style="mso-spacerun:yes">&nbsp;
</span>you must cease all use of the Application and delete all copies of the
Application from your Mobile Device and account.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(e)<span style="mso-spacerun:yes">&nbsp;
</span>Termination will not limit any of Company’s rights or remedies at law or
in equity.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">10.<span style="mso-spacerun:yes">&nbsp; </span>Disclaimer of Warranties. THE APPLICATION IS
PROVIDED TO LICENSEE “AS IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY
OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON
ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE
LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER
EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE APPLICATION,
INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF
COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT
LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND
MAKES NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET YOUR
REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY
OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT
INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE,
OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">SOME
JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED
WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER,
SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO
YOU.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">11.<span style="mso-spacerun:yes">&nbsp; </span>Limitation of Liability. TO THE FULLEST
EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY OR ITS AFFILIATES,
OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY
LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE
APPLICATION OR THE CONTENT AND SERVICES FOR:<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(a)<span style="mso-spacerun:yes">&nbsp;
</span>PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS
OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER
FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT,
EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span>(b)<span style="mso-spacerun:yes">&nbsp;
</span>DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY
PAID BY YOU FOR THE APPLICATION.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">THE
FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF
CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER
SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH
DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO
SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">12.<span style="mso-spacerun:yes">&nbsp; </span>Indemnification. You agree to indemnify,
defend, and hold harmless Company and its officers, directors, employees,
agents, affiliates, successors, and assigns from and against any and all
losses, damages, liabilities, deficiencies, claims, actions, judgments,
settlements, interest, awards, penalties, fines, costs, or expenses of whatever
kind, including attorneys’ fees, arising from or relating to your use or misuse
of the Application or your breach of this Agreement, including but not limited
to the content you submit or make available through this Application.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">13.<span style="mso-spacerun:yes">&nbsp; </span>Export Regulation. The Application may be
subject to US export control laws, including the Export Control Reform Act and
its associated regulations. You shall not, directly or indirectly, export,
re-export, or release the Application to, or make the Application accessible
from, any jurisdiction or country to which export, re-export, or release is
prohibited by law, rule, or regulation. You shall comply with all applicable
federal laws, regulations, and rules, and complete all required undertakings
(including obtaining any necessary export license or other governmental
approval), prior to exporting, re-exporting, releasing, or otherwise making the
Application available outside the US.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">14.<span style="mso-spacerun:yes">&nbsp; </span>Severability. If any provision of this
Agreement is illegal or unenforceable under applicable law, the remainder of
the provision will be amended to achieve as closely as possible the effect of
the original term and all other provisions of this Agreement will continue in
full force and effect; provided, however, that if any fundamental term or
provision of this Agreement is invalid, illegal, or unenforceable, the
remainder of this Agreement shall be unenforceable.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">15.<span style="mso-spacerun:yes">&nbsp; </span>Governing Law. This Agreement is governed by
and construed in accordance with the exclusive jurisdiction and laws presiding
over the State of Michigan, USA without giving effect to any choice or conflict
of law provision or rule. Any legal suit, action, or proceeding arising out of
or related to this Agreement or the Application shall be instituted exclusively
in the courts located in the county of Oakland, Michigan. You waive any and all
objections to the exercise of jurisdiction over you by such courts and to venue
in such courts.<o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">17.<span style="mso-spacerun:yes">&nbsp; </span>Limitation of Time to File Claims. ANY CAUSE
OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR
THE APPLICATION MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
ACCRUES OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">18.<span style="mso-spacerun:yes">&nbsp; </span>Entire Agreement. This Agreement, including
the Terms of Use and our Privacy Policy, constitute the entire agreement
between you and Company with respect to the Application and supersede all prior
or contemporaneous understandings and agreements, whether written or oral, with
respect to the Application.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><o:p></o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif"><o:p>&nbsp;</o:p></span></p>

<p class="MsoNormal" style="margin-bottom:0in;text-align:justify;line-height:
normal"><span style="font-size:12.0pt;font-family:&quot;Arial&quot;,sans-serif">19.<span style="mso-spacerun:yes">&nbsp; </span>Waiver. No failure to exercise, and no delay
in exercising, on the part of either party, any right or any power hereunder
shall operate as a waiver thereof, nor shall any single or partial exercise of
any right or power hereunder preclude further exercise of that or any other
right hereunder. In the event of a conflict between this Agreement and any
applicable purchase or other terms, the terms of this Agreement shall govern.<o:p></o:p></span></p>

</div>
      </div>
      </div>
  </div>
</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";

export default {
  components: { Breadcrumbs, NavbarDash },

  props: [],

  data() {
    return {
      name:"about"
    };
  },

  watch: {},

  methods: {
  },

  computed: {},


  mounted() {},
};
</script>

<style scoped>


</style>
