<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
	<div>

		<button @click="test_get">test get</button>
		<p/>
		<button @click="test_post">test Post</button>

  </div>
</template>

<script>
	export default {
		components: {},

		props: {},

		data() {
			return {
				opacity: 100,
			};
		},

		updated: function() {},
		watch: {},
		methods: {
			test_args:function(){
				console.log(arguments)
			},
			test_get:async function(){
				var ret = await $samsa.is_subscribed("clewis");
				console.log("Test_Get Returned", ret)
			},
			test_post:async function(){
				var newUser = {
			  "number": 0,
			  "string": "string",
			  "child": {
			    "number": 0,
			    "string": "string"
			  },
			  "children": [
			    {
			      "number": 0,
			      "string": "string"
			    }
			  ]
}

				var ret = await $samsa.sign_in("clewis@michaero.com","exception2021");
				console.log("Test_Post Returned", ret)
			}


		},

		computed: {},

		mounted() {},
	};
</script>

<style scoped>
.text{
	width:500px;
	height:500px;
	border:1px solid green;
	vertical-align: middle;
	display: flex;

  align-items: center;

}

.text p{
	border:1px solid red;
}


</style>
