<template>
    <div class="carousel__container">
        <VueSlickCarousel v-bind="settings">
            <div class="carousel__card">
              <img src="/images/testimonial-client-1.jpeg" />
              <p>Kyle Black</p>
              <p class="title">Head Coach</p>
              <p>UW MILWAUKEE</p>
              <p class="quote">&ldquo;</p>
              <p>Being able to see an athlete's story beyond their stats helps us get to know their character and make offer decisions easier.</p>
            </div>
            <div class="carousel__card">
              <img src="/images/testimonial-client-2.jpeg" />
              <p>Jason Gardner</p>
              <p class="title">Head Coach</p>
              <p>NORTH CENTRAL HIGH SCHOOL</p>
              <p class="quote">&ldquo;</p>
              <p>Athletes from smaller schools will have a better opportunity to get in front of coaches and recruiters from bigger schools than they did before.</p>
            </div>
            <div class="carousel__card less">
              <img src="/images/testimonial-client-3.jpeg" />
              <p>Maddix Bogunia-Bright</p>
              <p>FUTURE COLLEGIATE ATHLETE</p>
              <p class="quote">&ldquo;</p>
              <p>I wish I had something like this when I was in middle and high school. I think I would’ve been more heavily recruited if I had been able to show more than stats and highlight reels.</p>
            </div>
            <div class="carousel__card">
              <img src="/images/testimonial-client-4.jpeg" />
              <p>Tom Allen</p>
              <p class="title">Head Coach</p>
              <p>INDIANA UNIVERSITY</p>
              <p class="quote">&ldquo;</p>
              <p>This is what we need as coaches to be able to get to know an athlete’s character off the field. We are looking for leaders and kids that fit into our culture as a team.</p>
            </div>
        </VueSlickCarousel>
  </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  components: { VueSlickCarousel },

  props: [],

  data() {
    return {
        settings: {
            "dots": true,
            "arrows": true,
            "dotsClass": "slick-dots custom-dot-class",
            "adaptiveHeight": true,
            "edgeFriction": 0.35,
            "infinite": false,
            "speed": 500,
            "slidesToShow": 3,
            "slidesToScroll": 3
        }
    };
  },
  methods: {

  }

};
</script>

<style lang="scss">

</style>
