<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
    <text-view :view="view" ref="text" class="text ease" :style="{top:top, height:height, width:width}"  :class="[position,alignment,faded?'faded':'', content.text==null?'any':'']" v-if="content.type=='TEXT'" :scrollTop="scrollTop" :content="content"></text-view>
    <image-view :view="view" ref="image" class="any ease" :style="{top:top, height:height, width:width}" :class="[position,alignment,faded?'faded':'']" v-else-if="content.type=='IMAGE'" :scrollTop="scrollTop" :content="content"></image-view>
    <video-view :view="view" ref="video" class="any ease" :style="{top:top, height:height, width:width}" :class="[position,alignment,faded?'faded':'']" v-else-if="content.type=='VIDEO'" :scrollTop="scrollTop" :content="content"></video-view>
    <audio-view :view="view" ref="audio" class="any" :class="[faded?'faded':'']" v-else-if="content.type=='AUDIO'" :scrollTop="scrollTop" :content="content"></audio-view>
</template>

<script>
import TextView from "@/views/Narrator/viewers/text.vue"
import ImageView from "@/views/Narrator/viewers/image.vue"
import VideoView from "@/views/Narrator/viewers/video.vue"
import AudioView from "@/views/Narrator/viewers/audio.vue"


export default {
  components: {TextView,ImageView,VideoView,AudioView},

  props: {content:{default:null}, scrollTop:{default:0}, size:{default:0}, maxWidth:{default:0}, position:{default:0},view:{default:false}},

  data() {
    return {
      highlight:null
    };
  },

  watch: {
      scrollTop:function(after, before){

      }
  },

  methods: {
    edit(){

      if(this.highlight == this)
        window.$events.$emit("highlight",null)
      else {
        window.$events.$emit("highlight",this)
      }

      return;
      if(this.content && this.content.type=="TEXT"){
        this.$refs.text.edit()
      }
      else if(this.content && this.content.type=="IMAGE"){
        this.$refs.image.edit()
      }
      else if(this.content && this.content.type=="VIDEO"){
        this.$refs.video.edit()
      }


    },
  },

  computed: {
    width(){

      if(this.maxWidth==1 && this.position=='middle')
        return "100%";
      else if (this.maxWidth==3 && this.position=='middle_third')
        return "34%";
      else if (this.maxWidth==3)
          return "33%";
      else// if(this.maxWidth==2 || (this.content.index==0 || this.content.index==2))
          return "50%";

    },
    top(){
      return $narrative_height/2*this.content.top + "px"
    },
    height(){

      if(this.content.full)
        return $narrative_height + "px";
      else {
        return $narrative_height/2 + "px";
      }
    },
    alignment(){

      if(!this.size && this.content.alignment=='top')
        return 'full';
      else if(this.content.alignment=='top')
          return 'top';
      else if(this.content.alignment=='middle')
        return 'full';
      else if(this.content.alignment=='bottom')
        return 'bottom'
    },
    faded:function(){

      if(this.highlight != null && this.highlight!=this.$el)
        return true;
      else {
        return false;
      }
    },


  },

  mounted() {
    var self = this;
    window.$events.$on("highlight", function(item){self.highlight = item;})


    //window.setTimeout(function(){
    //  self.edit();
    //},500);

  },
};
</script>

<style scoped>

.faded {
  opacity: .1;
  transition: all 0.5s ease;
}

.edit .any{
  pointer-events:  all !important;
}

.ease{
  transition: ease all .5s;
}

.edit .text{
  pointer-events:  all !important;
  transition: ease all .5s;
  height:50%;
}

.top{

}


.bottom{


}

.full{


}


.left{
  right:100%;
  transform: translate(100%, 0);
  width: 50%;
}

.right{
  width: 50%;
  right:0;
  /**/
}

.middle{
  width: 100%;
  right:100%;
  transform: translate(100%, 0);
}

.middle_third{
  width: 100%;
  right:67.05%;
  transform: translate(100%, 0);
}

/*
.middle{
  width:100%;
}

.left{
  width: 50%;
}

.right{
  width: 50%;
}

.top{
  top:0px;
}

.bottom{
  top:50%;
}

.half{
  height:10%;
}
*/


</style>
