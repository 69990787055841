<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol class="flex items-center space-x-4">
      <li>
        <div>
          <a :href="homeLink" class="text-gray-400 hover:text-blue-500">
            <!-- Heroicon name: home -->
            <svg
              class="flex-shrink-0 h-5 w-5 transition duration-150 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4
                10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011
                1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0
                001.414-1.414l-7-7z"
              />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li class="flex" v-for="(path, idx) in paths">
        <div class="flex items-center space-x-4">
          <svg
            class="flex-shrink-0 h-5 w-5 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>

          <router-link
            :to="path.route"
            class="text-sm leading-5 font-medium text-gray-500
            hover:text-blue-600 transition duration-150 ease-in-out"
          >
            {{ path.name }}
          </router-link>
        </div>
      </li>
    </ol>
  </nav>

  <!-- <nav class="flex" aria&#45;label="Breadcrumb"> -->
  <!--   <ol class="bg&#45;white rounded&#45;md shadow px&#45;6 flex space&#45;x&#45;4"> -->
  <!--     <!&#45;&#45; HOME BUTTON &#45;&#45;> -->
  <!--     <li class="flex"> -->
  <!--       <div class="flex items&#45;center"> -->
  <!--         <router&#45;link to="/" class="text&#45;gray&#45;400 hover:text&#45;gray&#45;500"> -->
  <!--           <!&#45;&#45; Heroicon name: home &#45;&#45;> -->
  <!--           <svg -->
  <!--             class="flex&#45;shrink&#45;0 h&#45;5 w&#45;5 transition duration&#45;150 ease&#45;in&#45;out" -->
  <!--             aria&#45;hidden="true" -->
  <!--             xmlns="http://www.w3.org/2000/svg" -->
  <!--             viewBox="0 0 20 20" -->
  <!--             fill="currentColor" -->
  <!--           > -->
  <!--             <path -->
  <!--               d="M10.707 2.293a1 1 0 00&#45;1.414 0l&#45;7 7a1 1 0 001.414 1.414L4 -->
  <!--               10.414V17a1 1 0 001 1h2a1 1 0 001&#45;1v&#45;2a1 1 0 011&#45;1h2a1 1 0 011 -->
  <!--               1v2a1 1 0 001 1h2a1 1 0 001&#45;1v&#45;6.586l.293.293a1 1 0 -->
  <!--               001.414&#45;1.414l&#45;7&#45;7z" -->
  <!--             /> -->
  <!--           </svg> -->
  <!--         </router&#45;link> -->
  <!--         <span class="sr&#45;only">Home</span> -->
  <!--       </div> -->
  <!--     </li> -->
  <!--  -->
  <!--     <!&#45;&#45; ADDITIONAL NAV COMPONENTS &#45;&#45;> -->
  <!--     <li class="flex" v&#45;for="(path, idx) in paths"> -->
  <!--       <div class="flex items&#45;center space&#45;x&#45;4"> -->
  <!--         <svg -->
  <!--           class="flex&#45;shrink&#45;0 w&#45;6 h&#45;full text&#45;gray&#45;200" -->
  <!--           viewBox="0 0 24 44" -->
  <!--           preserveAspectRatio="none" -->
  <!--           fill="currentColor" -->
  <!--           xmlns="http://www.w3.org/2000/svg" -->
  <!--           aria&#45;hidden="true" -->
  <!--         > -->
  <!--           <path d="M.293 0l22 22&#45;22 22h1.414l22&#45;22&#45;22&#45;22H.293z" /> -->
  <!--         </svg> -->
  <!--         <router&#45;link -->
  <!--           :to="path.route" -->
  <!--           class="text&#45;sm leading&#45;5 font&#45;medium text&#45;gray&#45;500 -->
  <!--           hover:text&#45;gray&#45;700 transition duration&#45;150 ease&#45;in&#45;out" -->
  <!--         > -->
  <!--           {{ path.name }} -->
  <!--         </router&#45;link> -->
  <!--       </div> -->
  <!--     </li> -->
  <!--  -->
  <!--     <!&#45;&#45; <li class="flex"> &#45;&#45;> -->
  <!--     <!&#45;&#45;   <div class="flex items&#45;center space&#45;x&#45;4"> &#45;&#45;> -->
  <!--     <!&#45;&#45;     <svg &#45;&#45;> -->
  <!--     <!&#45;&#45;       class="flex&#45;shrink&#45;0 w&#45;6 h&#45;full text&#45;gray&#45;200" &#45;&#45;> -->
  <!--     <!&#45;&#45;       viewBox="0 0 24 44" &#45;&#45;> -->
  <!--     <!&#45;&#45;       preserveAspectRatio="none" &#45;&#45;> -->
  <!--     <!&#45;&#45;       fill="currentColor" &#45;&#45;> -->
  <!--     <!&#45;&#45;       xmlns="http://www.w3.org/2000/svg" &#45;&#45;> -->
  <!--     <!&#45;&#45;       aria&#45;hidden="true" &#45;&#45;> -->
  <!--     <!&#45;&#45;     > &#45;&#45;> -->
  <!--     <!&#45;&#45;       <path d="M.293 0l22 22&#45;22 22h1.414l22&#45;22&#45;22&#45;22H.293z" /> &#45;&#45;> -->
  <!--     <!&#45;&#45;     </svg> &#45;&#45;> -->
  <!--     <!&#45;&#45;     <a &#45;&#45;> -->
  <!--     <!&#45;&#45;       aria&#45;current="page" &#45;&#45;> -->
  <!--     <!&#45;&#45;       class="text&#45;sm leading&#45;5 font&#45;medium text&#45;gray&#45;500 &#45;&#45;> -->
  <!--     <!&#45;&#45;       hover:text&#45;gray&#45;700 transition duration&#45;150 ease&#45;in&#45;out" &#45;&#45;> -->
  <!--     <!&#45;&#45;       >Project Nero</a &#45;&#45;> -->
  <!--     <!&#45;&#45;     > &#45;&#45;> -->
  <!--     <!&#45;&#45;   </div> &#45;&#45;> -->
  <!--     <!&#45;&#45; </li> &#45;&#45;> -->
  <!--   </ol> -->
  <!-- </nav> -->
</template>

<script>
// import Component from "../component_location"

export default {
  components: {},

  props: ["links"],

  data() {
    return {
    };
  },

  watch: {},

  methods: {},

  computed: {
    homeLink()  {
      return `/${this.username}`  
    },
    username() {
      return this.$store.state.users.user.username;
    },
    paths() {
      return this.links;
      var paths = [];
      this.links.forEach((link) => {
        paths.push({ path: this.$router.resolve(link).href, id: link.id });
      });
      return paths;
    },
  },

  mounted() {},
};
</script>

<style></style>
