<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="flex flex-col earth-background" id = "blurry">

    <div class="content about main">
      <transition name="fade" appear>
        <div class="header-section">
          <h1>ABOUT US</h1>
        </div>
      </transition>
      <div id="about-section">

        <p>Everything meaningful begins with a story...</p>

        <p>We believe everybody has important stories to tell, but stories are often difficult to tell in an engaging and captivating way.</p>

        <p>That's why Springmatter's revolutionary no-code software is so empowering and important for many groups of people. Using Springmatter, and our beautiful templates, easily create captivating multi-media Narratives about your:</p>

        <ul>
          <li>life and interests</li>
          <li>environment, community, or cause</li>
          <li>business, employees, and services</li>
          <li>school, work, or research project</li>
        </ul>

        <p>And, if you choose, you can build your Narrative around compelling data, leveraging our data management and analysis tools. Once you share your Narrative via email or social media, our custom analytics allow you to measure your viewer's usage and engagement.</p>

        <p>The theme of Springmatter is to Start Something Meaningful. Fittingly, our company also established the Springmatter Fund to be intentional about our philanthropy. Especially meaningful to Springmatter is support for STEM/STEAM educational programming as well as efforts to provide equitable employment and educational opportunities for marginalized and under-represented communities. The unifying concept for these areas of focus is to provide hope for young people that companies will welcome them, even if society has not. Because Springmatter functions within a growth-based entrepreneurial community located in Ann Arbor, the Springmatter Fund is housed within the local community that has embraced the company's success, with the long-term vision for helping to extend storytelling success to all people.</p>

        <p>Are you ready to Start Something Meaningful?</p>
      </div>
    </div>
      <div id="about-section" class="about people">
        <h1>MEET OUR TEAM</h1>
        <div class="casting left">

          <img src="/images/team/ptchoryk.jpeg">
          <h2>Pete Tchoryk</h2>
          <h3>CEO</h3>
          <p>As an engineer and CEO, I aspired to develop ideas that would provide people with a chance to make a difference in the world. For more than twenty-five years, I led entrepreneurial ventures and advanced technology development, from company creation and team building to product development and licensing. Since 2004, I've been responsible for leading all day-to-day operations of the company, financial oversight (P&L), strategic planning, business development, and operations. Thankfully, my eyes have been opened along the way through my family's experiences in raising kids who live in the margins. It was through our advocacy work that I witnessed the transformative power of storytelling – this became the motivation behind Springmatter. At Springmatter, we strive to provide people and organizations with tools that inspire and empower them to tell and share their stories much more easily and meaningfully. It is both my passion and responsibility to foster an inclusive culture that allows people to live and work authentically while building products that bring us closer together.</p>
          <div id="clear"></div>
        </div>
        <div class="casting right brett">
          <img src="/images/team/brett_northcutt.jpg">
          <h2>Brett Northcutt</h2>
          <h3>Chief Commercialization Officer</h3>
          <p>After nearly 20 years in Aerospace and the Industrials, I brought my passion to build new products, businesses, and teams to the SaaS world with Springmatter. As engineer and business leader, I've always been one to push the envelope seeking better products and a better way to do things. I was drawn to Springmatter because of the huge gap in the market for enabling everybody to be better at telling their story, improving their life, and building their dream. At Springmatter, I am responsible for building the Springmatter team, and realizing the growth potential of the fantastic Springmatter platform.</p>
          <div id="clear"></div>
        </div>
        <div class="casting left">
        <img src="/images/team/chad_lewis.jpg">
          <h2>Chad Lewis</h2>
          <h3>Director of Software Development</h3>
          <p>Growing up with two entrepreneurial parents instilled in me an enthusiasm for being creative and taking chances. When they bought the family an Apple IIe back in 1984, I started programming in BASIC and never looked back. I had found my passion. Building tools and applications that helped improve people's lives became a love of mine. My career started at an environment laboratory working with the information management system that aggregated the data from analytical instruments. I became obsessed with improving software that people use in their day-to-day lives while making the data they work with more accessible and easier to understand. As the director of software development at SpringMatter, I bring with me a love for software and a need to build applications that help people understand each other better.</p>
          <div id="clear"></div>
        </div>
        <div class="casting right">
          <img src="/images/team/eli_miller.jpeg">
          <h2>Elijah Miller</h2>
          <h3>SaaS Technology Advisor</h3>
          <p>Eli brings over 20 year's experience creating and growing software businesses.  Eli's ability to understand both the technology-and business impact of that technology-is only rivaled by his curiosity to learn and teach.</p>
          <div id="clear"></div>
        </div>
        <div class="casting left">
          <img src="/images/team/ptchoryk.jpeg">
          <h2>Douglas Bright</h2>
          <h3>Director of Marketing</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          <div id="clear"></div>
        </div>
        <div class="casting right">
          <img src="/images/team/eli_miller.jpeg">
          <h2>Matthew J. Lewis, Ph.D.</h2>
          <h3>Vice President</h3>
          <p>I received my Ph.D. in physics from the University of Michigan for my work describing cosmological models in the context of string theories. After graduating, I worked for eight years in the defense industry, at General Dynamics Advanced Information Systems, focusing on the application of machine learning techniques to defense and intelligence problems, particularly in the realm of electronic warfare. I served as principal investigator for the $20M DARPA Retriever program, as well as many other electronic warfare and machine learning programs for the Navy, NSA, and other U.S. Government agencies. I currently apply machine learning technology to problems such as clean energy and early stage disease detection. Over the last decade there have been dramatic advances in machine learning and artificial intelligence, and I am excited to bring these advances to a wider audience via Springmatter and its clients.</p>
          <div id="clear"></div>
        </div>
        <div class="casting left">
          <img src="/images/team/dvasseliou.jpeg">
          <h2>Jane Pavlich</h2>
          <h3>Chief Operating Officer</h3>
          <p>Jane Pavlich is a company Vice-President and MAC's Chief Operating Officer.  She has over 25 years of experience in program management and systems engineering and is experienced in product development programs. Her involvement in the atmospheric LIDAR development programs has spanned ground-based stations, airborne optical air data systems, high altitude balloon platforms, wind turbine applications, and atmospheric measurements to support hypersonic vehicle testing. She has been involved in all phases of design, test, installation and verification of these LIDAR systems. She was the program manager for the Autonomous Satellite Docking System (ASDS) program, a project that designed, developed and tested a docking mechanism under a DARPA Phase II SBIR.  She is a co-inventor on various docking system patents. Additional space robotics experience includes system analysis, production planning, software development, test and mission preparation for the Automated Wafer Cartridge System, a mechanism designed to transfer and process semiconductor wafers in space.  She is a key member in bid and proposal efforts and is involved in strategic planning, financial oversight, program management and operations.</p>
        </div>
        <div class="casting right">
          <img src="/images/team/dvasseliou.jpeg">
          <h2>Leslie Borst</h2>
          <h3>UI/UX Engineer</h3>
          <p>I writes code.</p>
        </div>
      </div>
      <div class="about join">
        <div id="about-section">
          <h1>JOIN OUR TEAM</h1>

          <p>We have multiple open positions (see our LinkedIn page) and are always looking for world-class talent in software development, storytelling, and customer success.</p>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";

export default {
  components: { Breadcrumbs, NavbarDash },

  props: [],

  data() {
    return {
      name:"about"
    };
  },

  watch: {},

  methods: {
  },

  computed: {},


  mounted() {},
};
</script>

<style>

</style>
