<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template> </template>

<script>
	import CodexDatabase from "@/components/CodexDatabase.vue";
	export default {
		components: { CodexDatabase },

		props: [],

		data() {
			return {
				ships: [],
				ship_name: "",
				ship_type: "",
				date_entered: "",
			};
		},

		watch: {},

		methods: {},

		computed: {},

		mounted() {},
	};
</script>

<style>
	table {
		width: 100%;
	}
	table,
	th,
	td {
		border: 1px solid black;
		border-collapse: collapse;
	}
	th,
	td {
		padding: 15px;
		text-align: left;
	}
	#t01 tr:nth-child(even) {
		background-color: #eee;
	}
	#t01 tr:nth-child(odd) {
		background-color: #fff;
	}
	#t01 th {
		background-color: black;
		color: white;
	}
</style>
