<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="background">
    <div style="position:relative; height:100%; pointer-events:None;">
    <background v-for="(item, index) in backgrounds" :options="item" :key="index"></background>

  </div>
  </div>


  </div>
</template>

<script>
import Vue from 'vue'
import Background from "@/views/Narrator/viewers/background.vue"

export default {
  components: {Background},

  props: ['type','backgrounds'],

  data() {
    return {

    };
  },

  watch: {

  },

  methods: {
    add_background(y){
      console.log("Background: ",this.backgrounds)
      y=y+50;
      var z = this.backgrounds.length + 1;
      var data = {type:"BACKGROUND", mode:"SOLID", color1:"black", color2:"blue",y:y, z:z }
      window.$events.$emit("edit", data)
      console.log(this.backgrounds.length)
      this.backgrounds.push(data)
    }
  },

  computed: {
    data() {

    },
  },

  mounted() {
    window.$events.$on("add_background", this.add_background)
  },
};
</script>

<style scoped>
.background-actual
{
  position:absolute;
  top:0;
  height:100%;
  width:100%;

}

.background{
  pointer-events: none;
  overflow-y: hidden;
  position:absolute;
  top:0;
  height:100%;
  width:100%;
  z-index: 0;
}

</style>
