<template>
  <div class='flex flex-col earth-background footer' id = "contact-us" v-if="$route.meta.show_footer">
    <div class='footer__column'>
      <ul class="space-y-8">
        <li>
          <div class="footer-box first">
            <span ><p><a href="mailto:info@springmatter.com" name="footer-email">info@springmatter.com</a></p></span>
            </br>
          </div>
        </li>
        <li class="rights">
          <div class="footer-box left">
            <span><p>&copy;2000-2020 Michigan Aerospace Corporation</br>
            All rights reserved</p></span>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer__column links">
      <h4>Company</h4>
      <div class="footer__row">
        <div class="footer__column">
        <ul>
          <li>
            <router-link :to="{ name: 'Careers'}" name="footer_careers">
              Careers
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Company'}" name="footer_about">
              About Us
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Contact'}" name="footer_contact">
              Contact
            </router-link>
          </li>
        </ul>
        </div>
        </div>
    </div>
    <!-- <div class="footer__column links">
      <h4>Resources</h4>
      <div class="footer__row">
        <div class="footer__column">
          <ul>
            <li><a href="#" name="footer-support">Support</a></li>
            <li><a href="#" name="footer-tutorials">Tutorials</a></li>
            <li><a href="#" name="footer-case-studies">Case Studies</a></li>
          </ul>
        </div>

      </div>
    </div> -->
    <div class="footer__column links">
      <h4>Legal</h4>
      <div class="footer__row">
        <div class="footer__column">
          <ul>
            <li>
              <router-link :to="{ name: 'eula'}" name="footer_eula">
                EULA
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Privacy'}" name="footer_privacy">
                Privacy Policy
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Terms'}" name="footer_terms">
                Terms of Use
              </router-link>
            </li> 
          </ul>
        </div>
      </div>
    </div>
    <div class="footer__column links">
      <div class="footer__row">
        <div class="footer__column">
          <div class="social-links ninzio-clearfix">
            <a class="facebook" href="http://facebook.com/springmatter" title="facebook" target="_blank">
              <i class="fab fa-facebook-square size"></i>
            </a>
            <a class="twitter" href="https://twitter.com/aTellingStory" title="twitter" target="_blank">
              <i class="fab fa-twitter-square size"></i>
            </a>
            <a class="linkedin" href="https://www.linkedin.com/company/springmatter" title="linkedin" target="_blank">
              <i class="fab fa-linkedin size"></i>
            </a>
            <a class="email" href="mailto:info@springmatter.com" title="email" target="_blank" name="contact-page-icon-email">
              <i class="fas fa-envelope-square size"></i>
            </a>
          </div>
          <ul>
            <li>
              <a href="https://www.springmatter.org/" name="footer-fund" target="_blank">
                <img
                 class="h-16 w-auto footer-fund"
                 src="/images/Springmatter-Fund-Icon.png"
                 alt="Springmatter Fund" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// import Component from "../component_location"

export default {
  components: {},

  props: [],

  data() {
    return {

    };
  },

  watch: {},

  methods: {
  },

  computed: {},


  mounted() {},
};
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.flexbox {
  display: -webkit-flex;
  display: none;
  margin-bottom: 8vh;
}
.active, .dot:hover {
  background-color: $green;
}

.size {
  font-size: 28px;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  animation-name: fade;
  animation-duration: 2s;
}

@-webkit-keyframes fade {
  from {opacity: .1}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .1}
  to {opacity: 1}
}

.flexbox .col {
  flex: 1;
}
/*.earth-background {
  background: repeating-linear-gradient(#2c3e50, rgba(0, 0, 0, 0.75) 70%, #2c3e50, rgba(0, 0, 0, 0.75) 60%) , url("../assets/images/HeroBackground.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}*/

[class^="icon"] {
  width: 5vh;
  height: 5vh;
  position: relative;
  top: 1.5vh;
  margin-right: 1vh;
  fill: #fff;
}

nav {
  float: left;
  width: 100%;
  text-align: center;
  height: 10vh;
  padding: 1vh;
}
.box {
   display: flex;
   align-items:center;
   max-width: 45%;
   padding: 10%;

}
.footer-box {
  display: flex;
  align-items:center;
  margin-top:20px;

}
nav a{
    /*color: #282828;*/
    font-weight: bold;
    padding: 30px;
    text-align: center;

}
.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;

}
.third-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: white;
  padding: 0vh  0vh 14vh 0vh ;

}
.second-row {
  padding: 10vh;
  background-color: white;
  text-align: center;
}

.footer__row {
  display:flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
}

.footer__column {
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.column-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex:1;
}
.hero {
  margin-top: 20vh;
  max-width: 90%;
  min-width: 70%;
}
.monitor {
  display: block;
  max-width: 90%;
}
.footer-icon {
  max-width: 10%
}
a:active {
  color: red;
  background-color: transparent;
}
a:hover {
  color: green;
  background-color: transparent;
}
.large {
  font-size:97px;
  font-family: "Poppins";
  text-align: left;
  color: white;
  line-height: 102px;
  font-weight: 600;

}
.medium {
  font-size:4vh;
  font-family: "Poppins";
  text-align: left;
  line-height: 4vh;
  font-weight: bold;


}
.small {
  font-size:2.1vh;
  font-family: "Poppins";
  text-align: left;
  line-height: 2.5vh;
  font-weight: 500;

}

.paragraph {
  font-size:24px;
  color: white;
  font-family: "Poppins";
  font-weight: 300;
  text-align: left;
  line-height: 33px;
  margin-top: 3vh;
}

.text-column {
   margin-left: 20%;
   margin-top: 11vh;
   word-break:break-all;

}


.title {
  font-family: "Poppins";
  /*color: black;*/
  font-size:5vh;
  z-index : 0;
}
.main-title {
  font-size:8vh;
  font-weight: bold;
  font-family: "Poppins";
  /*color: black;*/
}
.text
{
  display: inline-block;
}
 .button {
  /*background-color: #47aa42;*/
  border: none;
  color: white;
  padding: 2vh 3vh 2vh 3vh;
  text-decoration: none;
  margin: 2.5vh 0.2vh;
  border-radius: 3vh;
  outline: none;
  font-size: 1.5vh;
  display:flex;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
  }
.button:hover {
  /*color: black;*/
}
#textButton {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
    outline: none;
    font-weight: 600;
}
#textButton:hover {
  color: green;
}
.active {
  color: green;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 6px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.active, .dot:hover {
  background-color:  #47aa42;
}
@media (max-width: 1900px) {
	.large {
		font-size: 75px;
    line-height: 80px;
	}
  .paragraph {
    font-size: 22px;
    line-height: 30px;
  }
}
@media (max-width: 1500px) {
	.large {
		font-size: 53px;
    line-height: 60px;
  }
  .paragraph {
    font-size: 18px;
    line-height: 25px;
  }
}
@media (max-width: 1200px) {
	.large {
		font-size: 45px;
    line-height: 50px;
	}
  .paragraph {
    font-size: 13px;
    line-height: 20px;
  }
}
@media (max-width: 900px) {
	.large {
		font-size: 30px;
    line-height: 34px;
	}
  .paragraph {
    font-size: 9px;
    line-height: 15px;
  }
}
@media (max-width: 600px) {
	.large {
		font-size: 19px;
    line-height: 25px;
	}
  .paragraph {
    font-size: 7px;
    line-height: 10px;
  }
}

</style>
