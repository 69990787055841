<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="m-12 mt-2 flex flex-col w-auto">
    <div
      class="w-full flex flex-row justify-between p-4 px-0  pb-6
      border-gray-300"
    >
      <breadcrumbs :links="links"></breadcrumbs>
      <button
        @click="uploadFiles"
        class="inline-flex justify-center py-2 px-4 border border-transparent
        pt-3 text-sm leading-5 font-medium rounded-md text-white
        bg-light-blue-500 hover:bg-light-blue-400 focus:outline-none
        focus:border-light-blue-600 focus:shadow-outline-blue
        active:bg-light-blue-600 transition duration-150 ease-in-out"
      >
        Add additional data
      </button>
    </div>

    <main class="flex flex-row w-full">
      <div class="flex flex-col w-1/3 p-4 bg-gray-100 shadow mt-6 rounded-md">
        <div class="flex flex-row justify-between h-6 ">
          <div class="">
            <span class="text-lg font-semibold text-blue-500">{{
              dataset.name
            }}</span>
            <span class="text-gray-500 text-sm">
              &bull;
              {{ dataset.datasetType }}
              &bull;
              {{ dataset.mimetype.toUpperCase() }}
            </span>
          </div>

          <div class=" text-gray-500">
            <a
              v-if="dataset.isConnected"
              aria-label="Visit this dataset's API"
              v-tooltip="'Visit this dataset\'s API'"
              :href="dataset.apiUrl"
              target="_blank"
              class="text-blue-600 underline hover:font-semibold"
            >
              API
            </a>
          </div>
        </div>

        <!-- DESCRIPTION -->
        <div class="mt-2 text-gray-500">
          {{ dataset.description }}
        </div>

        <!-- COLUMN DETAILS -->
        <div
          class="mt-2 p-2 border-t border-gray-200 flex flex-col"
          v-for="(descriptor, idx) in dataset.descriptors"
          v-if="dataset.mimetype.split('/')[0] == 'text'"
        >
          <div class="flex flex-row justify-between">
            <span class="text-md font-semibold text-gray-700">{{
              descriptor.name
            }}</span>
            <span class="text-md font-semibold text-gray-500">{{
              descriptor.dataType
            }}</span>
          </div>
          <span class="text-gray-500">{{ descriptor.description }}</span>
        </div>
      </div>
      <!-- END LEFT HAND BLOCK -->

      <data-table v-if="isText" :dataset="dataset"></data-table>
      <image-gallery v-if="isImage" :dataset="dataset"></image-gallery>
      <video-gallery v-if="isVideo" :dataset="dataset"></video-gallery>

      <!-- END DATA TABLE -->
    </main>
    <!-- END MAIN BLOCK -->
  </div>
</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DataTable from "@/components/DataTable.vue";
import ImageGallery from "@/components/ImageGallery.vue";
import VideoGallery from "@/components/VideoGallery.vue";
import AudioLibrary from "@/components/AudioLibrary.vue";

import { reportError, openFilePicker, EventBus } from "@/utils.js";

export default {
  components: { Breadcrumbs, DataTable, ImageGallery, VideoGallery, AudioLibrary },

  props: ["username", "repoName", "datasetName"],

  data() {
    return {};
  },

  watch: {},

  methods: {
    loadDataset() {
      let details = {
        username: this.username,
        repoName: this.repoName,
        datasetName: this.datasetName,
      };
      this.$store.dispatch("datasets/getDataset", details);
    },

    loadData() {
      let details = { datasetId: this.dataset.Oid, page: 0, perPage: 100 };
      this.dispatch("datasets/queryDataset", details);
    },

    uploadFiles() {
      this.uploading = true;
      const client = openFilePicker();
      var options = {
        accept: ["text/*", "image/*", "video/mp4", "audio/*"],
        onUploadDone: this.filesUploaded,
        onCancel: (e) => {
          this.uploading = false;
        },
        maxFiles: 1,
        transformations: { crop: false, circle: false, rotate: false },
      };
      client.picker(options).open();
    },

    filesUploaded(fileInfo) {
      var filesUploaded = fileInfo.filesUploaded;
      // Remove the originalFile embedded document business — redundant information.
      filesUploaded.forEach((file) => {
        delete file.originalFile;
      });

      let details = { datasetId: this.dataset.Oid, files: filesUploaded };
      this.$store.dispatch("datasets/addFiles", details);
    },

    complete() {
      this.$emit("uploaded");
    },
  },

  computed: {
    isText() {
      return this.dataset.mimetype.split("/")[0] == "text";
    },

    isImage() {
      return this.dataset.mimetype.split("/")[0] == "image";
    },

    isVideo() {
      return this.dataset.mimetype.split("/")[0] == "video";
    },

    data() {
      // RETURN example data associated with this dataset.
      return this.$store.state.data;
    },

    links() {
      return [
        {
          name: this.$store.getters['users/nickname'] + "'s repositories",
          route: {
            name: "Repos",
            params: { username: this.username },
          },
        },
        {
          name: this.repoName,
          route: {
            name: "Repo",
            params: { username: this.username, name: this.repoName },
          },
        },
        {
          name: this.dataset.name,
          route: {
            name: "Dataset",
            params: {
              username: this.username,
              repoName: this.repoName,
              datasetName: this.datasetName,
            },
          },
        },
      ];
    },

    dataset() {
      return this.$store.state.datasets.current;
    },
  },

  mounted() {
    this.loadDataset();
    // EventBus.$on("files-uploaded", this.loadData); // listening to VUEX Store
  },
};
</script>

<style></style>
