<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
<div class="editor video">
  <video-component :content="content" @getSignedRequest="request"></video-component>
   <div class="or embed">
    <p><span>________________</span>OR<span>________________</span></p>
  </div>

    <label for="videourl">Enter a YouTube URL to Embed</label>
    <input type="text" id="videourl" name="videourl" v-model="youtubeUrl"/>
    <div class="actions" @click="getYoutubeUrl">
      <submit-button >Embed</submit-button>
    </div>

  <div class="or">
    <p><span>________________</span>OR<span>________________</span></p>
  </div>
  <upload-content
    uploadText="Drag a video file to Upload or Click Here"
    imageType="/images/button-video5.png"
    ref="video"
    :content="content"
  >
  </upload-content>
</div>

</template>

<script>
import UploadContent from '@/views/Narrator/upload-content.vue';
import VideoComponent from '@/components/audio_video_components/video-component.vue';
import SubmitButton from '@/components/forms/submit-button.vue';

export default {
  components: {UploadContent, VideoComponent, SubmitButton},

  props: ["content"],

  data() {
    return {
      youtubeUrl: ""
    };
  },

  methods: {
    request(fileObject) {
      this.$refs.video.getSignedRequest(fileObject);
    },
    async getYoutubeUrl() {

      var url = await $samsa.correct_url(this.youtubeUrl)
      this.content.url = url
      console.log(url);
    }
  },

  computed: {

  },

};

</script>

<style scoped lang="scss">

  .editor {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .or {
      font-size: 20px;
      font-weight: 900;
      text-align: center;
      margin-bottom: 5px;
    }
    .or p {
      font-size: 0.825em;
      span {
        font-size: 0.5em;
        vertical-align: super;
        padding: 0 5px;
      }
    }
  }


.or.embed {
  margin-top: 1em;
  margin-bottom: 0.7em;;
}
.editor.video {
  text-align: center;
  label {
    color: #fff;
    font-size: 0.95em;
  }
  input {
    margin: 15px;
    width: 80%;
  }

  .actions {
    width: 60%;
    margin: 10px auto;
    text-align: center;
  }
}
.upload-component {
  flex: inherit;
}
</style>
