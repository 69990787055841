<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="flex flex-col earth-background" id = "blurry">

    <div class="content about main our-teams">
      <transition name="fade" appear>
        <div class="header-section">
          <div class="header-content">
            <h1 class="hero">OUR TEAM</h1>
            <a href="#about-section" class="scroll-arrow">&#8595;</a>
          </div>
        </div>
      </transition>

      <div id="about-section" class="about people">
        <div class="casting left">
          <div class="profile-images">
            <img src="/images/team/brett_northcutt.jpg" />
            <h2>Brett Northcutt <span>Chief Commercialization Officer</span></h2>
            <a href="https://www.linkedin.com/in/bgnorthcutt/" target="_blank" name="linkedin-brett"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
          <div class="profile-copy">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
        </div>
        <!--<div class="casting right">
          <div class="profile-copy">
            <p>As an engineer and CEO, I aspired to develop ideas that would provide people with a chance to make a difference in the world. For more than twenty-five years, I led entrepreneurial ventures and advanced technology development, from company creation and team building to product development and licensing. Since 2004, I've been responsible for leading all day-to-day operations of the company, financial oversight (P&L), strategic planning, business development, and operations. Thankfully, my eyes have been opened along the way through my family's experiences in raising kids who live in the margins. It was through our advocacy work that I witnessed the transformative power of storytelling – this became the motivation behind Springmatter. It is both my passion and responsibility to foster an inclusive culture that allows people to live and work authentically while building products that bring us closer together.</p>
          </div>
          <div class="profile-images">
            <img src="/images/team/ptchoryk.jpeg" />
            <h2>Pete Tchoryk <span>CEO</span></h2>
            <a href="https://www.linkedin.com/in/petertchoryk/" target="_blank" name="linkedin-peter"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
        </div>-->
      </div>
    </div>

  </div>
</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";

export default {
  components: { Breadcrumbs, NavbarDash },

  props: [],

  data() {
    return {
      name:"about"
    };
  },

  watch: {},

  methods: {
  },

  computed: {},


  mounted() {},
};

$( document ).ready(function() {

$(window).resize(function() {
		if ($(window).width() < 767) {
		    $('.casting.right.brett .profile-images').insertBefore('.casting.right.brett .profile-copy');
        $('.casting.right.matt .profile-images').insertBefore('.casting.right.matt .profile-copy');
        $('.casting.right.eli .profile-images').insertBefore('.casting.right.eli .profile-copy');
        $('.casting.right.david .profile-images').insertBefore('.casting.right.david .profile-copy');
        $('.casting.right.darryl .profile-images').insertBefore('.casting.right.darryl .profile-copy');
        $('.casting.right.ryan .profile-images').insertBefore('.casting.right.ryan .profile-copy');
		} else {
        $('.casting.right.brett .profile-images').insertAfter('.casting.right.brett .profile-copy');
        $('.casting.right.matt .profile-images').insertAfter('.casting.right.matt .profile-copy');
        $('.casting.right.eli .profile-images').insertAfter('.casting.right.eli .profile-copy');
        $('.casting.right.david .profile-images').insertAfter('.casting.right.david .profile-copy');
        $('.casting.right.darryl .profile-images').insertAfter('.casting.right.darryl .profile-copy');
        $('.casting.right.ryan .profile-images').insertAfter('.casting.right.ryan .profile-copy');
		}
	}).resize();

});

</script>

<style>

</style>
