<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>

<div class="text-panel">

<vue-editor v-model="content.text" ref = "editor" :editorToolbar="customToolbar"></vue-editor>
  <div class="delete" @click="delete_element">Delete</div>
</div>


</template>

<script>
import { VueEditor, Quill } from "vue2-editor";

// Generate code-friendly font names
function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, "-");
}

const sizeStyle = Quill.import('attributors/style/size')
sizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px']
Quill.register(sizeStyle, true);

// Specify Quill fonts
const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana'];
const fontNames = fontList.map(font => getFontName(font));
const fonts = Quill.import('formats/font');
fonts.whitelist = fontNames;
Quill.register(fonts, true);

// Add fonts to CSS style
let fontStyles = "";
fontList.forEach(function(font) {
  let fontName = getFontName(font);
  
  fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=' + fontName + ']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=' + fontName + ']::before {" +
    "content: '" + font + "';" +
    "font-family: '" + font + "', sans-serif;" +
    "}" +
    ".ql-font-" + fontName + "{" +
    " font-family: '" + font + "', sans-serif;" +
    "}";
});

export default {
  components: { VueEditor },

  props: ["content"],

  data() {
    return {

      customToolbar: [
        [{"font": fonts.whitelist}],
        // [{ "header": [false, 1, 2, 3, 4, 5, 6] }],
        [{ 'size': [false, '10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '40px', '46px'] }],
        ["bold"],
        ["italic"],
        ["underline"],
        ["strike"], //, toggled buttons
        ["blockquote"],
        ["code-block"],
        [{ list: "ordered" }],
        [{ list: "bullet" }], // superscript/subscript
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ['link'],
        ["clean"], // remove formatting button
        [{ 'color': [] }]]

    };
  },

  watch: {
    content:function(before, after){
      // console.log("CONTENT SET")
      // this.text = before.text

    },

  },

  methods: {
    delete_element(){
      $events.$emit("delete", this.content);
    }
  },

  computed: {

  },

  mounted() {

  },
};
</script>

<style lang="scss">

@import "~vue2-editor/dist/vue2-editor.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.text-panel {
  .delete{
    display: inline-block;
    text-align: center;
    line-height: 1em;
    font-size: 1em;
    width: auto;
    height: auto;
    margin: 20px 0 20px 27%;
    background-color: #d20000;
    color: #ffffff;
    border: 0;
    border-radius: 5px;
    padding: 1em 4em;
    transition: all .3s ease-in;
    @media only screen and (max-width: 767px) {
      width: 90%;
      margin: 2em 5%;
    }
    &:hover{
      background-color: #fff;
      color: #d20000;
      cursor: pointer;
      transition: all .3s ease-in;
    }
  }
}

.cd-panel__header {
  .close {
    color: #757575;
  }
}
.ql-font span[data-value="arial"]::before {
  font-family: "Arial";
  content: "Arial" !important;
}

.ql-font-arial {
  font-family: "Arial";
}

.ql-font span[data-value="courier"]::before {
  font-family: "Courier";
  content: "Courier" !important;
}

.ql-font-courier {
    font-family: "Courier";
  }

.ql-font span[data-value="garamond"]::before {
  font-family: "Garamond";
  content: "Garamond" !important;
}

.ql-font-garamond {
  font-family: "Garamond";
}

.ql-font span[data-value="tahoma"]::before {
  font-family: "Tahoma";
  content: "Tahoma" !important;
}

.ql-font-tahoma {
  font-family: "Tahoma";
}

.ql-font span[data-value="times-new-roman"]::before {
  font-family: "Times New Roman";
  content: "Times New Roman" !important;
}

.ql-font-times-new-roman {
  font-family: "Times New Roman";
}

.ql-font span[data-value="verdana"]::before {
  font-family: "Verdana";
  content: "Verdana" !important;
}

.ql-font-verdana {
  font-family: "Verdana";
}

</style>
