<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
	<div>
		<link
			href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
			rel="stylesheet"
			integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
			crossorigin="anonymous"
		/>
		<h1>This to test Bootstrap!</h1>
	</div>
</template>

<script>
	export default {
		components: {},

		props: {},

		data() {
			return {
				opacity: 100,
			};
		},

		updated: function() {},
		watch: {},
		methods: {},

		computed: {},

		mounted() {},
	};
</script>

<style scoped></style>
