<!--
    Copyright 2019-2021 Michigan Aerospace Corporation
    All Rights Reserved
    Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
<div class="bubble-container" v-if="index <= idx">

    <div v-if="question.question" class="bubble-left bubble-bottom-left">
        {{ question.question }}
    </div>
    
    <div class="bubble-right bubble-bottom-right" v-if="question.answerType">
        <audio-component v-if="question.answerType == 'AUDIO'" :content="question.content"></audio-component>
        <video-component v-if="question.answerType == 'VIDEO'" :content="question.content"></video-component>
        <text-editor v-if="question.answerType == 'TEXT'" :content="question.content"></text-editor>
        <button class="actions__button" @click="showNextQuestion">Submit</button>
    </div>

</div>
</template>

<script>
import AudioComponent from "@/components/audio_video_components/audio-component.vue";
import VideoComponent from "@/components/audio_video_components/video-component.vue";
import TextEditor from "@/views/Narrator/editors/text.vue";

export default {
    components: {AudioComponent, VideoComponent, TextEditor},

    props: ["content", "type", "idx", "question", "index"],

    data() {
        return {
            opacity: 100,

        };
    },

    updated: function() {},
    watch: {},
    methods: {
        showNextQuestion() {
            this.$emit("showNextQuestion");
        }
    },

    computed: {},

    mounted() {
        
    },
};

</script>

<style scoped lang="scss">
.bubble-container {
    display: flex;
    flex-direction: column;
}

.bubble-right {
    position: relative;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 24px;
    width: 500px;
    background: $blue;
    border-radius: 5px;
    padding: 24px;
    text-align: center;
    color: $white;
    box-shadow: 0 0 11px rgba(0,0,0,.15);
    margin: 25px;
    align-self: flex-end;
    
}

.bubble-left {
    position: relative;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 24px;
    max-width: 500px;
    background: $greyFont;
    border-radius: 5px;
    padding: 24px;
    text-align: center;
    color: $white;
    box-shadow: 0 0 11px rgba(0,0,0,.15);
    margin: 25px;
    align-self: flex-start;
}

.bubble-bottom-left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid $greyFont;
  border-right: 12px solid transparent;
  border-top: 12px solid $greyFont;
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -24px;
}

.bubble-bottom-right:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-right: 24px solid $blue;
  border-left: 12px solid transparent;
  border-top: 12px solid $blue;
  border-bottom: 20px solid transparent;
  right: 32px;
  bottom: -24px;
}

</style>