<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
<div ref="tile" id="tile" class="dropped" :style="{height:height}" tile=true>
    <div id="image_layer" ref="image_layer" class="layer row">
        <viewer :view="view" v-for="item, index in this.content" :key="index" :scrollTop="scrollTop" :content="item" :position="position(item)"  :maxWidth="maxWidth(item)" :size="full"></viewer>
    </div>
</div>
</template>


<script>
import Vue from 'vue'
import Viewer from "@/views/Narrator/viewers/viewer.vue"


function getOffset( el ) {
    var _x = 0;
    var _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}


export default {
  components: {Viewer},


  props: {
    scrollTop:{default:0},
    content:{default:[]},
    view:{default:false}
  },

  data() {
    return {

      is_animating:false,
      blanks:[],
      test:false,
      dropped:false,
      contents:null,
      type:"",
      preview:"",
      previeww:"",
      float:null,
      preview_tile:null,
      preview_mode:null,
      preview_index:0,
      layer_mode:null,
      is_hovering:false,
    };
  },

  watch: {
    scrollTop(before,after){
      //console.log("SCROLL", before, after)
      if(this.float){
        var top = parseInt(this.float.style.top, 10) + (after-before) + "px";
        this.float.style.top = top;
        //console.log(top)

      }
    }
  },

  methods: {
    position(element){
      let maxWidth = this.maxWidth(element);
      if(element.x == .5 && maxWidth == 2)
        return "right"
      else if(element.x==.5 && maxWidth == 3)
        return "middle_third"

      if(element.x<.5)
        return "left"
      else if (element.x>.5)
        return "right"
      else
        return "middle"

    },
    neighbors(element){
      let row = [];

      this.content.forEach((item, i) => {
        if(this.sameLayer(element,item)){
          if(item == element)
            return;
          if(element.top == item.top || (element.top == item.top+1 && item.full))
            row.push(item);
          if((element.top + 1 == item.top && element.full))
            row.push(item);
        }
      });

      return row
    },
    calcMaxWidth(index){
      let count = 0
      var self = this;
      this.content.forEach((item, i) => {
          let current = (item == self.preview_tile)
          if((index == item.top) || (index - 1  == item.top) || (index + 1 == item.top) || current)
            count+=1;
      });
      return count;
    },
    sameLayer(element_one, element_two){
      //CML- Override for now. Only one layer
      return true;
      if(element_one==null)
        return true;
      if(element_one.type == "AUDIO" || element_two.type=="AUDIO")
        return false;
      if((element_one.type=="TEXT" && element_two.type!="TEXT") || (element_two.type=="TEXT" && element_one.type!="TEXT")){
        return false;
      }
      else{
        return true;
      }
    },
    maxWidth(element){
      let top = element.top;
      let full = element.full;
      let top_count = 0;
      let bottom_count = 0;
      if(full)
        bottom_count+=1;

      this.content.forEach((item, i) => {
        if(this.sameLayer(item, element)){
          if(top == item.top || (top == item.top+1 && item.full))
            top_count+=1;
          if((top + 1 == item.top && full))
            bottom_count+=1;
        }
      });

      let count = Math.max(top_count, bottom_count)

      return count;
    },
    animate:function(){
      var self = this;
      this.is_animating = true;
      if(this.timeout)
        window.clearTimeout(this.timeout)
      this.timeout = window.setTimeout(function(){self.preview_rect = null; self.is_animating = false;},200)
    },
    hover:function(x,y,type,elem,content){
      if(this.is_hovering)
        return;
      var self = this;

      if(this.hover_timeout)
        window.clearTimeout(this.hover_timeout)
      this.hover_timeout = window.setTimeout(function(){self.is_hovering = true;self.place(x,y,type,elem,content)},100)
    },
    drop:function(tile, type){

      this.is_hovering = false;
      this.preview_tile = null;
      this.preview_mode = null;
      this.preview_rect = null;

    },
    add:function(){
      var child = document.createElement("div");
      this.$refs.con.appendChild(child)
    },
    clear:function(){

      this.is_hovering = false;
      if(this.preview_tile){
        var tile = this.preview_tile
        const index = this.content.indexOf(tile);

        if (index > -1){
            this.content.splice(index,1)
        }

        this.preview_mode = null;
        this.preview_rect = null;
        this.preview_tile = null;
      }

    },


    stack:function(x, elements){
      var self = this;
      if(elements.length==0)
        return;
      elements.push(this.preview_tile)
      elements.sort(function(a,b){
        if(a.x>b.x)
          return 1;
        else {
          return -1
        }
      });

      let allow_swap = true
      elements.forEach((item, i) => {
          if(self.calcMaxWidth(item.top)>2)
            allow_swap = false;
      });

      if(!allow_swap)
        return;

      if(elements.length==2){
        elements[0].x=.25
        elements[1].x=.75
      }
      // else{
      //   elements[0].x=.25
      //   elements[1].x=.5
      //   elements[2].x=.75
      // }

    },
    place_audio:function(x,y,type,elem,content){

      if(this.preview_tile==null && content!=null){
        this.preview_tile = content;
        if(!this.content.includes(content))
          this.content.push(content)
      }

      if(this.preview_tile == null){
        console.log("NEW TILE")
        let rect = this.$el.getBoundingClientRect()
        let tile = {type:type, x:(x-32)/rect.width * 100, text:null, top:(y-32)/rect.height * 100, url:null, index:0, visible:false, full:false, alignment:"top", position:"middle"};
        this.preview_tile = tile;

        this.content.push(tile)


        window.setTimeout(function(){
          tile.visible = true;
        },50)
      }
      else{
        let rect = this.$el.getBoundingClientRect()

        this.preview_tile.x = (x-32)/rect.width * 100;
        this.preview_tile.top = (y-32)/rect.height * 100;

      }
    },
    place:function(x,y,type,elem,content){
      if(type == "AUDIO")
        return this.place_audio(x,y,type,elem,content)


      this.hover(x,y,type,elem,content);

      if(this.is_hovering==false)
        return;

      let client_height = this.$el.parentNode.parentNode.clientHeight/2;
      let t_index = Math.floor(y/client_height);
      let remainder = y/client_height - t_index

      if(this.preview_tile==null && content!=null){
        this.preview_tile = content;
        if(!this.content.includes(content))
          this.content.push(content)
      }

      if(this.is_animating)
        return;

      if(this.preview_mode==null || this.preview_rect==null){
        this.preview_rect = this.$el.getBoundingClientRect();
        }


      var elements = document.elementsFromPoint(x, y);
      let existing_element = null;
      elements.some((item, i) => {
        //console.log(i,item)
        if(item.classList.contains("element")){
          existing_element = item
          return true;
        }
      });


      let rect = this.preview_rect;
      //if(existing_element)
      //  rect = existing_element.getBoundingClientRect();

      x =  x - rect.x
      y =  y - rect.y

      let width = rect.width;
      let height = rect.height;

      const l = x/width;
      const r = (width - x)/width;
      const t = y/height
      const b = (height - y)/height;


      if(this.preview_tile == null){

        if(this.calcMaxWidth(t_index) >= 2)
          return;
        //CML- Only full for now...
        let full = true;
        //if(remainder>.5 && this.calcMaxWidth(t_index+1) < 3){
        //  full = true;
        //}
        let tile = {type:type, x:x, text:null, top:t_index, url:null, index:0, visible:false, full:full, alignment:"top", position:"middle"};
        this.preview_tile = tile;

        this.content.push(tile)

        this.preview_index = this.content.indexOf(tile);

        window.setTimeout(function(){
          tile.visible = true;
        },50)
      }
      else{
        //CML- Only full for now...
        let full = true;
        //if(remainder>.5 && this.calcMaxWidth(t_index+1) < 3){
        //  full = true;
        //}


        if(this.calcMaxWidth(t_index) > 2){
          console.log("RET")
          return
        }
        console.log("SETTING TOP TO ", t_index)
        this.preview_tile.top = t_index;
        //if(t_index==this.preview_tile.top)
        this.preview_tile.full = full;
      }

      if(l < .4){
          this.preview_tile.x = l;
          this.animate()
      }
      else if (r < .4){
        this.preview_tile.x = l;
        this.animate()
      }
      else if(r > .4 && l > .4 ){
       this.preview_tile.x = .5;
       this.animate()
      }

      console.log("T:",t_index,this.calcMaxWidth(t_index))
      let neighbors = this.neighbors(this.preview_tile);
      this.stack(l, neighbors)

    },



  },

  computed: {
    height(){


      let max_index = 0
      this.content.forEach((item, i) => {
        if(item.top>max_index && item.type != "AUDIO")
          max_index = item.top;
      });


      let height = document.body.clientHeight;
      //height = height/2;
      //Header & Footer are 115px Tall
      return height*(max_index+3) - 115 + 'px'
    },
    full() {
      let ret = false;
      this.content.some((item, i) => {
        if(item.alignment == "bottom" || item.full){
          ret = true;
          return;
        }
      });
      return ret;
    },
  },

  mounted() {
    let self = this;
    window.$events.$on("drag_start",function(){self.is_hovering=false;})
  },
};
</script>

<style scoped>

.drag_tile{
  background-color: #00F5;
  height: 100vh;
  width:100vw;
}

.layer{
  width:100%;
  height:100%;
  position:relative;
  top:0px;
}

.row{
  pointer-events: none;
  width:100%;
  height:100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  flex:1;
}

.column{
  pointer-events: none;
  width:100%;
  height:100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-flow: column wrap;
}

#tile{
  position:relative;
  transition: ease all .5s;
  /*background-color: #acacac;*/
  width:100%;
  z-index: 1;
}

.full{
  height:calc(100vh - 123px);
}

.half{
  height:calc(50vh - 63px);
}

.dropped{
  pointer-events: inherit;

}

.dead{
  pointer-events: none !important;
}


.blank{
  background-color: #AEAEAE;
  border: 1px solid white;
  pointer-events: all;
  transition: ease all .5s;
  flex:0;
  /*padding:5px;*/
  display: flex;
  flex-direction: row;
  height:100%;
  position: relative;
  justify-content:flex-start;
  align-items: flex-start;
  overflow: hidden;
}

.dead > div{
  /*pointer-events: none !important;*/
}

.dead .blank{
  pointer-events: none !important;
}



#text_layer .blank{
  /*background-color: #ac00ac77;*/
}


.visible{
  flex:1;
}


.show {
  flex: 1;
  /*animation: moveAnim .3s alternate 1;*/
}

@keyframes moveAnim {
  from { flex: none }
  to{ flex: 1 }
}

/*
#container {
  height: 150px;
  display:flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-flow: row wrap;


}

#container div {
  width: 150px;
  height: 125px;
  display: inline-block;
  background: red;
  transition: ease all 1s;
}

#con:after {
  content: '';
  width: 100%;
  display: inline-block;
}

.ignore{
    pointer-events:none;
}

#image_layer{
  position:absolute;
  top:0;
  width:100%;
  height:100%;
}

#text_layer{
  position:absolute;
  top:0;
  height:100%;
  width:100%;
}

.rowcontent{
  flex-grow: 1; flex-shrink: 0; flex-basis: auto;
  display:flex;

}

svg{

}

.containers {
  position: relative;

}


.centered {
  position: absolute;
  width:50%;
  height:50% !important;
  overflow: hidden;
  left:25%;
  top:25%;
  transition: ease all 1s;
}

.hide .centered{
  width:0%;
  height:0%;
  left:50%;
  top:50%;
}

.center{
  max-width: 50%;
  max-height: 50%;
}

.tile{
  display:flex;
  width:100%;
  height:100%;

  flex-direction: column;

}

.column {
    pointer-events:none;
    display:flex;
    height:100%;
    width:100%;

    flex:1;
    flex-direction: row;
    flex-flow: row wrap;
  }



.row {
    pointer-events:none;
    display:flex;
    flex-direction: column;

    flex:1;
  }

.t {
    pointer-events:none;

    flex-grow: 1; flex-shrink: 0; flex-basis: auto;
    overflow: hidden;
  }


.dummy {
  max-height:0;
  transition: ease all 1s;

}

.column:hover .hovered {
  flex-grow: 1; flex-shrink: 0; flex-basis: auto;
}

.tile:hover .hovered {

}


.flex-show{
  flex-grow: 1; flex-shrink: 0; flex-basis: auto;
  max-height: 100%;
}

.show{
  flex-grow: 1; flex-shrink: 0; flex-basis: auto;
  max-height: 100%;
}

.hide{
  overflow: hidden;
  max-height: 0px;
  height:0px;
  width:0px;
}

*/

</style>
