<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="ml-4 mb-4 w-1/2 mt-6 sm:rounded-md">
    <ul>
      <li
        class="mb-6 border border-gray-400 shadow bg-gray-50 rounded-md"
        v-for="(desc, index) in dataset.descriptors"
      >
        <div class="p-4 flex justify-between">
          <div class="text-lg leading-5 text-gray-600">
            <label for="dataType" class="block text-sm leading-5 text-gray-600"
              >Column name</label
            >

            <input
              v-model="desc.name"
              class="text-sm p-2 border border-gray-500 rounded-md w-100 text-gray-600"
            />
          </div>

          <div class="text-lg leading-5 text-gray-600">
            <label for="dataType" class="block text-sm leading-5 text-gray-700"
              >Units</label
            >
            <input
              v-model="desc.units"
              class="text-sm p-2 border border-gray-500 rounded-md w-100"
            />
          </div>

          <div>
            <div class="">
              <label
                for="dataType"
                class="block text-sm leading-5 text-gray-700"
                >Data type</label
              >
              <select
                id="dataType"
                v-model="desc.dataType"
                class=" form-select block w-full pl-3 pr-10 py-2 text-base
                text-gray-600 leading-6 border-gray-300 focus:outline-none
                focus:shadow-outline-blue focus:border-blue-300 sm:text-sm
                sm:leading-5"
              >
                <option>int</option>
                <option selected>float</option>
                <option>str</option>
                <option>bool</option>
                <option>datetime</option>
              </select>
            </div>
          </div>
        </div>

        <div class="p-4">
          <label
            for="dataType"
            class="block text-sm leading-5 font-medium text-gray-600 w-2/3"
            >Column description</label
          >
          <textarea
            v-model="desc.description"
            class=" border border-gray-600 rounded-md w-full p-2"
          ></textarea>
        </div>

        <div class="px-4 pb-4">
          <label
            for="dataType"
            class="block text-sm leading-5 font-medium text-gray-600 w-2/3"
            >Example data</label
          >
          <span class="text-gray-400">
            {{ desc.examples }}
          </span>
        </div>
      </li>
    </ul>

    <div class="flex justify-end">
      <span class="ml-0 mt-6 inline-flex rounded-md shadow-sm">
        <button
          @click="completeAnnotations"
          class="inline-flex justify-center py-2 px-4 border border-transparent
          text-sm leading-5 font-medium rounded-md text-white bg-light-blue-500
          hover:bg-light-blue-400 focus:outline-none
          focus:border-light-blue-600 focus:shadow-outline-blue
          active:bg-light-blue-600 transition duration-150 ease-in-out"
        >
          Complete annotations
        </button>
      </span>
    </div>
  </div>
</template>

<script>
// import Component from "../component_location"
import { reportError, EventBus } from "@/utils.js";

export default {
  components: {},

  props: ["dataset"],

  data() {
    return {};
  },

  watch: {},

  methods: {
    complete() {
      this.$emit("complete");
    },

    completeAnnotations() {
      this.$store.dispatch("datasets/completeAnnotations", this.dataset);
    },
  },

  computed: {},

  mounted() {
    EventBus.$on("annotations-complete", this.complete);
  },
};
</script>

<style></style>
