// Copyright 2019-2021 Michigan Aerospace Corporation
// All Rights Reserved
// Author: Chad Lewis, David Vasseliou, Leslie Borst

import router from "@/router/index.js";
import { reportError } from "@/utils";

const state = { current: { datasets: [] }, list: [], validName: true };

const getters = {};

const mutations = {
  setRepos(state, repos) {
    state.list = repos;
  },

  setRepo(state, repo) {
    state.current = repo;
  },

  setNameValidity(state, validity) {
    state.validName = validity;
  },
};

const actions = {
  getRepos(context, username) {
    $samsa
      .getRepos(username)
      .then((res) => {
        context.commit("setRepos", res.data);
      })
      .catch((err) => {
        reportError(err);
      });
  },

  getRepo(context, details) {
    $samsa
      .getRepo(details.username, details.repoName)
      .then((res) => {
        var repo = res.data;
        context.commit("setRepo", repo);
      })
      .catch((err) => {
        reportError(err);
      });
  },

  checkName(context, details) {
    $samsa.isValidRepo(details.repoName, details.username).then((res) => {
      context.commit("setNameValidity", res.data);
    });
  },

  createRepo(context, details) {
    // Create a new repository!
    $samsa.createRepo
      .post(details.username, details.repo)
      .then((res) => {
        var repo = res.data.createRepo;
        context.commit("setRepo", repo);
        router.push({
          name: "Repo",
          params: { username: details.username, name: repo.name },
        });
      })
      .catch((err) => {
        reportError(err);
      });
  },

  deleteRepo(context, {repoId, username}) {
    $samsa.deleteRepo
      .post(repoId)
      .then((res) => {
        context.commit('setRepo', {});
        router.push({name:'Repos', params:{username: username}})
      })
      .catch((err) => {
        reportError(err);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
