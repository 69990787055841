<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div>
    <ul>
      <li
        class="flex flex-col mb-6 w-1/2 border border-gray-400 rounded-md"
        v-for="(dataset, idx) in datasets"
      >
        <router-link
          :to="{
            name: 'Dataset',
            params: {
              username: username,
              repoName: repoName,
              datasetName: dataset.name,
            },
          }"
          class="block p-4 rounded-md hover:bg-blue-100 focus:outline-none
          focus:bg-gray-50 transition duration-150 ease-in-out"
        >
          <div class="flex flex-row justify-between">
            <span class="text-lg font-semibold">
              {{ dataset.name }}
            </span>
            <span
              v-if="dataset.files.length > 0"
              class="text-sm uppercase text-gray-500"
            >
              {{ dataset.files[0].mimetype }}
            </span>
            <span v-else class="text-sm text-fray-500 uppercase">
              Unknown type
            </span>
          </div>

          <div>
            <span
              v-if="dataset.isConnected"
              class="text-blue-600 text-sm uppercase"
            >
              Connected
            </span>
            <span v-else class="text-red-600">
              Not connected
            </span>
            &bull;
            <span
              v-if="dataset.isAnnotated"
              class="text-blue-600 text-sm uppercase"
            >
              Anntotated
            </span>
            <span v-else class="text-red-600 text-sm uppercase">
              Not Anntotated
            </span>
          </div>

          <div class="text-gray-500 mt-4">
            {{ dataset.description }}
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
// import Component from "../component_location"

export default {
  components: {},

  props: ["username", "repoName", "datasets"],

  data() {
    return {};
  },

  watch: {},

  methods: {},

  computed: {},

  mounted() {},
};
</script>

<style></style>
