<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
<div id = "blurry">
  <div class="header"></div>
  <div class="changelog" >
    <div class="title">
      Change Log
    </div>

    <div class="copy">
      Find out what's new with SpringMatter in the change log below. Each release makes SpringMatter better, be it a new strategy, a general improvement, or just a bug fix. <!-- Have a bug or want to recommend a new feature? Please fill out the form below.-->
    </div>

    <div class="legend">
    <div class="circle bug">
      <i class="fas fa-bug"></i>
    </div>Bug Fix
    <div class="circle feature">
      <i class="fas fa-star"></i>
    </div> New Feature
    <div class="circle improvement">
      <i class="fas fa-wrench"></i>
    </div>General Improvement
    </div>

    <div class="version">v0.1.2 Release (Sept. 17th 2021)</div>
      <change-log-item type="feature">Viewer for Sharing Narratives</change-log-item>
      <change-log-item type="improvement">Edit settings dialog for narratives updated</change-log-item>
      <change-log-item type="bug">Add Paywall back with new Authentication</change-log-item>
      <change-log-item type="bug">Font's selection fixed in editor</change-log-item>


      <!--<div class="version">Next Up</div>-->



        <div class="version">Known Issues</div>
          <change-log-item type="bug">Ability to change background not working in Narrator</change-log-item>

          <div class="version">v0.1.1 Release (Sept. 16th 2021)</div>
            <change-log-item type="improvement">Record Video From Narrator</change-log-item>
            <change-log-item type="improvement">Record Audio From Narrator</change-log-item>
            <change-log-item type="bug">Fixed: Delete Button not working. Cannot delete elements in the Narrator</change-log-item>

        <div class="version">v0.1.0 Initial Beta Release (Sept. 13th 2021)</div>
          <change-log-item type="feature">New Version of Narrator Released</change-log-item>
          <change-log-item type="feature">Authentication Changed from AUTH0 to internal</change-log-item>
          <change-log-item type="feature">Portal Updated to use new Narrative design</change-log-item>

      <!--<change-log-item type="bug">Fixed the Login Screen</change-log-item>
      <change-log-item type="improvement">Fixed the Login Screen</change-log-item>-->
      <div id="bottom-section">
        <!--<div class="inner">
          <h2>Testimonials</h2>
          <p>What are recruiters and coaches saying about Athletes Stories<sub>&trade;</sub></p>
        </div>
        <carousel></carousel>-->


        <!--<div class="home-form-content">
          <h2>Got Suggesstions?</h2>
          <p>Don’t be shy. We would love to hear your feedback!</p>
          <div class="fl-col fl-node-5f17109620af6 form-container home" data-node="5f17109620af6">
            <div class="fl-col-content fl-node-content">
              <div class="fl-module fl-module-contact-form fl-node-5f17109f37f03" data-node="5f17109f37f03">
                <div class="fl-module-content fl-node-content">
                  <form class="fl-contact-form" >
                    <input type="hidden" name="fl-layout-id" value="508" />
                    <div class="fl-input-group fl-name">
                      <label for="fl-name">Name</label>
                      <span class="fl-contact-error">Your Name</span>
                      <input type="text" id="fl-name" name="fl-name" value="" placeholder="Your name" />
                    </div>
                    <div class="fl-input-group fl-email">
                      <label for="fl-email">Email</label>
                      <span class="fl-contact-error">Your Email</span>
                      <input type="email" id="fl-email" name="fl-email" value="" placeholder="Your email" />
                    </div>
                    <div class="fl-input-group fl-subject">
                      <label for="fl-subject">Subject</label>
                      <span class="fl-contact-error">Subject</span>
                      <input type="text" id="fl-subject" name="fl-subject" value="" placeholder="Subject" />
                    </div>
                    <div class="fl-input-group fl-message">
                      <label for="fl-message">Message</label>
                      <span class="fl-contact-error">Message</span>
                      <textarea id="fl-message" name="fl-message" placeholder="Your message" ></textarea>
                    </div>
                    <div class="fl-button-wrap fl-button-width-auto fl-button-left">
                      <a href="#" target="_self" class="button fl-button" role="button" name="contact-form-send">
                        <span class="fl-button-text">SEND</span>
                      </a>
                    </div>
                    <span class="fl-success-none" style="display:none;">Message Sent!</span>
                    <span class="fl-send-error" style="display:none;">Message failed. Please try again.</span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>



  </div>
</div>

</template>

<script>
import ChangeLogItem from "@/components/ChangeLogItem.vue";


export default {
  components: { ChangeLogItem },

  props: [],

  data() {
    return {
      name:"careers"
    };
  },

  watch: {},

  methods: {
  },

  computed: {},


  mounted() {},
};

</script>

<style scoped>

#blurry{
  background-color: #f7f9fa;

  justify-content: center;
}

.legend{
  display: flex;
}

.legend .circle{
  margin-left:30px;
}

.header{
  background-color: #7f8c8d;
  height:120px;
  width:100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.version{
  font-size: 24px;
  text-align: center;
  margin-top:50px;
  margin-bottom:15px;
}

.long{
  height:200vh;
}

.copy{
  width:50%;
  margin-bottom:50px;
}

.title{
  font-size: 36px;
  text-align: center;
  margin-top:50px;
  margin-bottom:15px;
}


.changelog{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.yellow{
  color:'yellow';
}


.bug{
  background-color: #e74c3c;
}

.feature{
  background-color: #2ecc71;
}

.improvement{
  background-color: #f1c40f;
}

.circle{

  padding-left: 4px;
  padding-top:0px;
  margin-right: 16px;
  color:white;
  border-radius:16px;
  width:25px;
  height:25px;
}
.fas {color: white;}
</style>
