<template>
    <div class="input-container">
        <label :for="id" class="input-container__label">
          <span>{{ labelText }}</span>
          <span class="secondary" v-if="secondary">{{ secondary }}</span>
        </label>
        <input
          :id="id"
          type="text"
          class="input-container__text-input"
          :value="value"
          @input="updateText($event.target.value)"
        />
    </div>

</template>

<script>


export default {
  components: {

  },
  props: ["id", "labelText", "value", "secondary"],

  data() {
    return{
      
    }
  },

  watch: {

  },

  methods: {
    updateText(text) {
      this.$emit('input', text);
    }
  },

  computed: {

  },

  mounted() {

  },
};

</script>

<style scoped lang="scss">
.input-container {
  &__text-input {
    width: 100%;
    outline: 0;
    margin: 0;
    text-align: left;
    height: 40px;
    max-width: 100%;
    padding: 5px 10px !important;
    transition: all ease 0.25s;
    background-color: $white;
    border: 1px solid;
    border-color: #ccc #e0e0e0 #e0e0e0 #ccc;
    border-radius: 3px;
  }

  &__label {

    .secondary {
      padding-left: 5px;
      font-size: .9em;
      font-style: italic;
    }
  }
}

</style>
