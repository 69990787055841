var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',_vm._l((_vm.datasets),function(dataset,idx){return _c('li',{staticClass:"flex flex-col mb-6 w-1/2 border border-gray-400 rounded-md"},[_c('router-link',{staticClass:"block p-4 rounded-md hover:bg-blue-100 focus:outline-none\n        focus:bg-gray-50 transition duration-150 ease-in-out",attrs:{"to":{
          name: 'Dataset',
          params: {
            username: _vm.username,
            repoName: _vm.repoName,
            datasetName: dataset.name,
          },
        }}},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('span',{staticClass:"text-lg font-semibold"},[_vm._v(" "+_vm._s(dataset.name)+" ")]),(dataset.files.length > 0)?_c('span',{staticClass:"text-sm uppercase text-gray-500"},[_vm._v(" "+_vm._s(dataset.files[0].mimetype)+" ")]):_c('span',{staticClass:"text-sm text-fray-500 uppercase"},[_vm._v(" Unknown type ")])]),_c('div',[(dataset.isConnected)?_c('span',{staticClass:"text-blue-600 text-sm uppercase"},[_vm._v(" Connected ")]):_c('span',{staticClass:"text-red-600"},[_vm._v(" Not connected ")]),_vm._v(" • "),(dataset.isAnnotated)?_c('span',{staticClass:"text-blue-600 text-sm uppercase"},[_vm._v(" Anntotated ")]):_c('span',{staticClass:"text-red-600 text-sm uppercase"},[_vm._v(" Not Anntotated ")])]),_c('div',{staticClass:"text-gray-500 mt-4"},[_vm._v(" "+_vm._s(dataset.description)+" ")])])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }