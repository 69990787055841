<template>
  <transition name="fade">
    <div class="rounded-md bg-red-50 p-4 w-1/2 left-1/4 absolute top-1/4 ">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: x-circle -->
          <svg
            class="h-5 w-5 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414
              1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293
              1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0
              00-1.414-1.414L10 8.586 8.707 7.293z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm leading-5 font-medium text-red-800">
            Something has gone wrong!
          </h3>
          <div class="mt-2 text-sm leading-5 text-red-700">
            <ul class="list-disc pl-5">
              <li>
                {{ message }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import Component from "../component_location"

export default {
  components: {},

  props: ['message'],

  data() {
    return {};
  },

  watch: {},

  methods: {},

  computed: {},

  mounted() {},
};
</script>

<style>
.fade-enter,
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
