<template>

  <form @submit.prevent="onSubmitted" >
    <fieldset class="top-heading">
      <h1 class="heading">
        {{ title }}
      </h1>
      <p class="desc">
        {{ description }}
      </p>
    </fieldset>

    <fieldset class="name">
      <text-input
        id="narrativeName"
        v-model="updatedNarrative.name"
        spellcheck="false"
        labelText="Narrative Name"
        secondary="">
      </text-input>
      
      <p class="message-hint">
        <span>* The entered name is preprocessed to match the required format.</span>
        <!-- <span v-if="!isNewNarrative">
          If narrative name is changed, the associated repository's name will be changed accordingly.
        </span> -->
      </p>
    </fieldset>

    <fieldset class="summary">
        <textarea-input
          id="description"
          v-model="updatedNarrative.description"
          labelText="Description"
          rows="3">
        </textarea-input>
    </fieldset>

    <fieldset class="privacy">
      <legend for="privacy">
        Privacy
      </legend>
      <radio-buttons 
        name="privacySetting"
        v-model="updatedNarrative.privacySetting"
        :labels="radioLabels"
        id="settings-option-"
        >
        
      </radio-buttons>
    </fieldset>
    <!-- </fieldset>
    <fieldset class="nar-tags">
      <selection-box
        :options="options"
        labelText="Select an Item"
        id="selection"
        v-model="selectValue"
      >
      </selection-box>
    </fieldset> -->

    <fieldset class="nar-tags">
      <text-input
        id="tags"
        v-model="updatedNarrative.tags"
        spellcheck="false"
        labelText="Tags"
        secondary="(comma separated — e.g.: sensor, health)">
      </text-input>
     
    </fieldset>

    <fieldset class="action-buttons">
      <submit-button type="submit" :loading="loading" :disabled="!isValidNarrativeName || checkingName">{{ submitButtonText }}</submit-button>
      <cancel v-if="$route.name === 'Narrator'" @click="cancel">
        <router-link :to="{ name: 'Portal'}">Cancel</router-link>
      </cancel>
      <cancel v-else @click="cancel">Cancel</cancel>
    </fieldset>
  </form>

</template>

<script>
import { cleanName, debounce, reportError, EventBus } from "@/utils.js";
import CButton from '@/components/Core/CButton.vue';
import Cancel from '@/components/forms/cancel.vue';
import SubmitButton from '@/components/forms/submit-button.vue';
import TextInput from '@/components/forms/text-input.vue';
import TextareaInput from '@/components/forms/textarea-input.vue';
import RadioButtons from '@/components/forms/radio-buttons.vue';
import SelectionBox from '@/components/forms/selection-box.vue';

export default {
  components: {
    CButton,
    Cancel,
    SubmitButton,
    TextInput,
    TextareaInput,
    RadioButtons,
    SelectionBox
  },
  props: ["username", "title", "description", "submitButtonText", "loading", "narrative", "isNewNarrative"],

  data() {
    return {
      updatedNarrative: Object.assign({}, this.narrative), // copy the original narrative
      checkingName: false,
      isValidNarrativeName: true,
      invalidNameHint: '',
      checksCounter: 0,
      selectValue: "",
      radioLabels: [
        {
          label: "Public",
          value: "public",
          order: 1
        },
        {
          label: "Private to narrative members",
          value: "private_to_members",
          order: 2
        },
        {
          label: "Private to you",
          value: "private_to_me",
          order: 3
        }
      ],
      options: [
        {
          option: "Please Select One",
          value: null
        },
        {
          option: "option 1",
          value: "option1"
        },
        {
          option: "option 2",
          value: "option2"
        },
        {
          option: "option 3",
          value: "option3"
        }
      ]
    };
  },

  watch: {
    narrative: function(after,before){
      console.log("narrative is here", after);
      this.updatedNarrative = Object.assign({}, after);
    }
  },

  methods: {
    onSubmitted() {
      
      if (!this.updatedNarrative.name) {
        this.isValidNarrativeName = false;
        this.invalidNameHint = 'Please enter narrative name.';
        this.checkingName = false;
        this.checksCounter++;
      } else {
        this.$emit('save', this.updatedNarrative);
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    narrativeCreated() {
      var narrativeName = this.$store.state.narratives.current.name;

    },

    createNarrative() {
      let details = { username: this.username, narrative: this.narrative };
      this.$store.dispatch("narratives/create", details);
    }


  },

  computed: {
    fullPath() {
      if(this.updatedNarrative == null || this.updatedNarrative.name == null)
        return "";
      if (this.updatedNarrative.name.length > 0) {
        return `(${this.username}/narratives/${cleanName(
          this.updatedNarrative.name
        )})`;
      } else {
        return "";
      }
    },

    markNameAsValid() {
      return this.checksCounter > 0 && !this.checkingName && this.isValidNarrativeName;
    },

    markNameAsInvalid() {
      return this.checksCounter > 0 && !this.checkingName && !this.isValidNarrativeName;
    }
  },

  mounted() {

  },
};

</script>

<style scoped lang="scss">

  .top-heading {
    color: $white;
    background: $blue;
    padding: 10px;
    text-align: center;
    .heading {
      font-size: 2em;
    }

    .desc {
      font-weight: 500;
    
    }
  }

  .name {
    padding: 25px 25px 0px 25px;

  }

  .summary {
    padding: 25px 25px 25px 25px;

  }

  .privacy {
    padding: 0px 25px 0px 25px;
  }

  .nar-tags {
    padding: 25px 25px 0px 25px;
  }

  .action-buttons {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  input {
    
    .invalid {
      border-color: $red;

      &:focus {
        box-shadow: 0 0 5px $red;
        border-color: $red;
      }
    }

    .valid {
      border-color: $green;

      &:focus {
        box-shadow: 0 0 5px $green;
        border-color: $green;
      }
    }
  
  }

  .message-hint {
    margin-top: .25rem;
    font-size: .875em;
    font-style: italic;
  }

  .invalid-message {
    color: $red;
  }

  .valid-message {
    color: $green;
  }
</style>
