<template>
	<div>




        <div class="task_element">

        <div v-if="type=='bug'" class="circle bug">
          <i class="fas fa-bug"></i>
        </div>
        <div v-if="type=='feature'" class="circle feature">
          <i class="fas fa-star"></i>
        </div>
        <div v-if="type=='improvement'" class="circle improvement">
          <i class="fas fa-wrench"></i>
        </div>


        <div class="task_description">
          <slot>Unknown Task</slot>
        </div>
      </div>


  </div>
</template>

<script>
	export default {
		components: {},

		props: {type:{default:"bug"}},

		data() {
			return {
				opacity: 100,
			};
		},

		updated: function() {},
		watch: {},
		methods: {},

		computed: {},

		mounted() {},
	};
</script>

<style scoped>

.task_element{
  display:flex;
  align-items: center;
  padding-left:5px;
  height:50px;
  width:500px;
  border-radius: 5px;
  background-color: white;
  margin-bottom:10px;

}

.task_description{
  color:grey;
}

.bug{
  background-color: #e74c3c;
}

.feature{
  background-color: #f1c40f;
}

.improvement{
  background-color: #2ecc71;
}

.circle{

  padding-left: 4px;
  padding-top:0px;
  margin-right: 16px;
  color:white;
  border-radius:16px;
  width:25px;
  height:25px;
}
.fas {color: white;}
</style>
