<template>
	<div style="width: 60%; margin: 0 auto; height: calc(100vh - 115px);">
        <div>radio button value: </div> 
        {{ thing1 }}
        <radio-buttons 
            :labels="labels" 
            v-model="thing1" 
            name="radioname" 
            id="radioStuff">
        </radio-buttons>

        <div>chekbox button values: </div> 
        {{ checkedValues }}
        <checkbox-buttons 
            :labels="labels" 
            v-model="checkedValues" 
            id="checkboxStuff">
        </checkbox-buttons>

        <div>Select Dropdown:  </div>
        {{ selectValue }}
        <selection-box
            :options="options"
            labelText="Select an Item"
            id="selection-"
            v-model="selectValue"
        >
        </selection-box>
        
        <div>textarea value: </div>
        {{ thing3 }}
        <textarea-input
            id="description"
            v-model="thing3"
            labelText="Text Area"
            rows="3">
        </textarea-input>

        <div>textbox value: </div> 
        {{ thing4 }}
        <text-input
            id="narrativeName"
            v-model="thing4"
            spellcheck="false"
            labelText="Text Input"
            secondary="">
        </text-input>
    </div>
</template>

<script>
    import TextInput from '@/components/forms/text-input.vue';
    import TextareaInput from '@/components/forms/textarea-input.vue';
    import RadioButtons from '@/components/forms/radio-buttons.vue';
    import CheckboxButtons from '@/components/forms/checkbox-buttons.vue';
    import SelectionBox from '@/components/forms/selection-box.vue';
	export default {
		components: {
            TextInput,
            TextareaInput,
            RadioButtons,
            CheckboxButtons,
            SelectionBox
        },

		props: {},

		data() {
			return {
                checkedValues:["option02"],
                selectValue: "",
				thing1: "",
                thing2: [],
                thing3: "",
                thing4: "",
                thing5: "",
                label: "this is a label",
                labels: [
                    {
                    label: "option 1",
                    value: "option1"
                    },
                    {
                    label: "option 2",
                    value: "option2"
                    },
                    {
                    label: "option 3",
                    value: "option3"
                    }
                ],
                labels: [
                    {
                    label: "option 1",
                    value: "option01"
                    },
                    {
                    label: "option 2",
                    value: "option02"
                    },
                    {
                    label: "option 3",
                    value: "option03"
                    }
                ],
                options: [
                    {
                    option: "Please Select One",
                    value: null
                    },
                    {
                    option: "option 1",
                    value: "option1"
                    },
                    {
                    option: "option 2",
                    value: "option2"
                    },
                    {
                    option: "option 3",
                    value: "option3"
                    }
                ]
			};
		},
		methods: {


        },

		mounted() {
      
        },
	};
</script>

<style lang="scss "scoped>


</style>