<template>
<div class="record-container" v-if="cameraAccessible">
    <input id="videoFile" type="file" class="hidden" accept="video/*" capture="">
    <video id="videoStuff" ref="video" class="record-container__video" autoplay playsinline></video>
    <div class="record-container__buttons">
      <button v-if="!isRecording && !countStarted" id="btn-start-recording" @click="record" class="record"><i class="fas fa-circle"></i> <span>Record</span></button>
      <button v-else id="btn-stop-recording" @click="stop_recording" class="stop"><i class="fas fa-stop"></i> <span>Stop</span></button>
      <h2 class="count-down" v-if="countStarted">{{ countDown }}</h2>
    </div>
  </div>
  <div v-else class="cant-record">
    <p>Unable to capture your camera. Try a different Browser. ex. Chrome</p>
  </div>
</template>

<script>
export default {
  components: {},

  props: ["content"],

  data() {
    return {
      text:"",
      files:[],
      video: null,
      recorder: null,
      cameraAccessible: true,
      isRecording: false,
      countStarted: false,
      countDown: null
    };
  },

  watch: {
    files(after,before){
      console.log("I HAVE FILES", after)
    }
  },

  methods: {

    stopRecordingCallback: function() {
        console.log("STOP RECORDING")
        this.video.src = this.video.srcObject = null;
        this.video.muted = false;
        this.video.volume = 1;
        let blob = this.recorder.getBlob();

        this.video.src = URL.createObjectURL(blob);

        // generating a random file name
        var fileName = "video1.webm";

        // we need to upload "File" --- not "Blob"
        var fileObject = new File([blob], fileName, {
            type: 'video/webm'
        });

        this.$emit("getSignedRequest", fileObject);

        this.recorder.camera.stop();
        //this.recorder.destroy();
        //this.recorder = null;
    },

    captureCamera: function(callback) {

      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((camera) => {
        this.countDown = 4;
        let timer = setInterval(() => {
       this.countStarted = true;
        if(this.countDown > 0) {
          console.log(this.countDown);
          this.countDown = this.countDown - 1;

        } if(this.countDown == 0) {
            this.countStarted = false;
            callback(camera);
            console.log("start");
            clearInterval(timer);
          }

        }, 1000);


        }).catch(function(error) {
            this.cameraAccessible = false;

            console.error(error);
      });

    },

    record: function(){

      this.captureCamera(this.getCamera);
    },
    stop_recording: function() {
      this.isRecording = true;
      this.recorder.stopRecording(this.stopRecordingCallback);
    },
    getCamera: function(camera) {
      this.cameraAccessible = true;
      this.video.muted = true;
      this.video.volume = 0;
      this.video.srcObject = camera;
      this.recorder = RecordRTC(camera, {
          type: 'video',
          mimeType: 'video/webm'
      });

      this.recorder.startRecording();
      // release camera on stopRecording
      this.recorder.camera = camera;
      this.isRecording = true;

    }
  },

  computed: {

  },

  mounted() {
    this.video = this.$refs.video;  //document.querySelector('video');

  }
};

</script>

<style scoped lang="scss">

    video#videoStuff {
      background: #000;
    }

    .record-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;

      &__video {
        width: 100%;
      }

      &__buttons {
        margin-top: 10px;

        .record {
          color: $green;
          border: 1px solid grey;
          border-radius: 5px;
          font-size: 22px;
          padding: 3px 5px;
          margin-right: 5px;

          &:hover {
            color: darken($green, 10%);
            border-color: darken(grey, 10%);
            transition: all .3s;
          }
        }
        .count-down {
            color: $white;
            font-size: 2.5em;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .stop {
          color: $red;
          border: 1px solid grey;
          border-radius: 5px;
          font-size: 22px;
          padding: 3px 5px;

          &:hover {
            color: darken($red, 10%);
            border-color: darken(grey, 10%);
            transition: all .3s;
          }
        }
      }
    }

    .cant-record {
      text-align: center;
      color: grey;
      font-style: italic;
    }

    .camera {
      pointer-events: none;
      max-width: 65px;
      max-height: 143px;
      display: block;
      margin: 0px auto;
    }

    #btn-start-recording {
      padding: 0;
      color: #d20000;
      border: 0;
      margin: 0;
      transition: all 0.3s ease-in;
      outline: 0;
      &:hover {
        color: #fff;
        transition: all 0.3s ease-in;
      }
      i {
        font-size: 1.5em;
      }
      span {
        display: block;
        font-size: 0.65em;
      }
    }

    #btn-stop-recording {
      padding: 0;
      color: #d20000;
      border: 0;
      outline: 0;
      &:hover {
        color: #fff;
        transition: all 0.3s ease-in;
      }
      i {
        font-size: 1.5em;
        display: block;
      }
      span {
        font-size: 0.65em;
        vertical-align: text-top;
      }
    }


    .video {
        #btn-start-recording {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        }
        #btn-stop-recording {
            position: absolute;
            bottom: 2vh;
            left: 2vw;

            i {
                font-size: 1.5em;
                display: inline-block;
            }
        }
    }

</style>
