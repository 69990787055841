//Copyright 2019-2021 Michigan Aerospace Corporation
//All Rights Reserved
//Author: Chad Lewis, David Vasseliou, Leslie Borst
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import "./assets/styles/index.css";
import { APIPREFIX } from "@/api.js";
import VueTailwindModal from "vue-tailwind-modal";
import VueCircleSlider from "vue-circle-slider";
import vSelect from "vue-select";
import VueSocialSharing from 'vue-social-sharing';
import VTooltip from 'v-tooltip';
import VueObserveVisibility from 'vue-observe-visibility';
//import companion from '@uppy/companion';
import "./assets/styles/scss/default.scss";
import '@fortawesome/fontawesome-free/css/all.css';
import { Auth0Plugin } from "./auth";
import auth0Config from "../auth_config";

import { verifyAuthenticated,signOut,user } from "@/scripts/Authentication.js";

// USE ALL THE STUFF!
Vue.use(Auth0Plugin, {
  ...auth0Config,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});


Vue.use(VueCircleSlider);
Vue.component("VueTailwindModal", VueTailwindModal);
Vue.component("v-select", vSelect);
Vue.use(VTooltip);
Vue.use(VueSocialSharing);
Vue.use(VueObserveVisibility);
// Vue.use('/companion', companion.app(options))

window.$data = new Vue({ data: {user:null}});;

// INSTALL MARKDOWN/KATEX processor
var md = require("markdown-it")();
var mk = require("markdown-it-texmath");
md.use(mk);

window.$samsa = new Samsa(APIPREFIX + "/");
window.$router = Vue.$router;
Vue.prototype.$samsa = $samsa;

Vue.prototype.$markdown = md
Vue.config.productionTip = false;


verifyAuthenticated().then((res)=>{
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");

})
