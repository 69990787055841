<template>
  <transition name="fade">
    <div class="z-50" :class="`rounded-md bg-${color}-50 p-4 w-1/2 left-1/4 absolute top-1/4`" id = "message-modal">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg
            v-if="status.error"
            class="h-5 w-5 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414
              1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293
              1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0
              00-1.414-1.414L10 8.586 8.707 7.293z"
              clip-rule="evenodd"
            />
          </svg>

          <svg
            v-else-if="status.success"
             :class="`h-5 w-5 text-${color}-400`"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <svg
            v-else
            :class="`h-5 w-5 text-${color}-400`"
            xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

        </div>
        <div class="ml-3">
          <h3 :class="`text-sm leading-5 font-medium text-${color}-800`">
            {{title}}
          </h3>
          <div :class="`mt-2 text-sm leading-5 text-${color}-700`">
            <ul class="list-disc pl-5">
              <li>
                {{ messageData.message }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import Component from "../component_location"

export default {
  components: {},

  props: ['messageData'],

  data() {
    return {};
  },

  watch: {},

  methods: {},

  computed: {
    status() {
      return  {
        success: this.messageData.type === 'success',
        warning: this.messageData.type === 'warning',
        error: this.messageData.type === 'error'
      }
    },
    color() {
      return {
        success: 'green',
        warning: 'orange',
        error: 'red'
      }[this.messageData.type || 'success']
    },
    title() {
      return {
        success: 'Success',
        warning: 'Warning',
        error: 'Something went wrong'
      }[this.messageData.type || 'success']
    }
  },

  mounted() {},
};
</script>

<style>
.fade-enter,
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#message-modal {
  z-index:3000;
}
</style>
