<template>
  <div class="ml-4 inline-block w-2/3 flex flex-wrap">
    <div
      v-if="dataset.isConnected"
      class="bg-gray-100 mt-6 p-4 rounded-md ml-4 inline-block w-2/3 flex flex-wrap"
    >
      <div class="h-40 w-40 m-4 ml-5" v-for="(aud, idx) in data">
        <video
          :src="aud.url"
          class="rounded-md object-cover h-40 w-full"
          controls
        />
      </div>
    </div>
    <h1 v-else class="text-blue-500 mt-8 leading-4 tracking-wider text-lg ">
      No audios have been uploaded to this dataset.
    </h1>
  </div>
</template>

<script>
// import Component from "../component_location"
import { getQuery } from "@/views/Datasets/api.js";
import { reportError } from "@/utils.js";

export default {
  components: {},

  props: ["dataset"],

  data() {
    return {};
  },

  watch: {},

  dataset() {
    this.loadData();
  },

  methods: {},

  computed: {
    data() {
      return this.$store.state.datasets.data;
    },
  },

  mounted() {
    if (this.dataset.isConnected) {
      let details = { dataset: this.dataset, page: 0, perPage: 100 };
      this.$store.dispatch("datasets/queryDataset", details);
    }
  },
};
</script>

<style>
.cover {
  object-fit: cover;
}
</style>
