<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="flex flex-col earth-background our-story" id = "blurry">

    <div class="content about main company">
      <transition name="fade" appear>
        <div class="header-section">
          <h1>OUR STORY</h1>
        </div>
      </transition>
      <div class="iframe-wrapper">
        <iframe src="/brettnorthcutt/narratives/Our%20History/watch" width="100%" height="100%" allowfullscreen>
        </iframe>
      </div>


    </div>



    </div>
  </div>
</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";

export default {
  components: { Breadcrumbs, NavbarDash },

  props: [],

  data() {
    return {
      name:"about"
    };
  },

  watch: {},

  methods: {
  },

  computed: {},


  mounted() {},
};
</script>

<style scoped>

iframe
{
height:100vh;

}
</style>
