<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="flex flex-col earth-background" id = "blurry">

    <div class="content about main profile">
      <transition name="fade" appear>
        <div class="header-section" id="top-header">
          <h1>PROFILE</h1>
        </div>
      </transition>
      <div id="about-section" class="about people">
        <div class="casting left">

          <div class="profile-images" @click="upload">
            <div class="center">
              <img v-if="user.picture" :src="user.picture" />
              <div v-else>
              <span class="inline-block rounded-full overflow-hidden bg-gray-100" style="width:128px; height:128px">
                <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                 <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
               </svg>
             </span>
              </div>
              upload profile pic
            </div>

          </div>

          <div class="profile-copy">
            <span class="title">User Name:</span> {{user.username}}</span>
            <p>
            <span><span class="title">Email Address:</span> {{user.email}}</span>
            </p>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";
import { openFilePicker, EventBus } from "@/utils.js";

export default {
  components: { Breadcrumbs, NavbarDash },

  props: [],

  data() {
    return {

    };
  },

  watch: {},

  methods: {
    upload() {
      const client = openFilePicker();
      var acceptable;
      acceptable = ["image/*"];
      var options = {
        accept: acceptable,
        onUploadDone: this.filesUploaded,
        onCancel: (e) => {
          this.uploading = false;
        },
        maxFiles: 1,
        transformations: { crop: false, circle: false, rotate: false },
      };
      client.picker(options).open();
    },
    filesUploaded(fileInfo) {
      var self = this;
      var filesUploaded = fileInfo.filesUploaded;
      if(filesUploaded.length==1){
        console.log(self.user)
        self.user.picture = filesUploaded[0].url

        $samsa.setProfilePic.post(this.user.username, filesUploaded[0].url).then((res) => {})

      }
    }

  },

  computed: {
  user(){
  return this.$store.state.users.user;
  }

  },


  mounted() {


  },
};
</script>

<style scoped>
img{
max-height:128px;
}

.title{
font-weight:600;
}

.profile-images > div{
  text-align:center;
  color: #3db4e5;
  font-size:12px;
  cursor:pointer;
}


</style>
