<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="flex flex-col home new-home acada" id = "blurry">

    <div id="header-video">
      <div id="overlay"></div>
      <div class="inner-padding">
        <div class="header-content">
          <h3>Smart Cities are too complicated today</h3>
          <p>For small and medium sized municipalities, the ambiguous definition of a 'Smart City' combined with a hyper-fragmented provider network makes adoption too complicated, expensive, and impractical.</p>
          <p><i>ACADA solves these problems.</i></p>
          <a href="/portal" class="button acada_blue" name="acada-request-demo">REQUEST DEMO</a>
        </div>
        <!--<div class="ipad-link">
          <a href=""><img src="/images/ipad-app.png" /></a>
        </div>-->
      </div>
      <div class="header-scroll"><a href="#home-hero-new"><img src="/images/arrow.png" /></a></div>
    </div>

    <div id="home-hero-new" class="version two">
      <!--<h2>Smart Made Simple</h2>
      <p>ACADA is an integrated package of Devices, Network, and Application software focused on the Municipal IoT (Internet of Things) market. The platform was designed as a turn-key end-to-end system which simplifies and streamlines the diverse activities of more than 19,000 municipalities, 3000+county public works agencies, and 35,000 water utilities nationwide.</p>
      <a href="/portal" class="button acada_blue" name="acada-request-info">Request Information</a>-->
      <div class="left">
        <h2>Smart Made Simple</h2>
        <p>ACADA is an integrated package of Devices, Network, and Application software focused on the Municipal IoT (Internet of Things) market. The platform was designed as a turn-key end-to-end system which simplifies and streamlines the diverse activities of more than 19,000 municipalities, 3000+county public works agencies, and 35,000 water utilities nationwide.</p>
        <a href="/portal" class="button acada_blue" name="acada-request-info">Request Information</a>
      </div>
      <div class="right">
        <img src="/images/acada/devices2.png" />
      </div>
    </div>

    <div id="split-content-right">
      <div class="left desktop">
        <img src="/images/acada/energy-worker2.jpg" />
      </div>
      <div class="right">
        <h3>What’s going on with assets and services in my city?</h3>
        <p>ACADA was designed to improve the workflow of everyday public works activities, and was built on a few key differentiating features:</p>
        <ul>
          <li>Devices which cities use everyday</li>
          <li>Intuitive software designed to work like the users think</li>
          <li>Goes Beyond "Alerts" With a "Service Request" Function to Take Action</li>
          <li>Easy tailoring of KPIs, thresholds, and alerts to make ACADA work the way you work</li>
        </ul>
      </div>
      <div class="left mobile">
        <img src="/images/acada/energy-worker2.jpg" />
      </div>
    </div>

    <div id="split-content-left">
      <div class="left">
        <h3>End-to-end Smart City Solution from one Vendor</h3>
        <p>ACADA eliminates the need for multiple device vendors, software Systems, Training and Support. In addition, ACADA can:</p>
        <ul>
          <li>Save Labor, Time and Money</li>
          <li>Improve Staff and Public Safety</li>
          <li>Reduce a Carbon Footprint</li>
          <li>Reduce Operational and Capital Expenditures</li>
          <li>Aid in Emergency Planning and Disaster Recovery</li>
        </ul>
      </div>
      <div class="right">
        <img src="/images/acada/city4.jpg" />
      </div>
    </div>


    <div id="features" class="newer">
      <div class="inner-features">
        <h2>Practical, Everyday Workflow Devices</h2>
        <div class="left-features">
          <div class="feature-section">
            <img class="m_feature" src="/images/acada/trash.jpg" />
            <div class="top-title">
              <h4>Smart Trash Bins &amp; Dumpsters</h4>
            </div>
            <img src="/images/acada/trash.jpg" />
          </div>
          <div class="feature-section">
            <img class="m_feature" src="/images/acada/lights.jpg" />
            <div class="top-title">
              <h4>Connected Street Lights</h4>
            </div>
            <img src="/images/acada/lights.jpg" />
          </div>
          <div class="feature-section">
            <img class="m_feature" src="/images/acada/parking.jpg" />
            <div class="top-title">
              <h4>Smart Parking &amp; EV Station Availability</h4>
            </div>
            <img src="/images/acada/parking.jpg" />
          </div>
          <div class="feature-section">
            <img class="m_feature" src="/images/acada/leak.jpg" />
            <div class="top-title">
              <h4>Acoustic Water Line Leak Detection</h4>
            </div>
            <img src="/images/acada/leak.jpg" />
          </div>
          <div class="feature-section">
            <img class="m_feature" src="/images/acada/flood.jpg" />
            <div class="top-title">
              <h4>Flood Monitoring</h4>
            </div>
            <img src="/images/acada/flood.jpg" />
          </div>
          <div class="feature-section">
            <img class="m_feature" src="/images/acada/meter.jpg" />
            <div class="top-title">
              <h4>Water Meters</h4>
            </div>
            <img src="/images/acada/meter.jpg" />
          </div>
        </div>
        <img class="phone-image" src="images/acada/devices-mobile.png" />
        <div class="right-features">
          <div class="feature-section">
            <img src="/images/acada/smoke.jpg" />
            <div class="top-title">
              <h4> Smart Smoke Detectors / Air Quality</h4>
            </div>
          </div>
          <div class="feature-section">
            <img src="/images/acada/security.jpg" />
            <div class="top-title">
              <h4>Door &amp; Window Security Sensors</h4>
            </div>
          </div>
          <div class="feature-section">
            <img src="/images/acada/reading.jpg" />
            <div class="top-title">
              <h4>LoRA Gateway</h4>
            </div>
          </div>
          <div class="feature-section">
            <img src="/images/acada/shut-off.jpg" />
            <div class="top-title">
              <h4>Remote Meter Shut-off Valve</h4>
            </div>
          </div>
          <div class="feature-section">
            <img src="/images/acada/restroom.jpg" />
            <div class="top-title">
              <h4>Restroom Product Monitoring</h4>
            </div>
          </div>
          <div class="feature-section">
            <img src="/images/acada/rodent.jpg" />
            <div class="top-title">
              <h4>Smart Rodent Control</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";
import Carousel from "@/components/carousel.vue";
import Vue from 'vue';


export default {
  components: { Breadcrumbs, NavbarDash, Carousel },

  props: ["username"],




};
</script>

<style scoped >
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.flexbox {
  display: -webkit-flex;
  display: none;
  margin-bottom: 8vh;
}
.active, .dot:hover {
  background-color: green;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  animation-name: fade;
  animation-duration: 2s;
}

@-webkit-keyframes fade {
  from {opacity: .1}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .1}
  to {opacity: 1}
}

.flexbox .col {
  flex: 1;
}
/*.earth-background {
  background: repeating-linear-gradient(#2c3e50, rgba(0, 0, 0, 0.75) 70%, #2c3e50, rgba(0, 0, 0, 0.75) 60%) , url("/assets/images/young-diverse.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}*/

[class^="icon"] {
  width: 5vh;
  height: 5vh;
  position: relative;
  top: 1.5vh;
  margin-right: 1vh;
  fill: #fff;
}

nav {
  float: left;
  width: 100%;
  text-align: center;
  height: 10vh;
  padding: 1vh;
}
.box {
   display: flex;
   align-items:center;
   max-width: 45%;
   padding: 10%;

}
.footer-box {
  display: flex;
  align-items:center;


}
nav a{
    color: #282828;
    font-weight: bold;
    padding: 30px;
    text-align: center;

}
.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 4vh;

}
.third-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: white;
  padding: 0vh  0vh 14vh 0vh ;

}
.second-row {
  padding: 10vh;
  background-color: white;
  text-align: center;
}

.column {
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.column-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex:1;
}
.hero {
  margin-top: 20vh;
  max-width: 90%;
  min-width: 70%;
}

.logow {
  display: block;
  margin: auto;
  max-width: 50%;

}
.monitor {
  display: block;
  max-width: 90%;
}
.footer-icon {
  max-width: 10%
}
a:active {
  color: red;
  background-color: transparent;
}
a:hover {
  color: green;
  background-color: transparent;
}
.large {
  font-size:97px;
  font-family: "Poppins";
  text-align: left;
  color: white;
  line-height: 102px;
  font-weight: 600;

}
.medium {
  font-size:4vh;
  font-family: "Poppins";
  text-align: left;
  line-height: 4vh;
  font-weight: bold;


}
.small {
  font-size:2.1vh;
  font-family: "Poppins";
  text-align: left;
  line-height: 2.5vh;
  font-weight: 500;

}

.paragraph {
  font-size:24px;
  color: white;
  font-family: "Poppins";
  font-weight: 300;
  text-align: left;
  line-height: 33px;
  margin-top: 3vh;
}

.text-column {
   margin-left: 20%;
   margin-top: 11vh;
   word-break:break-all;

}


.title {
  font-family: "Poppins";
  color: black;
  font-size:5vh;
  z-index : 0;
}
.main-title {
  font-size:8vh;
  font-weight: bold;
  font-family: "Poppins";
  color: black;
}
.text
{
  display: inline-block;
}
 .button {
  background-color: #47aa42;
  border: none;
  color: white;
  padding: 2vh 3vh 2vh 3vh;
  text-decoration: none;
  margin: 2.5vh 0.2vh;
  border-radius: 3vh;
  outline: none;
  font-size: 1.5vh;
  display:flex;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
  }
.button:hover {
  color: black;
}
#textButton {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
    outline: none;
    font-weight: 600;
}
#textButton:hover {
  color: green;
}
.active {
  color: green;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 6px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.active, .dot:hover {
  background-color:  #47aa42;
}
@media (max-width: 1900px) {
	.large {
		font-size: 75px;
    line-height: 80px;
	}
  .paragraph {
    font-size: 22px;
    line-height: 30px;
  }
}
@media (max-width: 1500px) {
	.large {
		font-size: 53px;
    line-height: 60px;
  }
  .paragraph {
    font-size: 18px;
    line-height: 25px;
  }
}
@media (max-width: 1200px) {
	.large {
		font-size: 45px;
    line-height: 50px;
	}
  .paragraph {
    font-size: 13px;
    line-height: 20px;
  }
}
@media (max-width: 900px) {
	.large {
		font-size: 30px;
    line-height: 34px;
	}
  .paragraph {
    font-size: 9px;
    line-height: 15px;
  }
}
@media (max-width: 600px) {
	.large {
		font-size: 19px;
    line-height: 25px;
	}
  .paragraph {
    font-size: 7px;
    line-height: 10px;
  }
}

</style>
