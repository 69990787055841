// Copyright 2019-2021 Michigan Aerospace Corporation
// All Rights Reserved
// Author: Chad Lewis, David Vasseliou, Leslie Borst

import Vue from "vue";
import Vuex from "vuex";
import users from "./modules/users.js";
import narratives from "./modules/narratives.js";
import repos from "./modules/repos.js";
import datasets from "./modules/datasets.js";
import modals from './modules/modals'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: { users, narratives, repos, datasets, modals },
});

window.$store = store;
export default store;
