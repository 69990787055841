<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
    <section class="new-org">
        <h1>Create an Organization</h1>
        <div>
            <label>Organization Name</label>
            <input type="text" placeholder="enter a name" v-model="orgName"/>
        </div>
        <div>
            <label>Add Members</label>
        </div>
        <button @click="create_org()">Create</button>
    </section>
</template>

<script>
export default {

  props: [],

  mounted(){

  },
  data() {
    return {
        orgName: "",
        desc: "",


    };
  },

  methods: {
    create_org: async function() {
        let owner_id = this.$store.state.users.user.username;
        let ret = await $samsa.createOrganization.post(owner_id, this.orgName);
        console.log(this.$store.state.users);
    }



  },
  computed: {


  },

};
</script>

<style lang="scss" scoped>


</style>
