<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
	<div>
		<div class="flex flex-col earth-background" id="">
			<div class="content about main dgreen">
				<transition name="fade" appear>
					<div class="header-section">
						<h1>Employement Application</h1>
						<h3>Enter your information below.</h3>
					</div>
				</transition>
				<div class="body-wrapper">
					<transition name="fade" appear mode="out-in">
						<div v-if="hideForm" name="personal-information-form">
							<ul>
								<label for="First Name">First Name: {{ first_name }} </label>
								<ul>
									<input size="25" v-model="first_name" />
								</ul>
							</ul>
							<ul>
								<label for="Last Name">Middle Name: {{ middle_name }}</label>
								<ul>
									<input size="25" v-model="middle_name" />
								</ul>
							</ul>
							<ul>
								<label for="Last Name">Last Name: {{ last_name }}</label>
								<ul>
									<input size="25" v-model="last_name" />
								</ul>
							</ul>
							<ul>
								<label for="Age">Age: {{ age }}</label>
								<ul>
									<input size="2" v-model="age" />
								</ul>
							</ul>
							<ul>
								<label for="City">City: {{ city }}</label>
								<ul>
									<input v-model="city" />
								</ul>
							</ul>
							<ul>
								<label for="State">State: {{ state }}</label>
								<ul>
									<input v-model="state" />
								</ul>
							</ul>
							<ul>
								<label for="Zip Code">Zip Code: {{ zip_code }}</label>
								<ul>
									<input size="10" v-model="zip_code" />
								</ul>
							</ul>

							<div class="buttons-wrapper">
								<button class="button" @click="createPerson">
									SUBMIT
								</button>
								<!--Need condition assigned to not show button if nothing has been submitted previously!-->
								<button class="button" id="getData" @click="toggleJobHistory">
									RESUME PREVIOUS APPLICATION
								</button>
							</div>
						</div>
					</transition>

					<transition-group name="fade" appear mode="out-in">
						<div v-for="item in people" :key="item.oid">
							<div class="buttons-wrapper">
								<div v-if="showJobHistory">
									<ul>
										First name:
										{{
											item.firstName
										}}
									</ul>
									<ul>
										Middle name:
										{{
											item.middleName
										}}
									</ul>
									<ul>
										Last name:
										{{
											item.lastName
										}}
									</ul>
									<ul>
										Age:
										{{
											item.age
										}}
									</ul>
									<ul>
										City:
										{{
											item.city
										}}
									</ul>
									<ul>
										State:
										{{
											item.state
										}}
									</ul>
									<ul>
										Zip Code:
										{{
											item.zipCode
										}}
									</ul>

									<label for="Employment History">Employment History</label>
									<job-history :jobs="item" @addJob="addJob"></job-history>
									<job-history
										:jobs="item"
										@deleteJob="deleteJob"
									></job-history>
									<div class="buttons-wrapper">
										<button class="button" @click="clear(item)">
											RESTART APPLICATION
										</button>
									</div>
								</div>
							</div>
						</div>
					</transition-group>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Breadcrumbs from "@/components/Breadcrumbs.vue";
	import NavbarDash from "@/components/NavbarDash.vue";
	import JobHistory from "@/components/JobHistory.vue";

	export default {
		components: { Breadcrumbs, NavbarDash, JobHistory },

		props: [],

		data() {
			return {
				people: [],
				work_history: [],
				first_name: "",
				last_name: "",
				middle_name: "",
				age: "",
				city: "",
				state: "",
				zip_code: "",
				job_name: "",
				job_desc: "",
				showJobHistory: false,
				hideForm: true,
			};
		},

		watch: {},

		methods: {
			test: async function() {
				var ret = await $samsa.getPerson("Darryl", "Green");
				this.people = ret.data;
				console.log(ret.data);
				//var myJSON = JSON.stringify(ret.data, ['firstName','lastName'], undefined, 4);
				//this.myArray = ret.data, ['firstname', 'lastname'];
				//document.getElementById("getData").innerHTML = myJSON;
				//console.log(ret.data);
			},
			create: async function() {
				var ret = await $samsa.createPerson.post("Darryl", "Green", "L");
				console.log(ret.data.createPerson);
			},
			createPerson: function() {
				var self = this;
				var ret = $samsa.createPerson
					.post(
						this.first_name,
						this.last_name,
						this.middle_name,
						this.age,
						this.city,
						this.state,
						this.zip_code
					)
					.then(function() {
						self.test();
					});

				this.hideForm = false;
				this.showJobHistory = true;
				//console.log(ret.data.createDgreen);
			},
			addJob: function(person, job_name, job_desc) {
				var job = { job_name: job_name, job_desc: job_desc };
				var self = this;
				console.log("Adding job");
				console.log(person);
				console.log(job);
				var ret = $samsa.addJob
					.post(person, job_name, job_desc)
					.then(function() {
						self.test();
					});
			},
			OLD_addJob: function(person) {},

			deleteJob: function(person, job_name, job_desc) {
				var job = { job_name: job_name, job_desc: job_desc };
				var self = this;
				console.log("Removing job");
				console.log(person);
				console.log(job);
				var ret = $samsa.deleteJob.post(job);
				this.test();
				console.log(ret.data);

				/*console.log("Removing job");
				console.log(job);
				var ret = $samsa.deleteJob.post(job);
				this.test();
				console.log(ret.data);*/
			},

			clear: function(item) {
				console.log(item);
				var ret = $samsa.deletePerson.post(item);
				this.test();
				console.log(ret.data);
				this.hideForm = true;
			},
			toggleJobHistory: function() {
				this.hideForm = false;
				this.showJobHistory = true;
			},
		},

		computed: {},

		mounted() {
			this.test();
		},
	};
</script>
<style scoped>

.button,
.button[data-v-22ba47ca] {
	font-weight: 400;
	background-color: #ffffff;
	border: 2px solid;
	color: #47aa42;
	padding: 2vh 3vh 2vh 3vh;
	text-decoration: none;
	margin: 2.5vh 0.2vh;
	border-radius: 3vh;
	outline: none;
	font-size: 1.5vh;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	transition: 0.35s ease-in;
}
.body-wrapper {
	margin: auto;
	width: 60%;
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 10px;
}
.buttons-alignment div:nth-child(3) {
	float: left;
	position: relative;
	margin-right: 0;
}

.buttons-wrapper {
	float: center;
	width: 300px;
	position: relative;
	line-height: 44px;
	margin: 15px 12.700534759358288770053475935829% 0 0;
}

</style>
