<template>
  <div
    ref="current-table"
    class="w-2/3 mt-6 tracking-wider leading-normal opacity-0"
  >
    <!--Container-->
    <div class="container px-2" v-if="tableReady">
      <!--Card-->

      <div
        id="recipients"
        class="w-full block p-8 mt-6 lg:mt-0 rounded shadow bg-white"
      >
        <table
          id="data-table"
          class="stripe hover"
          style="width:100%; padding-top: 1em;  padding-bottom: 1em;"
        >
          <thead>
            <tr>
              <th
                class="dt-left"
                priority="idx+1"
                v-for="desc in dataset.descriptors"
              >
                {{ desc.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(d, idx) in data">
              <td v-for="(desc, idx) in dataset.descriptors">
                {{ d[snakeToCamel(desc.name.toLowerCase())] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--/Card-->
    </div>
    <!--/container-->
  </div>
</template>

<script>
// import Component from "../component_location"
import { getQuery, getData } from "@/views/Datasets/api.js";
import { reportError, snakeToCamel, EventBus } from "@/utils.js";

export default {
  components: {},

  props: ["dataset"],

  data() {
    return {
      tableReady: false,
    };
  },

  watch: {
    dataset() {
      // this.loadData();
      let details = { dataset: this.dataset, page: 0, perPage: 100 };
      this.$store.dispatch("datasets/queryDataset", details);
    },
  },

  methods: {
    snakeToCamel(word) {
      return snakeToCamel(word);
    },

    initTable() {
      var table = $("#data-table")
        .DataTable({
          responsive: true,
          destroy: true,
        })
        .columns.adjust()
        .responsive.recalc();
      this.$refs["current-table"].style.opacity = 1;
    },

    // loadData() {
    //   if (!this.dataset.isConnected) {
    //     return;
    //   }
    //   getData(this.dataset, 0, 100)
    //     .then((res) => {
    //       this.$store.commit("setData", res.data.data.select);
    //       this.tableReady = true;
    //       setTimeout(this.initTable, 100);
    //     })
    //     .catch((err) => {
    //       reportError(err);
    //     });
    //   // var query = getQuery(this.dataset, 0, 100);
    //   // var data = { query: query };
    //   // var url = this.dataset.apiUrl;
    //   // var q = { url: url, method: "post", data: data };
    //   // LOAD selection of dataset data into memory!!
    //   // axios(q);
    // },

    dataReady() {
      this.tableReady = true;
      setTimeout(this.initTable, 100); // hacky!!
    },
  },

  computed: {
    data() {
      return this.$store.state.datasets.data;
    },
  },

  mounted() {
    // this.loadData();
    let details = { dataset: this.dataset, page: 0, perPage: 100 };
    this.$store.dispatch("datasets/queryDataset", details);
    EventBus.$on("data-available", this.dataReady);
  },
};
</script>

<style>
.dataTables_wrapper select,
.dataTables_wrapper .dataTables_filter input {
  color: #4a5568; /*text-gray-700*/
  padding-left: 1rem; /*pl-4*/
  padding-right: 1rem; /*pl-4*/
  padding-top: 0.5rem; /*pl-2*/
  padding-bottom: 0.5rem; /*pl-2*/
  line-height: 1.25; /*leading-tight*/
  border-width: 2px; /*border-2*/
  border-radius: 0.25rem;
  border-color: #edf2f7; /*border-gray-200*/
  background-color: #edf2f7; /*bg-gray-200*/
}

/*Row Hover*/
table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: #dbeafe; /*bg-indigo-100*/
}

/*Pagination Buttons*/
.dataTables_wrapper .dataTables_paginate .paginate_button {
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Pagination Buttons - Current selected */
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #fff !important; /*text-white*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /*shadow*/
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  background: #3b82f6 !important; /*bg-indigo-500*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Pagination Buttons - Hover */
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #fff !important; /*text-white*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /*shadow*/
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  background: #2563eb !important; /*bg-indigo-500*/
  color: white !important;
  border: 1px solid transparent; /*border border-transparent*/
}

/*Add padding to bottom border */
table.dataTable.no-footer {
  border-bottom: 1px solid #e2e8f0; /*border-b-1 border-gray-300*/
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

/*Change colour of responsive icon*/
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  background-color: #667eea !important; /*bg-indigo-500*/
}
a.paginate_button:hover {
  color: white !important;
  background-color: red !important;
}
</style>
