<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
<div>
  <div class="top">
    <div class="head">
      <div class="dragbar">
        <div @click="back" class="back">
          <span>&larr;</span>
        </div>
        <div class="survey-links">
          <router-link to="../" name="terms-privacy">Link 1</router-link>
          <router-link to="../" name="terms-privacy">Link 2</router-link>
          <router-link to="../" name="terms-privacy">Link 3</router-link>
        </div>
    </div>
  </div>

</div>
  <div class="header-hero">
    <h2>Survey Questionaire</h2>
  </div>
	<div class="conversation-pit survey">
    <wizard-bubble 
      v-for="(question, index) in survey" 
      :key="index" 
      :index="index" 
      :idx="idx" 
      :question="question"
      @showNextQuestion="showNextQuestion">
    </wizard-bubble>

    <div class="actions">
     
      <button class="actions__button" type="VIDEO" id="icon_VIDEO" @click="checkType('VIDEO')">
        <img src="/images/button-video5.png"/>
      </button>
      <button class="actions__button" type="AUDIO" id="icon_AUDIO" @click="checkType('AUDIO')">
        <img src="/images/button-audio5.png"/>
      </button>
      <button class="actions__button" type="TEXT" id="icon_TEXT" @click="checkType('TEXT')">
        <img src="/images/button-text5.png"/>
      </button>
    </div>
  </div>
</div>
</template>

<script>
  import WizardBubble from '@/components/narrative-wizard/wizardBubble.vue';

	export default {
		components: {WizardBubble},

		props: {},

		data() {
			return {
        idx: 0,
        survey: [
          {
            question: "What is your greatest achievement?",
            response: null,
            answerType: "",
            content: {type:null, url:null},
          },
          {
            question: "What are you most proud of?",
            response: null,
            answerType: "",
            content: {type:null, url:null},
          },
          {
            question: "What are some things you wanted to do that you haven't?",
            response: null,
            answerType: "",
            content: {type:null, url:null},
          },
          {
            question: "What did you want to be when you grew up?",
            response: null,
            answerType: "",
            content: {type:null, url:null},
            
          },
          {
            question: "Where did you grow up?",
            response: null,
            answerType: "",
            content: {type:null, url:null},
            
          },
          {
            question: "Who has been the most influential person in your life?",
            response: null,
            answerType: "",
            content: {type:null, url:null},
            
          },
          {
            question: "20 things you want to do before you die?",
            response: null,
            answerType: "",
            content: {type:null, url:null},
            
          },
          {
            question: "10 things you want to do before you're 50?",
            response: null,
            answerType: "",
            content: {type:null, url:null},
            
          },
          {
            question: "What was the best job you've had?",
            response: null,
            answerType: "",
            content: {type:null, url:null},
            
          }
        ]
			};
		},

		updated: function() {},
		watch: {

    },
		methods: {
      back(){
      this.$router.push({name:"Portal"})
      },
      showNextQuestion: function() {
        if((this.idx) <= this.survey.length) {
          this.idx += 1
        }
      },
      checkType: function(type) {
        this.survey[this.idx].answerType = type;
        
        console.log("CLICKED", type)
       
      }
    },

		computed: {
      
    },

		mounted() {},
	};
</script>

<style scoped lang="scss">

.top{
  display:flex;
  flex-direction: column;
  height:auto;
  z-index:0;
}
.dragbar {
    display: flex;
    flex-direction: row;
}

.head{
  background-color:white;
 -webkit-box-shadow: 0px 0px 11px rgba(0, 0, 0, .15);
  box-shadow: 0px 0px 11px rgba(0, 0, 0, .15);
  height:72px;
  width:100%;
  .back {
    width:64px;
    height:64px;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    padding:4px 0px 0 4px;
    margin:4px;
    span {
      font-size: 2.25em;
      cursor: pointer;
    }
  }
  .survey-links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 4px 0px 0px 4px;
    margin: 4px;
    a {
      margin: 0 1em;
      font-weight: 600;
      position: relative;
      transform-origin: right bottom;
      transition: transform 0.25s ease-out 0s;
    }
    a:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0px;
      left: 0px;
      background-color: rgb(0, 0, 0);
      transform-origin: right bottom;
      transition: transform 0.25s ease-out 0s;
    }
    a:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}
.header-hero {
    background: url(/images/header2.jpg) top center no-repeat;
    background-size: cover;
    height: auto;
    padding-bottom: 8.7%;
  h2 {
    font-size: 60px;
    text-transform: uppercase;
    text-align: center;
    padding-top: 8.7%;
  }
}
.conversation-pit {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  
  .actions {
    display: flex;
    float: none;
    &__button {
      width: 50px;
      margin: 5px;
    }
  }
}



</style>
