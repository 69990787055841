<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>


  <div class="parent" :style="{top:options.y+'px', zIndex:options.z}" >
    <div v-if="options.mode=='SOLID'">
      <div class="background" :style="{backgroundColor:options.color1}"></div>
    </div>
    <div v-else-if="options.mode=='ANGLE'">
        <svg  width="100%" height="50" viewBox="0 0 100 50" preserveAspectRatio="none" shape-rendering="geometricPrecision">
         <path d="M 0 50 L 100 0 L 100 50 L 0 50" :fill="options.color1"/>
           <path d="M 0 50 L 100 25 L 100 50 L 0 50" :fill="options.color2"/>
         <!--<path d="M 0 0 C 35 35, 65 35, 100 0" stroke="black" fill="black"/>-->
          <!--<rect width=100 height=100 style="fill:rgb(0,0,255)" />-->
       </svg>

       <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" >
         <path d="M 0 0 L 100 0 L 100 100 L 0 100 " :stroke="options.color2" :fill="options.color2"/>
         <!--<path d="M 0 0 C 35 35, 65 35, 100 0" stroke="black" fill="black"/>-->
          <!--<rect width=100 height=100 style="fill:rgb(0,0,255)" />-->
       </svg>

    </div>
    <div v-else-if="options.mode=='WAVE'">
      <svg width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none" shape-rendering="geometricPrecision" >
         <path d="M 0 20 C 25 0, 25 0, 50 20 C 75 50, 75 50, 100 20 L 100 100 L 0 100" :fill="options.color1"/>
         <!--<path d="M 0 0 C 35 35, 65 35, 100 0" stroke="black" fill="black"/>-->
          <!--<rect width=100 height=100 style="fill:rgb(0,0,255)" />-->
       </svg>

       <svg style="margin-top:-1" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" >
         <path d="M 0 0 L 100 0 L 100 100 L 0 100 " :fill="options.color1"/>
         <!--<path d="M 0 0 C 35 35, 65 35, 100 0" stroke="black" fill="black"/>-->
          <!--<rect width=100 height=100 style="fill:rgb(0,0,255)" />-->
       </svg>

    </div>


  </div>

</template>

<script>


import SettingsIcon from "@/views/Narrator/SettingsIcon.vue"
export default {
  components: {SettingsIcon},

  props: ["options"],

  data() {
    return {

    };
  },

  watch: {

  },

  methods: {
    edit(){
      window.$events.$emit("edit",this.content)
    },
  },

  computed: {


  },

  mounted() {


  },
};
</script>

<style scoped>

.background
{
  position:absolute;
  width:100%;
  height:100%;
}

.parent{
  position:absolute;
  width:100%;
  height:100%;
}

</style>
