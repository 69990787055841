// Copyright 2019-2021 Michigan Aerospace Corporation
// All Rights Reserved
// Author: Chad Lewis, David Vasseliou, Leslie Borst

import { reportError, EventBus } from "@/utils.js";
import { getData } from "@/views/Datasets/api.js";

const state = {
  current: { name: "", mimetype: "", description: "", datasetType: "FILE" },
  list: [],
  validName: true,
  data: [],
};

const getters = {};

const mutations = {
  setNameValidity(state, validity) {
    state.validName = validity;
  },

  setDataset(state, dataset) {
    state.current = dataset;
  },

  setData(state, data) {
    state.data = data;
  },
};

const actions = {
  checkName(context, details) {
    $samsa
      .isValidDatasetName(details.repoId, details.datasetName)
      .then((res) => {
        let validity = res.data;
        context.commit("setNameValidity", validity);
      });
  },

  create(context, details) {
    $samsa.createDataset
      .post(details.repoId, details.dataset)
      .then((res) => {
        let dataset = res.data.createDataset;
        context.commit("setDataset", dataset);
        EventBus.$emit("dataset-created", {});
      })
      .catch((err) => {
        reportError(err);
      });
  },

  getDataset(context, details) {
    $samsa
      .getDataset(details.username, details.repoName, details.datasetName)
      .then((res) => {
        context.commit("setDataset", res.data);
      })
      .catch((err) => {
        reportError(err);
      });
  },

  addInitialFile(context, details) {
    // Uploaded the initial file for data connection. Add details to the dataset.
    $samsa.addInitialFile
      .post(details.datasetId, details.file)
      .then((res) => {
        context.commit("setDataset", res.data.addInitialFile);
        EventBus.$emit("upload-complete", {});
      })
      .catch((err) => {
        reportError(err);
      });
  },

  completeAnnotations(context, dataset) {
    $samsa
      .completeAnnotations(dataset)
      .then((res) => {
        context.commit("setDataset", res.data.completeAnnotations);
        EventBus.$emit("annotations-complete", {});
      })
      .catch((err) => {
        reportError(err);
      });
  },

  queryDataset(context, { dataset, page, perPage }) {
    // Grab raw data via a GreaphQL query.
    getData(dataset, page, perPage)
      .then((res) => {
        context.commit("setData", res.data.data.select);
        EventBus.$emit("data-available", {});
      })
      .catch((err) => {
        reportError(err);
      });
  },

  addFiles(context, { datasetId, files }) {
    $samsa.addFiles
      .post(datasetId, files)
      .then((res) => {
        var dataset = res.data.addFiles;
        context.commit("setDataset", dataset);
        context.dispatch("queryDataset", {
          dataset: dataset,
          page: 0,
          perPage: 100,
        });
        EventBus.$emit("files-uploaded", {});
      })
      .catch((err) => {
        reportError(err);
      });
  },
  uploadImages(context, { narrativeId, files }) {
    $samsa.uploadImages
      .post(narrativeId, files)
      .then((res) => {
        EventBus.$emit("files-uploaded", {});
      })
      .catch((err) => {
        reportError(err);
      });
  },
  uploadVideos(context, { narrativeId, files }) {
    $samsa.uploadVideos
      .post(narrativeId, files)
      .then((res) => {
        EventBus.$emit("files-uploaded", {});
      })
      .catch((err) => {
        reportError(err);
      });
  },
  uploadAudio(context, { narrativeId, files }) {
    $samsa.uploadAudio
      .post(narrativeId, files)
      .then((res) => {
        EventBus.$emit("files-uploaded", {});
      })
      .catch((err) => {
        reportError(err);
      });
  },


};




export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
