<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
	<div class="conversation-pit">
    <wizard-bubble
      v-for="(question, index) in survey"
      :key="index"
      :index="index"
      :idx="idx"
      :question="question"
      @showNextQuestion="showNextQuestion">
    </wizard-bubble>

    <div class="actions">

      <button class="actions__button" type="VIDEO" id="icon_VIDEO" @click="checkType('VIDEO')">
        <img src="/images/button-video5.png"/>
      </button>
      <button class="actions__button" type="AUDIO" id="icon_AUDIO" @click="checkType('AUDIO')">
        <img src="/images/button-audio5.png"/>
      </button>
      <button class="actions__button" type="TEXT" id="icon_TEXT" @click="checkType('TEXT')">
        <img src="/images/button-text5.png"/>
      </button>
    </div>
  </div>
</template>

<script>
  import WizardBubble from '@/components/narrative-wizard/wizardBubble.vue';

	export default {
		components: {WizardBubble},

		props: {},

		data() {
			return {
        idx: 0,
        survey: [
          {
            question: "What made you join the company?",
            response: null,
            answerType: "",
            content: {type:null, url:null},
          },
          {
            question: "What does your ideal day at work look like?",
            response: null,
            answerType: "",
            content: {type:null, url:null},
          },
          {
            question: "Who is your favorite employee and why is it Carrie?",
            response: null,
            answerType: "",
            content: {type:null, url:null},
          },
          {
            question: "Tell us why you are so upset about the lack of a meat fridge in the office?",
            response: null,
            answerType: "",
            content: {type:null, url:null},

          }
        ]
			};
		},

		updated: function() {},
		watch: {

    },
		methods: {
      showNextQuestion: function() {
        if((this.idx) <= this.survey.length) {
          this.idx += 1
        }
      },
      checkType: function(type) {
        this.survey[this.idx].answerType = type;
        
        console.log("CLICKED", type)

      }
    },

		computed: {

    },

		mounted() {},
	};
</script>

<style scoped lang="scss">

.conversation-pit {
  border-left: 1px solid $darkgrey;
  border-right: 1px solid $darkgrey;
  height: 100vh;
  width: 60%;
  margin: 0 auto;

  .actions {
    display: flex;
    padding-top: 100px;
    float: right;
    &__button {
      width: 50px;
      margin: 5px;
    }
  }
}

</style>
