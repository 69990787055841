//Copyright 2019-2021 Michigan Aerospace Corporation
//All Rights Reserved
//Author: Chad Lewis, David Vasseliou, Leslie Borst

import Vue from "vue";
const EventBus = new Vue();

const FILESTACK_API_KEY = "AXBTBOr7EThGlfby8YdaLz";

function openFilePicker() {
  const client = filestack.init(FILESTACK_API_KEY);
  return client;
}

function cleanNarrative(narrative) {
  // Clean the narrative — make sure colors don't crash backend.
  narrative.elements.forEach((e) => {
    stripHash(e);
  });
  return narrative;
}

function humanize(num) {
  var ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  var tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  var numString = num.toString();

  if (num < 0) throw new Error("Negative numbers are not supported.");

  if (num === 0) return "zero";

  //the case of 1 - 20
  if (num < 20) {
    return ones[num];
  }

  if (numString.length === 2) {
    return tens[numString[0]] + " " + ones[numString[1]];
  }

  //100 and more
  if (numString.length == 3) {
    if (numString[1] === "0" && numString[2] === "0")
      return ones[numString[0]] + " hundred";
    else
      return (
        ones[numString[0]] +
        " hundred and " +
        humanize(+(numString[1] + numString[2]))
      );
  }

  if (numString.length === 4) {
    var end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return ones[numString[0]] + " thousand";
    if (end < 100) return ones[numString[0]] + " thousand and " + convert(end);
    return ones[numString[0]] + " thousand " + convert(end);
  }
}

function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

const snakeToCamel = (str) =>
  str.replace(/([-_][a-z])/g, (group) =>
    group
      .toUpperCase()
      .replace("-", "")
      .replace("_", "")
  );

function hyperStringify(obj_or_array) {
  // Convert object or array into a GraphQL-compatible string.
  if (Array.isArray(obj_or_array)) {
    var newArray = "[";
    obj_or_array.forEach((element) => {
      newArray = newArray + hyperStringify(element) + ", ";
    });
    newArray = newArray.slice(0, -2) + "]";
    return newArray;
  } else {
    return stringify(obj_or_array);
  }
}

function hexToRgb(hex) {
  // Convert hex-valued color string to RGB components
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function stringify(obj_from_json) {
  if (typeof obj_from_json !== "object" || Array.isArray(obj_from_json)) {
    // not an object, stringify using native function
    return JSON.stringify(obj_from_json);
  }
  // Implements recursive object serialization according to JSON spec
  // but without quotes around the keys.
  let props = Object.keys(obj_from_json)
    .map((key) => `${key}:${stringify(obj_from_json[key])}`)
    .join(",");
  return `{${props}}`;
}

function debounce(func, wait, immediate) {
  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function cleanName(oldName) {
  oldName = oldName.toLowerCase();
  var newName = oldName.replaceAll(/[^a-z_\d]/g, "-");
  if (newName.slice(-1) == "-") {
    newName = newName.slice(0, -1);
  }
  return newName;
}

function getToken() {
  var token = localStorage.getItem("plait-token");
  return token;
}

function stripHash(element) {
  if (element.textColor.length > 0) {
    element.textColor = element.textColor.slice(1);
  }
  if (element.titleColor.length > 0) {
    element.titleColor = element.titleColor.slice(1);
  }
  if (element.backgroundColor.length > 0) {
    element.backgroundColor = element.backgroundColor.slice(1);
  }
  element.text = element.text.replace("#", "@@@");
}

function restoreHash(element) {
  if (element.textColor.length > 0) {
    element.textColor = "#" + element.textColor;
  }
  if (element.titleColor.length > 0) {
    element.titleColor = "#" + element.titleColor;
  }
  if (element.backgroundColor.length > 0) {
    element.backgroundColor = "#" + element.backgroundColor;
  }
  element.text = element.text.replace("@@@", "#");
}

function reportError(err) {
  // Broadcast an error on the global event bus.
  try {
    EventBus.$emit("error", err.request.response);
  } catch {
    EventBus.$emit("error", err);
  }
}

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?') // query string

  return !!pattern.test(str);
}

function makeValidHref(elementText) {
  var resultText = "";
  if (elementText.includes('<a href="') && !elementText.includes('href="//') && !elementText.includes('http')) {
    resultText = elementText.split('href="')[0].trim() + " href=\"//" +  elementText.split('href="')[1].trim();
  }
  else {
    resultText = elementText;
  }
  return resultText;
}

export {
  validURL,
  cleanName,
  openFilePicker,
  debounce,
  hyperStringify,
  getToken,
  EventBus,
  reportError,
  snakeToCamel,
  titleCase,
  cleanNarrative,
  humanize,
  stripHash,
  restoreHash,
  hexToRgb,
  uuidv4,
  makeValidHref
};
