<template>
 <nav class="flex flex-wrap items-center justify-between p-5 bg-white" :class="$route.meta.header_class" id="dashboard-navbar" v-if="$route.meta.show_header">
  <router-link to="/Home" name="navigation-home">
   <img
      class="h-16 w-auto main-logo"
      src="/images/Springmatter-Icon2.png"
      alt="Springmatter"
    />
    <img
       class="h-16 w-auto acada-logo"
       src="/images/acada/image_w300.png"
       alt="Springmatter"
     />
  </router-link>
   <div class="-ml-2 mr-2 flex items-center lg:hidden">
            <!-- Mobile menu button -->
            <button
              class="inline-flex items-center justify-center p-2 rounded-md
              text-gray-400 hover:text-white hover:bg-gray-700
              focus:outline-none focus:bg-gray-700 focus:text-white transition
              duration-150 ease-in-out"
              aria-label="Main menu"
              aria-expanded="false"
              v-on:click="toggle"
            >
              <!-- Icon when menu is closed. -->
              <!--
              Heroicon name: menu
              Menu open: "hidden", Menu closed: "block"
            -->
              <svg
                class="toggle block h-6 w-6"
                :class="{'hidden': toggled}"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <!-- Icon when menu is open. -->
              <!--
              Heroicon name: x

              Menu open: "block", Menu closed: "hidden"
            -->
              <svg
                class="toggle h-6 w-6"
                :class="{'hidden': !toggled}"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
        </div>
  <div class="toggle lg:flex w-full lg:w-auto text-center text-bold mt-5 lg:mt-0 border-t-2 border-gray-500 lg:bg-none bg-white lg:border-none"
      :class="{'hidden': !toggled}"
      id = "links">
     <router-link
                 <!--<drop-down-menu title="ABOUT" :links="about_menu">
                 </drop-down-menu>-->

                 <!--<drop-down-menu title="CREATE" :links="create_menu">
                 </drop-down-menu>-->

                 <!--<drop-down-menu title="RESOURCES" :links="resources_menu">
                 </drop-down-menu>-->

                 <!--<drop-down-menu title="PRICING" :links="pricing_menu">
                 </drop-down-menu>-->

                 <!--<drop-down-menu title="VIEW DEMO"  :links="demo_menu">
                 </drop-down-menu>-->

                <!--
                <a
                name="nav_about"
                href = "/company"
                class="px-3 py-2 text-sm font-medium leading-5
                text-white focus:outline-none
                transition duration-150 ease-in-out
                block lg:inline-block lg:border-none border-b-2 border-gray-500"
                style = "font-size: 18px"
                >OUR STORY</a>
              -->

                <router-link
                name="nav_teams"
                to = "/teams"
                class="px-3 py-2 text-sm font-medium leading-5
                text-white focus:outline-none
                transition duration-150 ease-in-out
                block lg:inline-block lg:border-none border-b-2 border-gray-500"
                style = "font-size: 18px"
                >OUR TEAM</router-link>

                <!-- <a
                href = "/teams_acada"
                class="px-3 py-2 text-sm font-medium leading-5
                text-white focus:outline-none
                transition duration-150 ease-in-out
                block lg:inline-block lg:border-none border-b-2 border-gray-500 acada-teams"
                style = "font-size: 18px"
                >OUR TEAM</a> -->

                <router-link
                name="nav_careers"
                to = "/careers"
                class="px-3 py-2 text-sm font-medium leading-5
                text-white focus:outline-none
                transition duration-150 ease-in-out
                block lg:inline-block lg:border-none border-b-2 border-gray-500"
                style = "font-size: 18px"
                >CAREERS</router-link>

              <router-link
              v-if = "isSignedIn"
              :to="{ name: 'Portal', params: { username: username } }"
              class="lg:hidden px-3 py-2 text-sm font-medium leading-5
              text-white focus:outline-none
              transition duration-150 ease-in-out
              block lg:inline-block lg:border-none border-b-2 border-gray-500 bottom-links"
              >YOUR PROFILE</router-link>

              <router-link
              v-if = "isSignedIn"
              :to="{ name: 'Portal', params: { username: username } }"
              class="lg:hidden px-3 py-2 text-sm font-medium leading-5
              text-white focus:outline-none
              transition duration-150 ease-in-out
              block lg:inline-block lg:border-none border-b-2 border-gray-500 bottom-links"
              >SETTINGS</router-link>

              <a
              v-if = "isSignedIn"
              @click = "signOut"
              class="lg:hidden px-3 py-2 text-sm font-medium leading-5
              text-white focus:outline-none
              transition duration-150 ease-in-out
              block lg:inline-block lg:border-none border-b-2 border-gray-500 bottom-links"
              >SIGN OUT</a>

            <a
              v-if = "!isSignedIn"
              @click="login('login')"
              class="lg:hidden px-3 py-2 text-sm font-medium leading-5
              text-white focus:outline-none
              transition duration-150 ease-in-out
              block lg:inline-block lg:border-none border-b-2 border-gray-500 bottom-links"
              >LOG IN</a>
      </div>

      <div class="hidden lg:flex lg:w-auto lg:ml-3 lg:flex-shrink-0 lg:flex items-right" style="margin-right:50px" v-if = "isSignedIn">
        <div class="ml-3 relative">
        <drop-down-menu title="VIEW DEMO" :links="profile_menu">
           <button class="flex text-sm border-2 border-transparent rounded-full">
             <profile-icon-component></profile-icon-component>
           </button>
         </drop-down-menu>
       </div>
    </div>
    <div class="hidden lg:flex lg:w-auto lg:ml-3 lg:flex-shrink-0 lg:flex items-right"  v-if = "!isSignedIn" id = "login">
          <a
                @click="login('login')"
                class=""
                style = "color: #47aa42"
                >LOG IN</a
              >
          <button style = "position:relative; right:30px;"
            class="inline-flex items-center justify-center p-2 rounded-lg
            focus:outline-none"
            aria-label="login"
            @click="login('login')"

          >
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="#47aa42 "
              viewBox="0 0 24 24"
              stroke="#47aa42 "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </button>
      </div>
</nav>
</template>

<script>

import vClickOutside from 'v-click-outside'
import DropDownMenu from "../components/DropDownMenu.vue"
import ProfileIconComponent from '../views/sandbox/components/profile_icon_component.vue';
import { verifyAuthenticated,signOut } from "@/scripts/Authentication.js";

export default {
  components: {DropDownMenu, ProfileIconComponent},

  props: [],

  data() {
    return {
      menuOpened: false,
      toggled: false,
      about_menu:[{name:'OUR STORY',url:'Company'},{name:'OUR TEAM',url:'Teams'}, {name:'CAREERS',url:'Careers'}],
      create_menu:[{name:'PERSONAL',url:'Narratives'},{name:'SMALL / MEDIUM BUSINESS',url:'Narratives'}, {name:'ENTERPRISE',url:'Narratives'}],
      resources_menu:[{name:'TEMPLATES',url:'/'},{name:'FAQs',url:'/'}, {name:'CASE STUDIES',url:'/'}, {name:'SUPPORT',url:'/'}, {name:'HOW TO VIDEOS',url:'/'}, {name:'WEBINARS',url:'/'}],
      pricing_menu:[{name:'PRICING',url:'/'}],
      demo_menu:[{name:'DEMO',url:'/'}],
      profile_menu:[{name:'YOUR PROFILE',url:'Profile'},{name:'SIGN OUT',url:'SignOut'}]
    };
  },

  watch: {},

   directives: {
    clickOutside: vClickOutside.directive
  },

  methods: {
    openMenu(event) {
      this.menuOpened = !this.menuOpened;
      event.stopPropagation();
    },
    closeMenu() {
      this.menuOpened = false;
    },
    login(prompt) {
      this.$router.push("SignIn");
    },

    signOut() {
      signOut();
      this.$router.push("Home");
    },

    toggle() {
      this.toggled = !this.toggled
    },
  },


  computed: {

    username() {
      if($data.user){
        console.log("USER: ",$data.user.username)
        return $data.user.username;
      }
      else
        return null;
    },
    dashboard() {
      let username = null;
      if($data.user!=null)
        username = $data.user.username;
      var location = {
        name: "Dashboard",
        params: { username: username },
      };
      return location;
    },
    isSignedIn() {
      console.log("SIGNED IN: ", $data.user)
     return $data.user != null;
    },
  },

  async beforeCreate() {
    verifyAuthenticated();
  },
};
</script>

<style scoped>
#links {
  z-index:1000;
  background: "white";
}
.link {
}

#dashboard-navbar a:hover {
    color: gray;

}
a:hover {
 cursor:pointer;
}
</style>
