<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>

  <div ref="panel" id="myDIV" class="cd-panel cd-panel--from-right js-cd-panel-main ">
     <header @click="close" class="cd-panel__header">
        <i class="fas fa-times close"></i>
        <h1>{{title}}</h1>
     </header>

     <div class="cd-panel__container">
        <div class="cd-panel__content" v-if="content">
          <text-editor v-if="content.type=='TEXT'" :content="content"></text-editor>
          <image-editor v-if="content.type=='IMAGE'" :content="content"></image-editor>
          <video-editor v-if="content.type=='VIDEO'" :content="content"></video-editor>
          <audio-editor v-if="content.type=='AUDIO'" :content="content"></audio-editor>
          <background-editor v-if="content.type=='BACKGROUND'" :content="content"></background-editor>

           <!-- your side panel content here -->
        </div> <!-- cd-panel__content -->
     </div> <!-- cd-panel__container -->
  </div> <!-- cd-panel -->
</template>

<script>

import TextEditor from "@/views/Narrator/editors/text.vue";
import ImageEditor from "@/views/Narrator/editors/image.vue";
import VideoEditor from "@/views/Narrator/editors/video.vue";
import AudioEditor from "@/views/Narrator/editors/audio.vue";
import BackgroundEditor from "@/views/Narrator/editors/background.vue";
import CloseIcon from "@/components/Icon/CloseIcon.vue";
import Vue from 'vue'

export default {
  components: {
    CloseIcon,
    TextEditor,
    VideoEditor,
    AudioEditor,
    ImageEditor,
    BackgroundEditor
  },

  props: [],

  data() {
    return {
      title:"",
      type:null,
      content:{type:null}
    };
  },

  watch: {
    content:function(){

    }
  },

  methods: {
    close: function(){
      $editing = false;
      this.content = null;
      if(this.$refs.panel)
        this.$refs.panel.classList.remove("cd-panel--is-visible");
      $events.$emit("highlight",null);
      $events.$emit("save");
    },
    show: function(){
      if(this.$refs.panel)
        this.$refs.panel.classList.add("cd-panel--is-visible");
    },
    edit: function(content){
      $editing = true;
      Vue.set(this, 'content', content)
      var type = content.type
      this.content = content
      if(type == "IMAGE")
        this.title = "Image Settings";
      else if(type == "TEXT")
          this.title = "Text Settings";
      else if(type == "VIDEO")
        this.title = "Video Settings";
      else if(type == "AUDIO")
        this.title = "Audio Settings";
      else if(type == "BACKGROUND")
        this.title = "Background Settings";

      this.type = type;
      this.show()
    },
    click(){
      this.$refs.file_input.click()
    },
    filesChanged(ev){

      if(this.$refs.file_input.files.length>0)
        this.upload(this.$refs.file_input.files[0])
    },
    async upload(file){

      let ret = await $samsa.sign_s3.post(file.name,file.type);

      this.uploadFile(file, ret.data, ret.url);

    },
    uploadFile(file, s3Data, url){
      var self = this;
      var xhr = new XMLHttpRequest();
      xhr.open("POST", s3Data.url);

      var postData = new FormData();
      for(var key in s3Data.fields){
        postData.append(key, s3Data.fields[key]);
      }
      postData.append('file', file);
      xhr.upload.onprogress = function(ev) {

      }
      xhr.onreadystatechange = function() {
        if(xhr.readyState === 4){
          if(xhr.status === 200 || xhr.status === 204){
            self.content.url = url;
            window.$events.$emit("image_added", url)
            window.$events.$emit("close_edit")
          }
          else{
            alert("Could not upload file.");
          }
       }
      };
      xhr.send(postData);
    }
  },

  computed: {
    data() {

    },
  },

  mounted() {
    var self = this
    $events.$on("edit", this.edit);
    $events.$on("close_edit", this.close);
    $events.$on("delete", this.close);

    //window.setTimeout(function(){
    //  self.show()
    //},2000);
  },
};
</script>

<style lang="scss">
.cd-panel__content{
  background-color: #bdc3c7;
  height:100%;
  padding-top:50px;
  box-shadow: 0px 0px 5px grey;
}

.close {
    color: $red;
    font-size: 28px;
    padding: 10px;

    &:hover {
      color: lighten($red, 10%);
      cursor: pointer;
      transition: all .2s;
    }
  }

.cd-panel__header{
  background-color: #ecf0f1;
  z-index: 1;
  box-shadow: 0px 5px 5px grey;
  display:flex;
  flex-direction: row;
}

.cd-panel__header h1{
  margin-top:15px;
  margin-left:20px;
  line-height: 20px;
  font-size: 25px;
}

.cd-panel {
  /*...*/
  z-index: 20;
  visibility: hidden;
  transition: visibility 0s 0.6s;
  max-width: 400px;
}

.cd-panel.cd-panel--is-visible {
  visibility: visible;
  transition: visibility 0s 0s;
}

.cd-panel__header {
  /*...*/
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 400px;
  height: 50px;
  transition: transform 0.3s 0s;
  transform: translateY(-50px);

}

.cd-panel--from-right .cd-panel__header {
  right: 0;
}

.cd-panel--from-left .cd-panel__header {
  left: 0;
}

.cd-panel--is-visible .cd-panel__header {
  transition: transform 0.3s 0.3s;
  transform: translateY(0px);
}

.cd-panel__container {
  /*...*/
  position: fixed;
  width: 100%;
  max-width: 400px;
  height: 100%;
  top: 0;
  transition: transform 0.3s 0.3s;
}

.cd-panel--from-right .cd-panel__container {
  right: 0;
  transform: translate3d(100%, 0, 0);
}

.cd-panel--from-left .cd-panel__container {
  left: 0;
  transform: translate3d(-100%, 0, 0);
}

.cd-panel--is-visible .cd-panel__container {
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}

</style>
