<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
	<div class="header">
    <div v-for="(item, index) in users" :key="index">
      {{item.username}} <button @click="subscribe(item)"> subscribe </button>
    </div>
  </div>
</template>

<script>
	export default {
		components: {},

		props: {},

		data() {
			return {
				users: [],
			};
		},

		updated: function() {},
		watch: {},
		methods: {
      subscribe(item){
        console.log("Subscribing:", item)
        item.subscriptionStatus = 1;
        $samsa.saveUser.post(item);
      }

    },

		computed: {},

		mounted() {
      let self = this;
      $samsa.listUsers().then(function(res){
        self.users = res.data;
      })
    },
	};
</script>

<style scoped>

.header{
  padding-top:200px;
}

</style>
