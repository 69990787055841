// Copyright 2019-2021 Michigan Aerospace Corporation
// All Rights Reserved
// Author: Chad Lewis, David Vasseliou, Leslie Borst

import router from "@/router/index.js";
import { reportError, EventBus } from "@/utils.js";
import { getData } from "@/views/Datasets/api.js";
import omit from 'lodash/omit'
import cloneDeep from 'lodash/cloneDeep'

const state = {
  list: [],
  current: { elements: [], images: [], videos: [], audio:[] },
  narrativeHistory: [],
  activeBackground: null
};

const getters = {};

const mutations = {
  setNarratives(state, narratives) {
    state.list = narratives;
  },

  setNarrative(state, narrative) {
    state.current = narrative;
  },

  refreshNarrative(state, updatedNarrative) {
    const narrativeIndex = state.list.findIndex(narrative => narrative?.Oid === updatedNarrative.Oid);
    if (narrativeIndex != -1) {
      state.list.splice(narrativeIndex, 1, updatedNarrative);
    }
  },

  setDatasets(state, datasets) {
    state.datasets = datasets;
  },

  setImages(state, images) {
    state.current.images = images;
  },

  setVideos(state, videos) {
    state.current.videos = videos;
  },

  setAudio(state, audio) {
    state.current.audio = audio;
  },

  updateElementOrder(state, elements) {
    state.current.elements = elements;
  },

  pushNarrativeHistory(state) {
    state.narrativeHistory.push(state.current.elements);
  },

  popNarrativeHistory(state) {
    if(state.narrativeHistory.length) {
      state.current.elements = state.narrativeHistory.pop()
    }
  },

  clearNarrativeHistory() {
    state.narrativeHistory = []
  },

  removeActiveBackground(state, element){
    if(state.activeBackground && state.activeBackground['Oid'] === element['Oid']) {
      state.activeBackground = null
    }
  },

  setActiveBackground(state, element){
    state.activeBackground = element
  }
};

const actions = {
  getNarratives(context, username) {
    // Get all narratives from the user.
    $samsa
      .getNarratives(username)
      .then((res) => {
        context.commit("setNarratives", res.data);
      })
      .catch((err) => {
        reportError(err);
      });
  },

  getNarrative(context, { username, narrativeName }) {
    // Get a specific narrative.
    //CML- Clear the Cached narrative information
    state.activeBackground = null;

    $samsa
      .getNarrative(username, narrativeName)
      .then((res) => {
        context.commit('clearNarrativeHistory')
        context.commit("setNarrative", res.data);
      })
      .catch((err) => {
        reportError(err);
      });
  },

  create(context, { username, narrative }) {
    $samsa.createNarrative
      .post(username, narrative)
      .then((res) => {
        context.commit('clearNarrativeHistory')
        context.commit("setNarrative", res.data.createNarrative);
        EventBus.$emit("narrative-created", {});
      })
      .catch((err) => {
        reportError(err);
      });
  },

  deleteNarrative(context, { narrativeId, username }) {
    $samsa.deleteNarrative
      .post(narrativeId)
      .then((res) => {
        context.commit("setNarrative", { elements: [] });
        context.commit("setImages", []);
        context.commit("setImageDataset", {});
        EventBus.$emit("narrative-deleted", {});
        context.dispatch('modals/showSuccess', { message: 'Narrative successfully deleted' }, { root: true })
      })
      .catch((err) => {
        reportError(err);
      });
  },

  createElement(context, { narrativeId, elementType, elementRank }) {
    $samsa.createElement
      .post(narrativeId, elementType, elementRank)
      .then((res) => {
        context.commit('pushNarrativeHistory')
        context.commit("setNarrative", res.data.createElement);
      })
      .catch((err) => {
        reportError(err);
      });
  },

  deleteElement(context, element) {
    $samsa.deleteElement
      .post(element)
      .then((res) => {
        context.commit('pushNarrativeHistory')
        context.commit("setNarrative", res.data.deleteElement);
      })
      .catch((err) => {
        reportError(err);
      });
  },

  updateElement(context, element) {
    $samsa.updateElement
      .post(element)
      .then((res) => {
        // Do NOTHING — all is good.
        // context.commit("setNarrative", res.data.updateElement);
      })
      .catch((err) => {
        reportError(err);
      });
  },

  updateElementOrder(context, elements) {
    context.commit('pushNarrativeHistory')
    context.commit("updateElementOrder", elements);
    context.dispatch("updateNarrative", context.state.current);
  },

  queryImages(context, { dataset, page, perPage }) {
    // Grab raw data via a GreaphQL query.
    getData(dataset, page, perPage)
      .then((res) => {
        context.commit("setImages", res.data.data.select);
      })
      .catch((err) => {
      });
  },

  getImages(context, elementId) {
    $samsa
      .getImages(elementId)
      .then((res) => {
        var dataset = res.data;
         context.commit("setImages", dataset);

      })
      .catch((err) => {
        reportError(err);
      });
  },
   getAudio(context, elementId) {
    $samsa
      .getAudio(elementId)
      .then((res) => {
        var dataset = res.data;
         context.commit("setAudio", dataset);

      })
      .catch((err) => {
        reportError(err);
      });
    },

  queryAudio(context, { dataset, page, perPage }) {
    // Grab raw data via a GreaphQL query.
    getData(dataset, page, perPage)
      .then((res) => {
        context.commit("setAudio", res.data.data.select);
      })
      .catch((err) => {
      });
  },

  queryVideos(context, { dataset, page, perPage }) {
    // Grab raw data via a GraphQL query.
    getData(dataset, page, perPage)
      .then((res) => {
        context.commit("setVideos", res.data.data.select);
      })
      .catch((err) => {
      });
  },

  getVideos(context, elementId) {
    $samsa
      .getVideos(elementId)
      .then((res) => {
        var dataset = res.data;
         context.commit("setVideos", dataset);

      })
      .catch((err) => {
        reportError(err);
      });
  },




  updateNarrative(context, narrative) {


    $samsa.updateNarrative
      .post(narrative)
      .then((res) => {
        context.commit("setNarrative", res.data.updateNarrative);
        context.commit("refreshNarrative", res.data.updateNarrative);
        EventBus.$emit("narrative-updated");
        context.dispatch('modals/showSuccess', { message: 'Narrative successfully saved' }, { root: true })
      })
      .catch((err) => {
        reportError(err);
      });
  },

  copy(context, { username, narrative }) {

    $samsa.copyNarrative
      .post(narrative)
      .then((res) => {
        EventBus.$emit("narrative-copied", {});
        context.dispatch('modals/showSuccess', { message: 'Narrative successfully saved' }, { root: true })
      })
      .catch((err) => {
        reportError(err);
      });
  },

  undoNarrative(context) {
    context.commit('popNarrativeHistory')
    context.dispatch("updateNarrative", context.state.current);
  },

  copyElement(context, element) {
    const source = context.state.current.elements.find(e => e['Oid'] === element['Oid'])
    const newCopy = omit({
      ...cloneDeep(source),
      url: '',
      text: '',
      title: '',
      name: '',
    }, 'Oid')
    let newElements = [...context.state.current.elements]
    // insert to proper index
    newElements.splice(newCopy.rank + 1, 0, newCopy);
    // update rank based on index
    newElements = newElements.map((e, rank) => ({...e, rank}))
    context.dispatch('updateElementOrder', newElements)
    context.dispatch('modals/showSuccess', { message: 'Element successfully duplicated' }, { root: true })
  },

  updateElementItem(context, element) {
    context.dispatch('updateElementOrder', context.state.current.elements.map(e => e['Oid'] === element['Oid'] ? element: e))
  },

  setActiveBackground(context, element) {
    context.commit('setActiveBackground', element)
  },

  removeActiveBackground(context, element) {
    context.commit('removeActiveBackground', element)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
