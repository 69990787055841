<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="m-12 mt-2 flex flex-col w-auto">
    <c-warning
      title="Delete Repository?"
      :body="warningMessage"
      :openWarning="deleting"
      v-on:cancel="deleting = false"
      v-on:proceed="deleteRepo"
    ></c-warning>
    <div class="w-full flex flex-row justify-between p-4 px-0">
      <breadcrumbs :links="links"></breadcrumbs>

      <div
        class="flex flex-row p-1 bg-gray-100 rounded-md border border-gray-300"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="h-8 w-8 text-blue-500 mr-4 cursor-pointer"
          v-tooltip="'Add new dataset'"
          @click="
            $router.push({
              name: 'AddDataset',
              params: { repoName: name, username: username },
            })
          "
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0
            100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
            clip-rule="evenodd"
          />
        </svg>

        <!-- TRASH CAN -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="h-8 w-8 text-red-600 cursor-pointer"
          v-tooltip="'Delete repository'"
          @click="attemptDelete"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0
            01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1
            1v3M4 7h16"
          />
        </svg>
      </div>
    </div>

    <div class="mt-2">
      <h1
        class="tracking-wider uppercase text-blue-500 text-md"
        v-if="datasets.length == 0"
      >
        This repository contains no datasets.
      </h1>
      <div v-else>
        <h1 class="text-gray-500 mb-6 font-semibold text-lg">
          Available datasets:
        </h1>
        <dataset-list
          :username="username"
          :repo-name="name"
          :datasets="repo.datasets"
        ></dataset-list>
      </div>
    </div>
  </div>
</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DatasetList from "@/views/Repositories/DatasetList.vue";
import CWarning from "@/components/Core/CWarning";

export default {
  components: { Breadcrumbs, DatasetList, CWarning },

  props: ["username", "name"],

  data() {
    return {
      warningMessage: `
      Are you sure you want to delete this repository? The repository and all
      of its datasets will be permanently deleted. This cannot be undone.
      `,
      deleting: false,
    };
  },

  watch: {},

  methods: {
    attemptDelete() {
      this.deleting = true;
    },

    deleteRepo() {
      let details = { username: this.username, repoId: this.repo.Oid };
      this.$store.dispatch("repos/deleteRepo", details);
    },
  },

  computed: {
    repos() {
      return this.store.state.repos.list;
    },

    repo() {
      return this.$store.state.repos.current;
    },

    links() {
      return [
        {
          name: this.$store.getters['users/nickname'] + "'s repositories",
          route: {
            name: "Repos",
            params: { username: this.username },
          },
        },
        {
          name: this.name,
          route: {
            name: "Repo",
            params: { username: this.username, name: this.name },
          },
        },
      ];
    },

    datasets() {
      return this.repo.datasets;
    },
  },

  mounted() {
    let repoDetails = { username: this.username, repoName: this.name };
    this.$store.dispatch("repos/getRepo", repoDetails);
  },
};
</script>

<style></style>
