<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="m-12 mt-2 flex flex-col w-auto">
    <div
      class="w-full flex flex-row justify-between p-4 px-0"
    >
      <breadcrumbs :links="links"></breadcrumbs>
    </div>
    <main class="max-w-lg mx-auto pt-10 pb-12 px-4 lg:pb-16">
      <form v-on:submit.prevent="createRepo">
        <div class="space-y-6">
          <div class="space-y-1">
            <h1 class="text-lg leading-6 font-medium text-gray-900">
              Data Repository Details
            </h1>
            <p class="text-sm leading-5 text-gray-500">
              Let’s get started by filling in the information below to create
              your new data repository.
            </p>
          </div>

          <div class="space-y-1">
            <label
              for="project_name"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Repository name
              <span v-if="isValidRepoName" class="text-blue-600">
                {{ fullPath }}
              </span>
              <span v-else class="text-red-500"
                >This repo name is not available.</span
              >
            </label>
            <div class="rounded-md shadow-sm">
              <input
                id="repoName"
                v-model="repository.name"
                class="form-input block w-full transition duration-150
              ease-in-out sm:text-sm sm:leading-5"
                spellcheck="false"
                value=""
              />
            </div>
          </div>

          <div class="space-y-1">
            <label
              for="description"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Description
            </label>
            <div class="rounded-md shadow-sm">
              <textarea
                id="description"
                v-model="repository.description"
                rows="3"
                class="form-textarea block w-full transition duration-150
              ease-in-out sm:text-sm sm:leading-5"
              ></textarea>
            </div>
          </div>

          <fieldset>
            <legend class="text-sm leading-5 font-medium text-gray-900">
              Privacy
            </legend>

            <div class="mt-1 bg-white rounded-md shadow-sm -space-y-px">
              <!-- On: "bg-light-blue-50 border-light-blue-200 z-10", Off: "border-gray-200" -->
              <div class="relative border rounded-tl-md rounded-tr-md p-4 flex">
                <div class="flex items-center h-5">
                  <input
                    id="settings-option-0"
                    value="public"
                    v-model="repository.privacySetting"
                    name="privacySetting"
                    type="radio"
                    class="form-radio h-4 w-4 text-light-blue-600 transition
                  duration-150 ease-in-out cursor-pointer"
                    checked
                  />
                </div>
                <label
                  for="settings-option-0"
                  class="ml-3 flex flex-col cursor-pointer"
                >
                  <!-- On: "text-light-blue-900", Off: "text-gray-900" -->
                  <span class="block text-sm leading-5 font-medium">
                    Public access
                  </span>
                  <!-- On: "text-light-blue-700", Off: "text-gray-500" -->
                  <span class="block text-sm leading-5">
                    This data repository would be available to anyone who has
                    the link
                  </span>
                </label>
              </div>

              <!-- On: "bg-light-blue-50 border-light-blue-200 z-10", Off: "border-gray-200" -->
              <div class="relative border border-gray-200 p-4 flex">
                <div class="flex items-center h-5">
                  <input
                    id="settings-option-1"
                    name="privacySetting"
                    v-model="repository.privacySetting"
                    value="private_to_members"
                    type="radio"
                    class="form-radio h-4 w-4 text-light-blue-600 transition
                  duration-150 ease-in-out cursor-pointer"
                  />
                </div>
                <label
                  for="settings-option-1"
                  class="ml-3 flex flex-col cursor-pointer"
                >
                  <!-- On: "text-light-blue-900", Off: "text-gray-900" -->
                  <span class="block text-sm leading-5 font-medium">
                    Private to Project Members
                  </span>
                  <!-- On: "text-light-blue-700", Off: "text-gray-500" -->
                  <span class="block text-sm leading-5">
                    Only members of this data repository would be able to access
                  </span>
                </label>
              </div>

              <!-- On: "bg-light-blue-50 border-light-blue-200 z-10", Off: "border-gray-200" -->
              <div
                class="relative border border-gray-200 rounded-bl-md rounded-br-md p-4 flex"
              >
                <div class="flex items-center h-5">
                  <input
                    id="settings-option-2"
                    value="private_to_me"
                    v-model="repository.privacySetting"
                    name="privacySetting"
                    type="radio"
                    class="form-radio h-4 w-4 text-light-blue-600 transition
                  duration-150 ease-in-out cursor-pointer"
                  />
                </div>
                <label
                  for="settings-option-2"
                  class="ml-3 flex flex-col cursor-pointer"
                >
                  <!-- On: "text-light-blue-900", Off: "text-gray-900" -->
                  <span class="block text-sm leading-5 font-medium">
                    Private to you
                  </span>
                  <!-- On: "text-light-blue-700", Off: "text-gray-500" -->
                  <span class="block text-sm leading-5">
                    You are the only one able to access this data repository
                  </span>
                </label>
              </div>
            </div>
          </fieldset>

          <div class="space-y-1">
            <label
              for="tags"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Tags
              <span class="text-gray-400"
                >(comma separated — e.g.: sensor, health)</span
              >
            </label>
            <div class="rounded-md shadow-sm">
              <input
                id="tags"
                v-model="repository.tags"
                class="form-input block w-full transition duration-150
                ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>

          <div class="flex justify-end">
            <span class="ml-3 inline-flex rounded-md shadow-sm">
              <button
                type="submit"
                class="inline-flex justify-center py-2 px-4 border
                border-transparent text-sm leading-5 font-medium rounded-md
                text-white bg-light-blue-500 hover:bg-light-blue-400
                focus:outline-none focus:border-light-blue-600
                focus:shadow-outline-blue active:bg-light-blue-600 transition
                duration-150 ease-in-out"
              >
                Create this repository
              </button>
            </span>
          </div>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
// import Component from "../component_location"
import { cleanName, debounce, reportError } from "@/utils.js";
import { ingestFiles } from "./api.js";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs },

  props: ["username"],

  data() {
    return {
      repository: {
        name: "",
        description: "",
        privacySetting: "public",
        tags: "weather, sensors, IOT",
      },
      uploading: false,
    };
  },

  watch: {},

  methods: {
    createRepo() {
      var details = { username: this.username, repo: this.repository };
      this.$store.dispatch("repos/createRepo", details);
    },

  },

  computed: {
    fullPath() {
      if (this.repository.name.length > 0) {
        return `(${this.username}/repos/${cleanName(this.repository.name)})`;
      } else {
        return "";
      }
    },

    isValidRepoName() {
      return this.$store.state.repos.validName;
    },

    links() {
      return [
        {
          name: this.$store.getters['users/nickname'] + "'s repositories",
          route: {
            name: "Repos",
            params: { username: this.username },
          },
        },
        {
          name: "create a new repo",
          route: {
            name: "CreateRepository",
            params: { username: this.username },
          },
        },
      ];
    },
  },

  mounted() {
    // Hooks to check the dataset name.
    var validateName = debounce(() => {
      this.repository.name = cleanName(this.repository.name);
      var details = { repoName: this.repository.name, username: this.username };
      this.$store.dispatch("repos/checkName", details);
    }, 1000);

    // When we type the name, validate that name (but debounce it).
    const name = document.querySelector("#repoName");
    name.addEventListener("keyup", validateName);
    validateName();
  },
};
</script>

<style></style>
