<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="m-12 mt-2 flex flex-col w-auto">
    <div
      class="w-full flex flex-row justify-between p-4 rounded-md
      border-blue-500 border"
    >
      <breadcrumbs :links="links"></breadcrumbs>

      <div
        class="flex flex-row p-1 bg-gray-100 rounded-md border border-gray-300"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="h-8 w-8 text-blue-500 cursor-pointer"
          v-tooltip="'Add new repository'"
          @click="
            $router.push({
              name: 'CreateRepository',
              params: { username: username },
            })
          "
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0
            100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>

    <div class="mt-1">
      <h1
        class="text-md tracking-wider uppercase text-blue-500"
        v-if="repos.length == 0"
      >
        You have not created any repositories.
      </h1>
    </div>

    <repo-list :username="username" :repos="repos"></repo-list>
  </div>
</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import RepoList from "@/views/Repositories/RepoList.vue";

export default {
  components: { Breadcrumbs, RepoList },

  props: ["username"],

  data() {
    return {
      datasets: [],
    };
  },

  watch: {},

  methods: {
    createRepository() {
      this.$router.push({
        name: "CreateRepository",
        params: { username: this.username },
      });
    },
  },

  computed: {
    user() {
      return this.$store.state.users.user;
    },
    repos() {
      return this.$store.state.repos.list;
    },
    links() {
      return [
        {
          name: this.$store.getters['users/nickname'] + "'s repositories",
          route: {
            name: "Repos",
            params: { username: this.username },
          },
        },
      ];
    },
  },

  mounted() {
    this.$store.dispatch("repos/getRepos", this.username);
  },
};
</script>

<style></style>
