<template>
  <nav class="flex flex-wrap items-center justify-between p-5 bg-gray-700" id="global-navbar">
           <router-link
            :to="{ name: 'Dashboard', params: { username: username } }">
              <img
                class="h-16"
                src="@/assets/images/Springmatter-Icon.png"
                title = "Back to Dashboard"
                alt="SPRINGMATTER LOGO"
                style = "  display: inline-block;"
              />
            </router-link>
            <div class="-ml-2 mr-2 flex items-center md:hidden">
            <!-- Mobile menu button -->
            <button
              class="inline-flex items-center justify-center p-2 rounded-md
              text-gray-400 hover:text-white hover:bg-gray-700
              focus:outline-none focus:bg-gray-700 focus:text-white transition
              duration-150 ease-in-out"
              aria-label="Main menu"
              aria-expanded="false"
              style = "float:right"
              v-on:click="toggle"

            >
              <!-- Icon when menu is closed. -->
              <!--
              Heroicon name: menu
              Menu open: "hidden", Menu closed: "block"
            -->
              <svg
                class="toggle block h-6 w-6"
                :class="{'hidden': toggled}"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <!-- Icon when menu is open. -->
              <!--
              Heroicon name: x

              Menu open: "block", Menu closed: "hidden"
            -->
              <svg
                class="toggle h-6 w-6"
                :class="{'hidden': !toggled}"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div class="toggle md:flex w-full md:w-auto text-center text-bold mt-5 md:mt-0 border-t-2 border-gray-500 md:bg-none bg-gray-700 md:border-none"
              :class="{'hidden': !toggled}"
              id = "links">
            <a
              class="px-3 py-2 text-sm font-medium leading-5
                text-white focus:outline-none
                transition duration-150 ease-in-out
                block md:inline-block md:border-none border-b-2 border-gray-500"
              href = "http://www.springmatter.org">
              Springmatter Fund
            </a>
            <router-link
              :to="{ name: 'Narratives', params: { username: username } }"
              class="md:hidden px-3 py-2 text-sm font-medium leading-5
              text-white focus:outline-none
              transition duration-150 ease-in-out
              block md:inline-block md:border-none border-b-2 border-gray-500"

              Your Profile
            </router-link>

            <router-link
            :to="{ name: 'Narratives', params: { username: username } }"
              class="md:hidden px-3 py-2 text-sm font-medium leading-5
              text-white focus:outline-none
              transition duration-150 ease-in-out
              block md:inline-block md:border-none border-b-2 border-gray-500">
              Settings
            </router-link>

            <a
            @click = "signOut"
            class="md:hidden px-3 py-2 text-sm font-medium leading-5
              text-white focus:outline-none
              transition duration-150 ease-in-out
              block md:inline-block md:border-none border-b-2 border-gray-500"
              Sign Out
            </a>
          </div>
        </div>
        <div class="hidden md:flex md:items-center">
          <div class="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
            <button
              class="p-1 border-2 border-transparent text-gray-400 rounded-full
              hover:text-gray-300 focus:outline-none focus:text-gray-700
              focus:bg-gray-100 transition duration-150 ease-in-out"
              aria-label="Notifications"
            >
              <!-- Heroicon name: bell -->
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002
                  6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6
                  11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6
                  0v-1m6 0H9"
                />
              </svg>
            </button>

            <!-- Profile dropdown -->
            <div class="ml-3 relative" v-click-outside="closeMenu">
              <div>
                <button
                  class="flex text-sm border-2 border-transparent rounded-full
                  focus:outline-none focus:border-gray-300 transition
                  duration-150 ease-in-out"
                  id="user-menu"
                  aria-label="User menu"
                  aria-haspopup="true"
                  v-on:click="openMenu"
                >
                  <span
                    class="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100"
                  >
                    <svg
                      class="h-full w-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004
                        15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0
                        11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <!--
              Profile dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-200"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
              <div
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
              >
                <div
                  class="py-1 rounded-md bg-white shadow-xs"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                  v-if="menuOpened"
                >
                  <a
                    href="#"
                    class="block px-4 py-2 text-sm leading-5 text-gray-700
                    hover:bg-gray-100 focus:outline-none focus:bg-gray-100
                    transition duration-150 ease-in-out"
                    role="menuitem"
                    >Your Profile</a
                  >
                  <a
                    class="block px-4 py-2 text-sm leading-5 text-gray-700
                    hover:bg-gray-100 focus:outline-none focus:bg-gray-100
                    transition duration-150 ease-in-out"
                    role="menuitem"
                    >Settings</a
                  >
                  <a
                    @click="signOut"
                    class="block px-4 py-2 text-sm leading-5 text-gray-700
                    hover:bg-gray-100 focus:outline-none focus:bg-gray-100
                    transition duration-150 ease-in-out cursor-pointer"
                    role="menuitem"
                    >Sign out</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

    <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  -->
    <div class="hidden md:hidden">
      <div class="px-2 pt-2 pb-3 sm:px-3">
        <a
          href="#"
          class="block px-3 py-2 rounded-md text-base font-medium text-white
          bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700
          transition duration-150 ease-in-out"
          >Dashboard</a
        >
        <a
          href="#"
          class="mt-1 block px-3 py-2 rounded-md text-base font-medium
          text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none
          focus:text-white focus:bg-gray-700 transition duration-150
          ease-in-out"
          >Team</a
        >
        <a
          href="#"
          class="mt-1 block px-3 py-2 rounded-md text-base font-medium
          text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none
          focus:text-white focus:bg-gray-700 transition duration-150
          ease-in-out"
          >Projects</a
        >
        <a
          href="#"
          class="mt-1 block px-3 py-2 rounded-md text-base font-medium
          text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none
          focus:text-white focus:bg-gray-700 transition duration-150
          ease-in-out"
          >Calendar</a
        >
      </div>
      <div class="pt-4 pb-3 border-t border-gray-700">
        <div class="flex items-center px-5 sm:px-6">
          <div class="flex-shrink-0"></div>
          <div class="ml-3">
            <div class="text-base font-medium leading-6 text-white">
              Tom Cook
            </div>
            <div class="text-sm font-medium leading-5 text-gray-400">
              tom@example.com
            </div>
          </div>
        </div>
        <div class="mt-3 px-2 sm:px-3">
          <a
            href="#"
            class="block px-3 py-2 rounded-md text-base font-medium
            text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none
            focus:text-white focus:bg-gray-700 transition duration-150
            ease-in-out"
            >Your Profile</a
          >
          <a
            href="#"
            class="mt-1 block px-3 py-2 rounded-md text-base font-medium
            text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none
            focus:text-white focus:bg-gray-700 transition duration-150
            ease-in-out"
            >Settings</a
          >
          <a
            href="#"
            class="mt-1 block px-3 py-2 rounded-md text-base font-medium
            text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none
            focus:text-white focus:bg-gray-700 transition duration-150
            ease-in-out"
            >Sign out</a
          >
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// import Component from "../component_location"
import vClickOutside from 'v-click-outside'




export default {
  components: {},

  props: [],

  data() {
    return {
      menuOpened: false,
      toggled: false,

    };
  },

  watch: {},

   directives: {
    clickOutside: vClickOutside.directive
  },

  methods: {
    openMenu() {
      this.menuOpened = !this.menuOpened;
    },
    closeMenu() {
      this.menuOpened = false;
    },

    signOut() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
      this.$store.dispatch("users/signOut");
    },
     toggle() {
      this.toggled = !this.toggled
    },

  },

  computed: {
    username() {
      return this.$store.state.users.user.username;
    },
    dashboard() {
      var location = {
        name: "Dashboard",
        params: { username: this.$store.state.users.user.username },
      };
      return location;
    },
  },

  mounted() {},
};
</script>

<style>
#links {
  z-index:1000;
}
</style>
