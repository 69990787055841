<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div
    class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6
    lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-20 w-auto"
        src="@/assets/images/Springmatter-Icon.png"
        alt="SPRINGMATTER LOGO"
      />
      <h2
        v-if="mode=='email'"
        class="mt-6 text-center text-3xl leading-9 font-extrabold
        text-gray-400"
      >
        Enter your email to reset your password:
      </h2>
      <h2
        v-else
        class="mt-6 text-center text-3xl leading-9 font-extrabold
        text-gray-400"
      >
        Create a new password.
      </h2>
      <p class="mt-2 text-center text-sm leading-5 text-gray-300 max-w"></p>
    </div>


    <div v-if="mode=='email'" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form v-on:submit.prevent="sendEmail" method="POST">
          <p v-if="error" class="error">The passwords do not match.</p>
          <div v-if="passwordSuccess" class="text-center">
            <p>A password reset email sent.</p>
            <router-link :to="{ name: 'SignIn'}" name="SignIn" class="text-blue-600 underline">Back to sign in page.</router-link>
          </div>

          <div class="mt-6" v-if="!passwordSuccess">
            <label
              for="email"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Email Address
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                id="email"
                type="email"
                v-model="email"
                required
                class="appearance-none block w-full px-3 py-2 border
                border-gray-300 rounded-md placeholder-gray-400
                focus:outline-none focus:shadow-outline-blue
                focus:border-blue-300 transition duration-150 ease-in-out
                sm:text-sm sm:leading-5"
              />
            </div>
          </div>

          <div class="mt-6" v-if="!passwordSuccess">
            <span class="block w-full rounded-md shadow-sm">
              <button
                type="submit"
                class="w-full flex justify-center py-2 px-4 border
                border-transparent text-sm font-medium rounded-md text-white
                bg-blue-600 hover:bg-blue-500 focus:outline-none
                focus:border-blue-700 focus:shadow-outline-indigo
                active:bg-blue-700 transition duration-150 ease-in-out"
              >
                Send Email
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>


    <div v-else class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form v-on:submit.prevent="newPassword" method="POST">
          <p v-if="error" class="error">The passwords do not match.</p>
          <div v-if="passwordSuccess" class="text-center">
            <p>Password change successful!</p>
            <router-link :to="{ name: 'SignIn'}" name="SignIn" class="text-blue-600 underline">Back to sign in page.</router-link>
          </div>

          <div class="mt-6 pw" v-if="!passwordSuccess">
            <label
              for="password"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              New Password
            </label>
            <div class="mt-1 rounded-md shadow-sm pw">
              <!--<input
                id="password"
                type="password"
                v-model:strength="passwordValue"
                v-model:match="password"
                required
                class="appearance-none block w-full px-3 py-2 border
                border-gray-300 rounded-md placeholder-gray-400
                focus:outline-none focus:shadow-outline-blue
                focus:border-blue-300 transition duration-150 ease-in-out
                sm:text-sm sm:leading-5"
              />
              <password-meter :password="passwordValue" />-->
                            <div class="input_container">
                <div class="checkmark_container" v-bind:class="{ show_checkmark: valid_password }">		
                  <svg width="50%" height="50%" viewBox="0 0 140 100">
                    <path class="checkmark" v-bind:class="{ checked: valid_password }" d="M10,50 l25,40 l95,-70" />
                  </svg>
                </div>
                
                <input type="password" @input="checkPassword" v-model="password" autocomplete="off" placeholder="Password" />
              </div>
                <ul>
                  <li v-bind:class="{ is_valid: contains_eight_characters }">8 Characters</li>
                  <li v-bind:class="{ is_valid: contains_number }">Contains Number</li>
                  <li v-bind:class="{ is_valid: contains_uppercase }">Contains Uppercase</li>
                  <li v-bind:class="{ is_valid: contains_special_character }">Contains Special Character</li>
                </ul>
            </div>
          </div>
          <div class="mt-6" v-if="!passwordSuccess">
            <label
              for="password"
              class="block text-sm font-medium leading-5 text-gray-700"
            >
              Confirm New Password
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input
                id="passwordConfirm"
                type="password"
                v-model="passwordConfirm"
                required
                class="appearance-none block w-full px-3 py-2 border
                border-gray-300 rounded-md placeholder-gray-400
                focus:outline-none focus:shadow-outline-blue
                focus:border-blue-300 transition duration-150 ease-in-out
                sm:text-sm sm:leading-5"
              />
            </div>
          </div>



          <div class="mt-6" v-if="!passwordSuccess">
            <span class="block w-full rounded-md shadow-sm">
              <button
                type="submit"
                class="w-full flex justify-center py-2 px-4 border
                border-transparent text-sm font-medium rounded-md text-white
                bg-blue-600 hover:bg-blue-500 focus:outline-none
                focus:border-blue-700 focus:shadow-outline-indigo
                active:bg-blue-700 transition duration-150 ease-in-out"
              >
                Reset Password
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import Component from "../component_location"
import { EventBus } from "@/utils.js";
import { user, verifyAuthenticated,signIn } from "@/scripts/Authentication.js";
import passwordMeter from "vue-simple-password-meter";

export default {

  components: { passwordMeter },

  props: {pwkey:{default:null}},

  data() {
    return {
      mode:"email",
      email: "",
      passwordConfirm: "",
      passwordSuccess: false,
      error: false,
      passwordValue: null,
      password: null,
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false
    };
  },

  watch: {},

  methods: {

    // signIn() {
    //   var self = this;
    //   var loginInfo = { email: this.email, password: this.password };
    //   signIn(loginInfo).then(function(res){
    //       console.log("SignIn:", res.data.username)
    //       self.$router.push({
    //          name: 'Portal',
    //        });
    //   }).catch((error) => {
    //     this.error = true;
    //     console.log(error);
    //   });

    // this.$samsa
    //   .signIn(this.email, this.password)
    //   .then(res => {
    //     this.$store.commit('signIn', res.data);
    //     this.$router.push({
    //       name: 'Dashboard',
    //       params: {username: res.data.username},
    //     });
    //   })
    //   .catch(err => {
    //     EventBus.$emit('error', err.request.response);
    //   });
    //},
    sendEmail(){
      $samsa.email_reset_password(this.email)
      this.passwordSuccess = true;
    },
    newPassword() {
      if(this.password == this.passwordConfirm) {
        $samsa.reset_password(this.pwkey,this.password)
        this.error = false;
        this.passwordSuccess = true;
      }else {
        this.error = true;
        this.passwordSuccess = false;
      }
    },
    checkPassword() {
      this.password_length = this.password.length;
			const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
			
      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
			}
			
      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
			this.contains_special_character = format.test(this.password);
      
      if (this.contains_eight_characters === true &&
					this.contains_special_character === true &&
					this.contains_uppercase === true &&
					this.contains_number === true) {
						this.valid_password = true;			
      } else {
        this.valid_password = false;
      }
    }
  },

  computed: {},

  mounted() {
    console.log("KEY:", this.pwkey)
    if(this.pwkey != null){
      console.log("Other Mode")
      this.mode = "password"
    }

  },
};
</script>

<style scoped lang="scss">
p.error {
  color: #d20000;
  line-height: 1.25em;
  margin-bottom: 1em;
}

.pw .shadow-sm {
  box-shadow: none;
}

.mt-1.rounded-md.shadow-sm #password {
  width: 100%;
  border-radius: 5px;
}
/*.po-password-strength-bar {
  margin-top: 12px;;
}*/
.mt-1.rounded-md.shadow-sm.pw  {
  box-shadow: none;
}

ul {
	padding-left: 2px;
  margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

li { 
	margin-bottom: 5px;
	color: #525f7f;
	position: relative;
  font-size: 0.9em;
}

li:before {
  content: "";
	width: 0%; height: 2px;
	background: #2ecc71;
	position: absolute;
	left: 0; top: 50%;
	display: block;
	transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#app { width: 400px; }


/* Password Input --------- */

input[type="password"] {
	line-height: 1.5;
	display: block;
	color: rgba(136, 152, 170, 1);
	font-weight: 300;
	width: 100%;
	height: calc(2.75rem + 2px);
	padding: .625rem .75rem;
	border-radius: .25rem;
	background-color: #fff;
	transition: border-color .4s ease;
	border: 1px solid #cad1d7;
	outline: 0;
}

input[type="password"]:focus {
	border-color: rgba(50, 151, 211, .45);
}


/* Checkmark & Strikethrough --------- */

.input_container {
  position: relative;
}

.is_valid { color: rgba(136, 152, 170, 0.8); }
.is_valid:before { width: 100%; }

.checkmark_container {
	border-radius: 50%;
	position: absolute;
	top: -27px; right: 2px;
	background: #2ecc71;
	width: 20px; height: 20px;
	visibility: hidden;
	opacity: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity .4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked { animation: draw 0.5s ease forwards; }

@keyframes draw {
  to { stroke-dashoffset: 0; }
}
</style>
