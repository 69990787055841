<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
<div class="flex flex-col earth-background" id = "blurry">

  <div class="contain career-wrapper new">
    <transition name="fade" appear>
      <div class="header-section">
        <div class="header-content">
          <h1 class="hero">JOIN OUR TEAM</h1>
          <p>At Springmatter, we know every person can have a meaningful impact and we strive to empower people to accomplish their career and life goals.</p>
          <a href="#scroll-location" class="scroll-arrow">&#8595;</a>
        </div>
      </div>
    </transition>
    <div id="scroll-location" class="content careers">
      <div class="narrow">

        <p class="green-copy">These aren't just words to us. It's our mission and our passion. But we don’t have all the answers, which is why we need you!</p>

        <p>We know it’s our differences that make us stronger and we strive to bring diverse experiences to everything we do. We are committed to providing an inclusive environment where authenticity is valued. One that inspires everyone to grow and thrive.</p>

        <p>Our mission extends to the world around us, where we strive to provide equitable learning and employment opportunities for marginalized and under-represented communities. Through our Springmatter Fund, we work with community organizations and educators on youth empowerment, STEAM, social justice and environmental justice initiatives.</p>

      </div>
    </div>
    <div class="content careers full-width">
      <div class="inner-padding">
        <p>When you wake up in the morning and look ahead to your day of creating stories and helping others do the same, you are thrilled, knowing you are in the right place to use your talents in a meaningful way.</p>

        <p>You have a passion for empowering people to create and share their stories and your world view places a high priority on social and environmental justice issues.</p>
      </div>
    </div>
    <img class="stairs-mobile" src="/images/stairs.jpg" />
    <div class="content careers">
      <div class="left">
        <h3>Open Requisitions</h3>

        <p>We have multiple open positions and are always looking for world-class talent in software development, product management, storytelling, and customer success.</p>

        <p>If you are interested, create your narrative and send us the link!</p>

        <!--<p>You can find the following positions below on LinkedIn:</p>
        <ul class="top-pdfs">
          <li>
            <a href="https://www.linkedin.com/jobs/view/2542443005/" target="_blank" name="linkedin-product-manager">Product Manager &#8250;</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/jobs/view/2542439222/" target="_blank" name="linkedin-full-stack">Full Stack &#8250;</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/jobs/view/2521264617/" target="_blank" name="linkedin-font-end">Front End &#8250;</a>
          </li>
        </ul>-->
      </div>
      <div class="right desktop">
        <img src="/images/stairs.jpg" />
      </div>
    </div>
    <div class="content careers full-width bottom">
      <div class="inner-padding">
        <p>Want to join us or just learn more?</p>

        <p><a href="mailto:info@springmatter.com" name="careers-email">info@springmatter.com</a> | <span><a href="https://www.linkedin.com/company/springmatter/about" target="_blank" name="linkedin-profile"><img class="social li" src="/images/LI-In-Bug.png" /></a></span></p>
      </div>
    </div>

  </div>
</div>

</div>

</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";
import Foot from "@/components/Footer.vue";

export default {
  components: { Breadcrumbs, NavbarDash, Foot },

  props: [],

  data() {
    return {
      name:"careers"
    };
  },

  watch: {},

  methods: {
  },

  computed: {},


  mounted() {},
};

</script>

<style>

</style>
