<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
<div class="editor">

  <div>
    <div class="label"><span>Background Type: </span>
      <v-select
        v-model="content.mode"
        :options="transitionDuration"
        :reduce="type => type.mode"
        label="label"
        class="bg-white"
        style="width:200px"
      ></v-select>
    </div>
  </div>

  <div>
    <div class="label"><span>Color 1:</span>
    <v-swatches
      v-model="content.color1"
      swatches="text-advanced"
      class="p-0 rounded-md mt-1 mr-4"
      show-fallback
      fallback-input-type="color">
    </v-swatches>
    </div>
  </div>

  <div>
    <div class="label"><span>Color 2:</span>
    <v-swatches
      v-model="content.color2"
      swatches="text-advanced"
      class="p-0 rounded-md mt-1 mr-4"
      show-fallback
      fallback-input-type="color">
    </v-swatches>
    </div>
  </div>
  <div class="delete" @click="delete_element">Delete</div>
</div>

</template>

<script>

import VSwatches from "vue-swatches";
export default {
  components: {VSwatches},

  props: ["content"],

  data() {
    return {
      type:{},
      text:"",
      backgroundColor:"#F00",
      transitionDuration: [
        {label:"Solid Color",mode:"SOLID"},
        {label:"Angle", mode:"ANGLE"},
        {label:"Wave", mode:"WAVE"},
        {label:"Image", mode:"IMAGE"}],
      files:[]
    };
  },

  watch: {

  },

  methods: {
    delete_element(){
      $events.$emit("delete", this.content);
    },

  },

  computed: {

  },

  mounted() {

  },
};
</script>

<style scoped lang="scss">

.delete{
  display: inline-block;
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  width: 90%;
  height: 50px;
  margin: 20px;
  background-color: $red;
  color: $white;
  border:1px solid $white;
  border-radius: 5px;

  &:hover{
    background-color: $red;
    cursor: pointer;
  }
}

input{
  height:20px;
}

.editor{
  margin-top:10px;
}

.label{
  margin-left: 10px;
  display:flex;
  align-items:center;
}
.label span{
  margin-right: 5px;
}

</style>
