<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
	<div>
		<div class="title">Edit Profile</div>
		<top-section></top-section>
		<div class="title">Location</div>
		<bottom-section></bottom-section>
		<button type="button">
					Update Account
				</button>
	</div>
</template>

<script>
	import TopSection from "@/views/sandbox/components/top_section.vue";
	import BottomSection from "@/views/sandbox/components/bottom_section.vue";

	export default {
		components: { TopSection, BottomSection },

		props: {},

		data() {
			return {
				username: "",
				email: "",
				first_name: "",
				last_name: "",
				city: "",
				state: "",
				zip_code: "",
			};
		},

		updated: function() {},
		watch: {},
		methods: {},

		computed: {},

		mounted() {},
	};
</script>

<style scoped>
	.title {
		margin: 15px;
		font-size: 20px;
		font-weight: bold;
	}

	button {
		position: relative;
		font-size: 20px;
		font-weight: 500;
		display: block;
		/*margin: 0 200px;*/
		margin-left: 350px;
		margin-top: 30px;
		margin-bottom: 10px;
		color: white;
		background-color: grey;
		padding: 5px;
		border-radius: 10px;
		width: 35%;
		transition: all 300ms ease-in-out;
	}

	button:hover {
		background-color: #9e9e9e;
		box-shadow: 0 0 5px black;
	}

	button:focus{
		outline: 0;
		background-color: white;
		color: grey;
		border: 3px solid grey;
		border-radius: 10px;
		box-shadow: 0 0 5px black;
	}

	@media screen and (max-width: 1024px) {

		button {

		margin: 0 auto;
		}

	}

	@media screen and (max-width: 360px) {
		button {
			font-size: 17px;
			font-weight: 500;
		}
	}

	@media screen and (max-width: 309px) {
		button {
			font-size: 15px;
			font-weight: 600;
		}
	}
</style>
