<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
	<div v-if="user">
		<div
			v-if="user.picture"
			class="profile-icon"
			:style="{ backgroundImage: 'url(' + user.picture + ')' }"
		></div>
		<svg
			v-else
			style="width:36px; height:36px;"
			class="text-gray-300"
			fill="currentColor"
			viewBox="0 0 24 24"
		>
			<path
				d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
			/>
		</svg>
	</div>
</template>

<script>
	import { openFilePicker, EventBus } from "@/utils.js";


	export default {
		components: {},

		props: {},

		data() {
			return {
				opacity: 100,
			};
		},

		updated: function() {},
		watch: {},
		methods: {
			upload() {
				const client = openFilePicker();
				var acceptable;
				acceptable = ["image/*"];
				var options = {
					accept: acceptable,
					onUploadDone: this.filesUploaded,
					onCancel: (e) => {
						this.uploading = false;
					},
					maxFiles: 1,
					transformations: { crop: false, circle: false, rotate: false },
				};
				client.picker(options).open();
			},
			filesUploaded(fileInfo) {
				var self = this;
				var filesUploaded = fileInfo.filesUploaded;
				if (filesUploaded.length == 1) {
					console.log(self.user);
					self.user.picture = filesUploaded[0].url;

					$samsa.setProfilePic
						.post(this.user.username, filesUploaded[0].url)
						.then((res) => {});
				}
			},
		},

		computed: {
			user(){
				return $data.user;
			}
		},

		mounted() {},
	};
</script>

<style scoped>
	.profile-icon {
		border-radius: 50%;
		width: 50px;
		height: 50px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
</style>
