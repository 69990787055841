<template>
    <div class="input-container">
        <label :for="id">
            <span>{{ labelText }}</span>
        </label>
        <textarea
            :id="id"
            :value="value"
            @input="updateText($event.target.value)"
            class="input-container__textarea"
            rows="rows">
        </textarea>
    </div>

</template>

<script>


export default {
  components: {

  },
  props: ["id", "labelText", "value", "rows"],

  data() {
    return{}
  },

  watch: {
    
  },

  methods: {
   updateText(value) {
      this.$emit('input', value);
    }
  },

  computed: {
    
  },

  mounted() {

  },
};

</script>

<style scoped lang="scss">
.input-container {
    display: flex;
    flex-direction: column;

    &__textarea {
        width: 100%;
        height: 80px;
        outline: 0;
        margin: 0;
        text-align: left;
        max-width: 100%;
        padding: 5px 10px !important;
        transition: all ease 0.25s;
        background-color: $white;
        border: 1px solid;
        border-color: #ccc #e0e0e0 #e0e0e0 #ccc;
        border-radius: 3px;
    }
}

</style>