<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>

  <div v-if="!view" class="audio" :style="{left:left, top:top}">
    <div v-if="content.url==null"  id="audio" class="parent">
      <img src="/images/button-audio5.png" />
    </div>
    <audio v-else  :src="content.url" ref="audio" controls autoplay playsinline></audio>
  </div>
  <div v-else class="audio" :style="{left:left, top:top}">
  </div>

</template>

<script>

import { play, load } from "@/scripts/Audio.js";

export default {
  components: {},

  props: {scrollTop:{type:Number},content:{default:null},view:{default:false}},

  data() {
    return {
      playing:false,
    };
  },

  watch: {
      scrollTop:function(after, before){
        var node = this.$el.parentNode;
        var height = document.getElementById("narrative").getBoundingClientRect().height
        var rect = this.$el.getBoundingClientRect()

        var loc = (height - rect.y) / height ;

        if(loc > 0 && loc<1 && this.view)
          this.play()
      }
  },
  methods: {
    play(){

        play(this.content.url)

    },
    edit(){
      window.$events.$emit("edit",this.content)
    },

  },

  computed: {
    left:function(){
      return this.content.x + "%";
    },
    top:function(){
      return this.content.top + "%";
    }

  },

  mounted() {

    load(this.content.url)

  },
};
</script>

<style scoped>

.audio{
  position: absolute;
  display: inline-block;
  z-index: 3;
}

.edit audio{
  pointer-events: none;
}

.image_content{

}


.placeholder img{
  pointer-events: none;

  max-width:200px;
  max-height: 143px;
  display:block;
  margin-left: auto;
  margin-right: auto
}


.image_placeholder{

  display:block;
}

.parent{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  background-color: #2ecc71;
  width:64px;
  height:64px;
  border-radius: 32px;
  border: 3px solid #27ae60;

}

.parent img{
  width:32px;

}

.image_actual{
  padding:20px;
  position:relative;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 25px;
  overflow: hidden;
  width:100%;
  height:100%;
  background-size:contain;
  background-position:center center;
  background-repeat: no-repeat;
  border-radius: 30px;
}

.cover{
  background-size:cover;
  border-radius: 0px;
  padding:0px;
}

.placeholder{
  pointer-events: all;
  padding:20px;
  position:relative;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  /*background-color: #3498db55;*/
  background-color: #599dcb54;
  border: 1px solid #dbdbdb;
  border-radius: 25px;
  overflow: hidden;
  width:100%;
  height:100%;
}

</style>
