<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="dragbar">
    <div @click="back" class="back">
      <span>&larr;</span>
    </div>

  <div type="TEXT" id="icon_TEXT" class="drg" _draggable="true" @mousedown="touch" @touchstart="touch" @touchend="end" @touchmove="move" @_dragstart="drag">
    <img class="drag_icon" src="/images/button-text5.png"/>
  </div>
  <div type="IMAGE" id="icon_IMAGE" class="drg" _draggable="true" @mousedown="touch" @touchstart="touch" @touchend="end" @touchmove="move" @_dragstart="drag">
    <img class="drag_icon" src="/images/button-image5.png"/>
  </div>
  <div type="VIDEO" id="icon_VIDEO" class="drg" _draggable="true" @mousedown="touch" @touchstart="touch" @touchend="end" @touchmove="move" @_dragstart="drag">
    <img class="drag_icon" src="/images/button-video5.png"/>
  </div>
  <div type="AUDIO" id="icon_AUDIO" class="drg" _draggable="true" @mousedown="touch" @touchstart="touch" @touchend="end" @touchmove="move" @_dragstart="drag">
    <img class="drag_icon" src="/images/button-audio5.png"/>
  </div>
</div>
</template>

<script>

export default {
  components: {},

  props: [],

  data() {
    return {
      type:"",
      content:null,
    };
  },

  watch: {

  },

  methods: {
    back(){
      this.$router.push({name:"Portal"})
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
    touch(ev){
      window.$events.$emit("highlight",null)
      ev.preventDefault();

      var path = ev.target;

      while(true){
        const type = path.getAttribute("type")

        if(type)
          {
            this.type = type
            window.$events.$emit("modeChanged", type)
            break
          }
          else if (path != null && path != document.body) {
            path = path.parentElement

          }
          else {
            break
          }

      }
      window.$events.$emit("drag_start")
      var child = document.getElementById("icon_"+this.type).cloneNode(true);
      window.$events.$emit("close_edit")
      var body = document.body;
      child.classList.add("float");
      this.child = child;
      this.child.classList.add("nope")

      body.appendChild(child)
    },
    end(ev){

      if(this.child){
        this.child.remove();
        this.child = null;
        this.content = null;
        this.$emit("drop", this.type);
      }
    },
    mmove(ev){
      console.log("MMOVE",ev)
    },
    move(ev){
      const x = ev.targetTouches[0].clientX;// - 24
      const y = ev.targetTouches[0].clientY;// - 24
      const xx = ev.targetTouches[0].clientX-36;
      const yy = ev.targetTouches[0].clientY-36;

      this.child.style.left =  xx  + "px";
      this.child.style.top =  yy + "px";

      this.$emit("drag",x,y, this.type, this.content)

    },
    drag(ev) {
      ev.dataTransfer.setData("text", ev.target.id);
    },
    start(content){
      if(this.child == null){
        this.type = content.type;
        this.content = content;

        var child = document.getElementById("icon_"+content.type).cloneNode(true);

        window.$events.$emit("close_edit")

        var body = document.body;
        child.classList.add("float");
        this.child = child;
        this.child.classList.add("nope")
        window.$events.$emit("drag_start")
        body.appendChild(child)
      }
    },
    drop(ev) {
      ev.preventDefault();
      var data = ev.dataTransfer.getData("text");
      ev.target.appendChild(document.getElementById(data));
    }
  },

  computed: {
    data() {

    },
  },

  mounted() {
    window.$events.$on("move_start",this.start);
    var self = this;
    document.body.addEventListener('mousemove', ev => {
      if(self.child){
        const x = ev.pageX
        const y = ev.y - 75
        self.child.style.left =  x - 32 + "px";
        self.child.style.top =  ev.pageY - 32 + "px";
        self.$emit("drag",x,y,self.type,self.content)
    }
    });

    document.body.addEventListener('mouseup', ev => {
        self.end()
    });
  },
};
</script>

<style scoped lang="scss">

  input:focus, textarea:focus, select:focus{
      outline: none;
  }


input{
  font-size:36px;
  border: 0px solid;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0!important;
  -webkit-appearance: none;
  appearance:None;
}

.dragbar {
  span {
    font-size: 2.25em;
    cursor: pointer;
  }
}

.dragbar .image{
  width:64px;
  height:64px;
}

.dragbar .title{
  width:200px;
  height:64px;
  display:flex;
}

.drag_icon{
    max-height: 48px;

}

.back{
  width:64px;
  height:64px;
  display:flex;
  justify-content: space-evenly;
  align-items: center;


  padding:4px 0px 0 4px;
  margin:4px;

}


.title{
  width:64px;
  height:64px;
  display:flex;
  justify-content: space-evenly;
  align-items: center;

  ;
  padding:4px 0px 0 4px;
  margin:4px;

}

.nope{
  pointer-events:none;
}

.dragbar{
  display: flex;
  flex-direction: row;
}

.float{
  position:absolute;
  z-index: 10;
}

.drg{
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  padding:4px 0px 0 4px;
  margin:4px;
  /*border:1px solid #AFAFAF;
  background-color: #3498db;
  border-radius: 5px;
  width:64px;
  height:64px;*/
  img {
    cursor: pointer;
  }
}

</style>
