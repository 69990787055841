<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>


<div class="parent" @click="click()">
  <div class="p-col-12 p-md-6 p-lg-3">
          <label for="msid">Standard Report</label>
          <select id="service-type" name="service-type">
            <option>Report Type</option>
            <option value="inspect">Devices Compilation- Alert/OK</option>
            <option value="install">List of Open Services Requests</option>
            <option value="inspect">Open Reports by Employee</option>
            <option value="install">Single Device</option>
            <option value="inspect">Closed Reports Compilation</option>
          </select>
        </div>

    <input id="my_input" class="hide" value = "DEFAULT"/>
</div>

<!--
<div ref="parent" class="paren">

  <button id="btn-start-recording">Start Recording</button>
  <button id="btn-stop-recording" disabled>Stop Recording</button>

  <hr>
<input id="videoFile" type="file" class="hidden" accept="video/*" capture="">
-->


<!--
  <input type="file" id="file_input"/>
  <p id="status">Please select a file</p>
  <img id="preview" src="/static/default.png" />

  <form method="POST" action="/submit_form/">
    <input type="hidden" id="avatar-url" name="avatar-url" value="/static/default.png">
    <input type="text" name="username" placeholder="Username">
    <input type="text" name="full-name" placeholder="Full name">
    <input type="submit" value="Update profile">
  </form>


<div class="third" >Save</div>
-->

</template>

<script>
import { temp, test } from "@/scripts/Authentication.js";





var video = null;

function captureCamera(callback) {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(function(camera) {
        console.log("Camera!", camera)
        callback(camera);
    }).catch(function(error) {
        //alert('Unable to capture your camera. Please check console logs.');
        console.error(error);
    });
}



var recorder; // globally accessible





export default {
  components: {},

  props: {},

  async mounted() {
    var self = this;


        var select = document.getElementById("service-type")
        var my_input = document.getElementById("my_input")
        select.onchange = function(val){
          console.log("Changed:", select)
          //my_input.value = select.value;
          my_input.value = select.options[select.selectedIndex].text;
          my_input.classList.remove("hide");
          //my_input.classList.add("show");
        };



    return;
    /*
    (function() {
      document.getElementById("file_input").onchange = function(){
        var files = document.getElementById("file_input").files;
        var file = files[0];
        if(!file){
          return alert("No file selected.");
        }
        self.getSignedRequest(file);
      };
    })();
*/
    video = document.querySelector('video');

    document.getElementById('btn-start-recording').onclick = function() {
        this.disabled = true;
        captureCamera(function(camera) {
            console.log("CAPTURE?")
            video.muted = true;
            video.volume = 0;
            video.srcObject = camera;

            recorder = RecordRTC(camera, {
                type: 'video',
                mimeType: 'video/webm\;codecs=vp8'
            });

            recorder.startRecording();

            // release camera on stopRecording
            recorder.camera = camera;

            document.getElementById('btn-stop-recording').disabled = false;
        });
    };

    document.getElementById('btn-stop-recording').onclick = function() {
        this.disabled = true;
        // get recorded blob


        recorder.stopRecording(self.stopRecordingCallback);


    };

  },
  data() {
    return {
      version:0,
      examples:[]
    };
  },

  watch: {


  },
  methods: {
    click:function(){
      console.log("Test:" ,temp)

      test()
    },
    stopRecordingCallback:function() {
        video.src = video.srcObject = null;
        video.muted = false;
        video.volume = 1;
        let blob = recorder.getBlob();
        console.log("Blob:", blob)
        video.src = URL.createObjectURL(blob);

        // generating a random file name
        var fileName = "video1.webm"

        // we need to upload "File" --- not "Blob"
        var fileObject = new File([blob], fileName, {
            type: 'video/webm'
        });

        this.getSignedRequest(fileObject)

        recorder.camera.stop();
        recorder.destroy();
        recorder = null;
    },
    getSignedRequest: async function(file){
      let ret = await $samsa.signS3.post(file.name,file.type);
      console.log(ret)
      ret = ret.data.signS3
      console.log("Uplaoding:", ret.data, ret.url)
      uploadFile(file, ret.data, ret.url);
      console.log("Done")
    },
    cklick: function(item){
      console.log(item)
      item.target.classList.add("right")
      item.target.classList.remove("left")

    },
    add: async function(){

      this.load()
    },
    load: async function(){
      var examples = await $samsa.getExamples()
      this.examples = examples.data
    }
  },

  computed: {
    value:function(){
      return temp.name;
    }
  },


};
</script>

<style scoped>

.hide{
  display: none;
}

.third{
  background-color: blue;
  width:33%;
  height:200px;
  display: inline-block;
  position: absolute;
  top:500px;
  left:500px;
}

.parent{
  width:100vw;
  height:100vh;
  background-color: #afafaf;
  position:relative;
}


.middl{
  background-color: green;
  height:25vh;
  width:100%;
  position:relative;

}

.chil{
transition: ease all .5s;
  background-color: red;
  height:20vh;
  width:30%;
  position:absolute;
  min-width: 400px;
  top:0px;
  right:0px;
  z-index: 10;
}

.left{
  right:0;
}


.right{
  right: 100%;
  top:300px;
  transform: translate(100%, 0);

}

.animate.right.move {

}


</style>
