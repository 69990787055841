<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="flex flex-col earth-background" id = "blurry">

    <div class="contain paywall">
      <transition name="fade" appear>
        <div class="header-section">
          <h1>CONFIRMATION</h1>
        </div>
      </transition>
      <div class="content">
        <h1>Thank you for your interest in Springmatter</h1>
        <p>
          We are just getting started and our site is undergoing changes everyday! If you are interested in trying out our site as a beta user, please contact
          our adminstrators at info@springmatter.com
        </p>
      </div>
    </div>

  </div>
</div>
</div>
</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";

export default {
  components: { Breadcrumbs, NavbarDash },

  props: [],

  data() {
    return {
      name:"paywall"
    };
  },

  watch: {},

  methods: {
  },

  computed: {},


  mounted() {},
};
</script>

<style>

</style>
