// Copyright 2019-2021 Michigan Aerospace Corporation
// All Rights Reserved
// Author: Chad Lewis, David Vasseliou, Leslie Borst

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Repositories from "../views/Repositories/Repositories.vue";
import Repo from "../views/Repositories/Repo.vue";
import CreateRepository from "../views/Repositories/CreateRepository.vue";
import Dashboard from "../views/Dashboard.vue";
import AddDataset from "@/views/Datasets/AddDataset.vue";
import AnnotateDataset from "@/views/Datasets/AnnotateDataset.vue";
import ConnectData from "@/views/Datasets/ConnectData.vue";
import Dataset from "@/views/Datasets/Dataset.vue";
import Playground from "@/views/Playground.vue";
import Dummy from "@/views/dummy_page.vue";
import About from "@/views/about.vue";
import Company from "@/views/company.vue";
import Athletes from "@/views/athletes.vue";
import AthletesNew from "@/views/athletes_new.vue";
import Teams from "@/views/teams.vue";
import TeamsAcada from "@/views/teams_acada.vue";
import Contact from "@/views/contact.vue";
import Acada from "@/views/acada/acada.vue";
import Careers from "@/views/careers.vue";
import FAQ from "@/views/faq.vue";
import Socials from "@/views/fb_insta.vue";
import Paywall from "@/views/paywall.vue";
import Privacy from "@/views/privacy.vue";
import Narrator from "@/views/Narrator/narrator.vue";
import View from "@/views/Narrator/View.vue";
import Terms from "@/views/terms.vue";
import Profile from "@/views/profile.vue";
import ProfileForm from "@/views/sandbox/profile_form.vue";
import EULA from "@/views/eula.vue";
import { verifyAuthenticated,signOut,user } from "@/scripts/Authentication.js";
//import { authGuard } from "@/auth/AuthGuard.js";
import store from "@/store/index.js";
import createAuth0Client from "@auth0/auth0-spa-js";
import DGreen from "@/views/dgreen.vue";
import HaloCodex from "@/views/HaloCodex.vue";
import PricingPanel from "@/views/sandbox/pricing_panel_project.vue";
import NarrativePortal from "@/views/Portal/narrative_portal.vue";
import OrgPortal from "@/views/Portal/org_portal.vue";
import NewOrg from "@/views/Portal/new_org.vue";
import Example from "@/views/Narrator/example.vue";
import ComponentTesting from "@/views/component-testing.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import ChangeLog from "@/views/ChangeLog.vue";
import Admin from "@/views/admin.vue";
import Examples from "@/views/sandbox/examples.vue"
import Conversation from "@/views/Conversation.vue";
import Survey from "@/views/Survey.vue"


Vue.use(VueRouter);

const authGuard = async (to, from, next) => {
  console.log("AUTH GUARD!!")
  await verifyAuthenticated()
    .then(async function(res){
      console.log("res: ", res)
      if(res != null){
        console.log("VERIFY AUTH:", $data.user)

        var behind_paywall = false;
        if(to.meta.behind_paywall){
          behind_paywall = true;
          console.log(res)
          var subscription_id = await $samsa.is_subscribed(res.username);
          console.log("PAYWALL: ",subscription_id.data,res )
          if(subscription_id.data == 0)
          {
            return next('/paywall')
          }

        }

        return next();
      }
      else{
        console.log("ROUTING TO SIGN - IN")
        return next("SignIn");
      }
    })
    .catch((res) => {
      console.log("ERROR REDIRECT")
      return next("SignIn");
    });
};

async function SignOut(to, from, next) {
  console.log("SIGNING OUT")
  signOut();
	return next("/");
}

const verifySignedOut = (to, from, next) => {
	verifyAuthenticated()
		.then((res) => {
			return next({
				name: "Dashboard",
				params: { username: res.data.email },
			});
		})
		.catch((res) => {
			return next();
		});
};

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: { show_header: true, show_footer: true },
	},
  {
		path: "/admin",
		name: "Admin",
		component: Admin,
		meta: { show_header: true, show_footer: true },
	},
  {
		path: "/examples",
		name: "Examples",
		component: Examples,
		meta: { show_header: false, show_footer: false },
	},
  {
		path: "/Conversation",
		name: "Conversation",
		component: Conversation,
		meta: { show_header: false, show_footer: false },
	},
	{
		path: "/Survey",
		name: "Survey",
		component: Survey,
		meta: { show_header: false, show_footer: false },
	},
  {
		path: "/narrator/:narrativeid",
		name: "EditNarrator",
		component: Narrator,
    props: true,
	},
  {
		path: "/view/:narrativeid",
		name: "View",
		component: View,
    props: true,
	},
  {
		path: "/createnarrative",
		name: "Narrator",
		component: Narrator,
    props: true,
	},
	{
		path: "/example",
		name: "Example",
		component: Example,
		meta: { show_footer: true },
		props: true,
	},
	{
		path: "/component-testing",
		name: "ComponentTesting",
		component: ComponentTesting,
		meta: { show_footer: true },
		props: true,
	},
	{
		path: "/portal",
		name: "Portal",
		component: NarrativePortal,
		meta: { show_footer: true, behind_paywall:true},
		beforeEnter: authGuard,
		props: true,
	},
	{
		path: "/portal/:org",
		name: "OrgPortal",
		component: OrgPortal,
		meta: { show_footer: true },
		beforeEnter: authGuard,
		props: true,
	},
	{
		path: "/newOrg",
		name: "NewOrg",
		component: NewOrg,
		meta: { show_footer: true },
		beforeEnter: authGuard,
		props: true,
	},
	{
		path: "/SignIn",
		name: "SignIn",
		component: SignIn,
		meta: { },
		props: true,
	},
	{
		path: "/resetPassword/:pwkey",
		name: "ResetPasswordWithKey",
		component: ResetPassword,
		meta: { },
		props: true,
	},
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { },
    props: true,
  },
	{
		path: "/SignUp",
		name: "SignUp",
		component: SignUp,
		meta: { },
		props: true,
	},
	{
		path: "/ChangeLog",
		name: "ChangeLog",
		component: ChangeLog,
		meta: { show_header: true },
		props: true,
	},
	{
		path: "/SignOut",
		name: "SignOut",
		component: Home,
		meta: { show_header: true, sign_out: true },
    beforeEnter: SignOut,
		props: true,
	},
	{
		path: "/dummy",
		name: "Dummy",
		component: Dummy,
	},
	{
		path: "/dgreen",
		name: "dgreen",
		component: DGreen,
		meta: { show_header: true, show_footer: true },
	},
	{
		path: "/halocodex",
		name: "Halo Codex",
		component: HaloCodex,
	},
	{
		path: "/about",
		name: "About",
		component: About,
		meta: { show_header: true, show_footer: true },
	},

	{
		path: "/faq",
		name: "FAQ",
		component: FAQ,
		meta: { show_footer: true },
	},
	{
		path: "/company",
		name: "Company",
		component: Company,
		meta: { show_header: true, header_class: "company" },
	},
	{
		path: "/athletes",
		name: "Athletes",
		component: Athletes,
		meta: { show_header: true, show_footer: true },
	},
	{
		path: "/athletes_new",
		name: "AthletesNew",
		component: AthletesNew,
		meta: { show_header: true, show_footer: true, scroll_arrow_class: "athletes-scroll", header_class: "athletes", main_class: "main-athletes" },
	},
	{
		path: "/acada",
		name: "Acada",
		component: Acada,
		meta: { show_header: true, show_footer: true, header_class: "acada", main_class: "main-acada" },
	},
	{
		path: "/teams",
		name: "Teams",
		component: Teams,
		meta: { show_header: true, show_footer: true },
	},
	{
		path: "/teams_acada",
		name: "TeamsAcada",
		component: TeamsAcada,
		meta: { show_header: true, show_footer: true, header_class: "acada", main_class: "teams-acada" },
	},
	{
		path: "/contact",
		name: "Contact",
		component: Contact,
		meta: { show_header: true, show_footer: true },
	},
	{
		path: "/careers",
		name: "Careers",
		component: Careers,
		meta: { show_header: true, show_footer: true },
	},
	{
		path: "/paywall",
		name: "Paywall",
		component: Paywall,
		meta: { show_header: true, show_footer: true },
	},
	{
		path: "/privacy",
		name: "Privacy",
		component: Privacy,
		meta: { show_header: true, show_footer: true },
	},
	{
		path: "/eula",
		name: "eula",
		component: EULA,
		meta: { show_header: true, show_footer: true },
	},
	{
		path: "/terms",
		name: "Terms",
		component: Terms,
		meta: { show_header: true, show_footer: true },
	},
	{
		path: "/profile",
		name: "Profile",
		component: Profile,
		meta: { show_header: true, show_footer: true, header_class: "profile" },
	},
	{
		path: "/ProfileRework",
		name: "Profile Page Rework",
		component: ProfileForm,
		meta: { show_header: true, show_footer: true },
	},
	{
		path: "/pricingpanel",
		name: "Pricing Panel",
		component: PricingPanel,
	},
	{
		path: "/:username",
		name: "Dashboard",
		component: Dashboard,
		beforeEnter: authGuard,
		props: true,
		meta: { show_header: true, show_footer: true },
	},
	{
		path: "/:username/repos",
		name: "Repos",
		component: Repositories,
		beforeEnter: authGuard,
		props: true,
	},

	{
		path: "/:username/repos/create",
		name: "CreateRepository",
		component: CreateRepository,
		beforeEnter: authGuard,
		props: true,
	},

	{
		path: "/:username/repos/:name",
		name: "Repo",
		component: Repo,
		beforeEnter: authGuard,
		props: true,
	},

	{
		path: "/:username/repos/:repoName/add-dataset",
		name: "AddDataset",
		component: AddDataset,
		beforeEnter: authGuard,
		props: true,
	},

	{
		path: "/:username/repos/:repoName/:datasetName/connect-data",
		name: "ConnectData",
		component: ConnectData,
		beforeEnter: authGuard,
		props: true,
	},

	{
		path: "/:username/repos/:repoName/:datasetName/annotate-data",
		name: "AnnotateData",
		component: AnnotateDataset,
		beforeEnter: authGuard,
		props: true,
	},

	{
		path: "/:username/repos/:repoName/:datasetName",
		name: "Dataset",
		component: Dataset,
		beforeEnter: authGuard,
		props: true,
	},

	{
		path: "/:username/playground",
		name: "Playground",
		component: Playground,
		beforeEnter: authGuard,
		props: true,
	},
	/*{
    path: "/dummy",
    name: "Test",
    component: Test,
  },*/
];

const router = new VueRouter({
	mode: "history",
	routes: routes,
	// linkActiveClass:
	//   'bg-gray-800 transition duration-150 ease-in-out router-link-exact-active',
});

export default router;
