<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div id="app" :class="$route.meta.main_class">

    <!-- <Nav class="mb-6" v-if='$auth.isAuthenticated' /> -->
    <nav-bar
      class="sticky top-0"
      style="z-index:2000"
      v-if="
        isAuthenticated & ($route.name != 'WatchNarrative') &&
          $route.name != 'Dashboard'
      "
    ></nav-bar>

      <div class="navbar-div" :class="$route.meta.header_class">
          <navbar-dash
        >
          </navbar-dash>
      </div>
      <error-modal v-if="errorsPresent" :message="errorMessage"></error-modal>
      <message-modal v-if="messageIsVisible" :messageData="messageData" />
      <!-- <button class="margin-4 p-3 bg-gray-300" @click="check">PRESS ME</button> -->
      <router-view v-on:error="showErrors" />
      <a href="#app" id="to-top" class="no-display" title="Scroll To Top"><img :class="$route.meta.scroll_arrow_class" src="/images/arrow.png" /></a>
    <foot></foot>
    <cookie-law>
  <div slot-scope="props">
    <p>This site uses cookies. For more info <router-link to="terms">Click here</router-link></p>
    <button name="accept-cookies" class="skew" @click="props.accept"><span>I accept</span></button>
    <button name="close-cookies" class="skew" @click="props.close"><span>Ignore</span></button>
  </div>

</cookie-law>
  </div>

</template>

<script>
console.log("APP.VUE")
import NavBar from "./components/NavBar.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "./components/NavbarDash.vue";
import Foot from "./components/Footer.vue";
import ErrorModal from "./components/ErrorModal.vue";
import MessageModal from './components/MessageModal.vue';
import CookieLaw from 'vue-cookie-law';
import { checkUser, getToken, EventBus } from "@/utils.js";
import { verifyAuthenticated, user } from "@/scripts/Authentication.js";

export default {
  name: "App",
  components: {
    NavBar,
    NavbarDash,
    Foot,
    ErrorModal,
    CookieLaw,
    MessageModal
  },

  methods: {
    showErrors(message) {
      this.errorMessage = JSON.parse(message).message;
      this.errorsPresent = true;
      setTimeout(() => {
        this.errorsPresent = false;
      }, 3000);
    },
  },

  data() {
    return {
      errorMessage: "",
      errorsPresent: false,
    };
  },

  computed: {
    isAuthenticated() {
      return this.$store.state.users.isAuthenticated;
    },
    messageIsVisible() {
      return this.$store.state.modals.visible;
    },
    messageData() {
      return this.$store.state.modals;
    },
    user() {
      return this.$store.state.users.user
    }
  },
  watch: {
     user(newVal) {
      if(newVal && this.$route.name === 'Home') {
         this.$router.push({
          name: "Dashboard",
          params: { username: newVal.username },
        });
      } else if(newVal === null) {

      }
    }
  },
  async beforeCreate() {
    EventBus.$on("error", this.showErrors); // listen to the global event bus
  },
};

// ===== Scroll to Top ====
window.onscroll = function() {myFunction(); navFunction()};

function myFunction() {
  if (document.body.scrollTop > 650 || document.documentElement.scrollTop > 650) {
    document.getElementById("to-top").className = "display";
  } else {
    document.getElementById("to-top").className = "no-display";
  }
}

// ===== Navigation Scroll ====

function navFunction() {
  if(document.getElementById("dashboard-navbar")) {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      document.getElementById("dashboard-navbar").className = "flex flex-wrap items-center justify-between p-5 bg-white scrolled";
    } else {
      document.getElementById("dashboard-navbar").className = "flex flex-wrap items-center justify-between p-5 bg-white";
    }
  }else {
    return
  }

}


</script>
