<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="flex flex-col earth-background" id = "blurry">

    <div class="contain contact">
      <transition name="fade" appear>
        <div class="header-section">
          <div class="header-content">
            <h1 class="hero">CONTACT US</h1>
            <p>Get in touch with us.<br />We'd love to hear from you.</p>
            <a href="#scroll-location" class="scroll-arrow">&#8595;</a>
          </div>
        </div>
      </transition>
      <div v-if="!sent" id="scroll-location" class="content">
        <div class="fl-col-group fl-node-5f17109620a89" data-node="5f17109620a89">
          <div class="fl-col-content fl-node-content info-copy">
            <!--<div class="fl-module fl-module-rich-text fl-node-5f1711f997580" data-node="5f1711f997580">
              <div class="fl-module-content fl-node-content">
                <div class="fl-rich-text">
                  <p><a href="tel:734-975-8777">(734) 975-8777 x100</a><br />
                  (734) 975-0239 fax</p>
                </div>
              </div>
            </div>-->
            <div class="fl-module fl-module-html fl-node-5f17121ea36ed" data-node="5f17121ea36ed">
              <div class="fl-module-content fl-node-content">
                <div class="fl-html">
                  <a href="mailto:info@springmatter.com" name="contact-page-icon-email" class="email-info"><span>info@springmatter.com</span></a>
                  <br><br></div>
                </div>
              </div>
            <div class="fl-module fl-module-html fl-node-5f1732be4a518" data-node="5f1732be4a518">
              <div class="fl-module-content fl-node-content">
                <div class="fl-html">
                  <div class="social-links ninzio-clearfix left">
                    <a class="facebook" href="http://facebook.com/springmatter" title="facebook" target="_blank" name="contact-page-facebook">
                      <i class="fab fa-facebook-square"></i>
                    </a>
                    <a class="twitter" href="https://twitter.com/aTellingStory" title="twitter" target="_blank" name="contact-page-twitter">
                      <i class="fab fa-twitter-square"></i>
                    </a>
                    <a class="linkedin" href="https://www.linkedin.com/company/springmatter" title="linkedin" target="_blank" name="contact-page-linkedin">
                      <i class="fab fa-linkedin"></i>
                    </a>
                    <a class="email" href="mailto:info@springmatter.com" title="email" target="_blank" name="contact-page-icon-email">
                      <i class="fas fa-envelope-square"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="fl-col fl-node-5f17109620af6 form-container" data-node="5f17109620af6">
            <div class="fl-col-content fl-node-content">
              <div class="fl-module fl-module-contact-form fl-node-5f17109f37f03" data-node="5f17109f37f03">
                <div class="fl-module-content fl-node-content">
                  <form class="fl-contact-form" >
                    <input type="hidden" name="fl-layout-id" value="508" />
                    <div class="fl-input-group fl-name">
                      <label for="fl-name">Name</label>
                      <span class="fl-contact-error">Please enter your name.</span>
                      <input type="text" id="fl-name" name="fl-name" value="" placeholder="Your name" v-model="name" />
                    </div>
                    <div class="fl-input-group fl-subject">
                      <label for="fl-subject">Subject</label>
                      <span class="fl-contact-error">Please enter a subject.</span>
                      <input type="text" id="fl-subject" name="fl-subject" value="" placeholder="Subject" v-model="subject"/>
                    </div>
                    <div class="fl-input-group fl-email">
                      <label for="fl-email">Email</label>
                      <span class="fl-contact-error">Please enter a valid email.</span>
                      <input type="email" id="fl-email" name="fl-email" value="" placeholder="Your email" v-model="email"/>
                    </div>
                    <div class="fl-input-group fl-message">
                      <label for="fl-message">Your Message</label>
                      <span class="fl-contact-error">Please enter a message.</span>
                      <textarea id="fl-message" name="fl-message" placeholder="Your message" v-model="message"></textarea>
                    </div>
                    <div @click="send" class="fl-button-wrap fl-button-width-auto fl-button-left">
                      <a href="#" target="_self" class="button fl-button" role="button" name="contact-form-send">
                        <span class="fl-button-text">SEND</span>
                      </a>
                    </div>
                    <span class="fl-success-none" style="display:none;">Message Sent!</span>
                    <span class="fl-send-error" style="display:none;">Message failed. Please try again.</span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        <div class="fl-col fl-node-5f17109620af8 fl-col-small" data-node="5f17109620af8">
      </div>
    </div>


  </div>
  <div v-else>
    <div class="content contact thank-you">
      <h2>Thank you</h2>
      <p>You sent your message!</p>
    </div>
  </div>
</div>
</div>
</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";

export default {
  components: { Breadcrumbs, NavbarDash },

  props: [],

  data() {
    return {
      name:"",
      email:"",
      subject:"",
      message:"",
      sent: false
    };
  },

  watch: {},

  methods: {
    send(){
      var self = this;

      $samsa.contact.post(this.name, this.subject, this.email, this.message)
      .then((res) => {

          self.sent = true;
      })
    }
  },

  computed: {},


  mounted() {},
};
</script>

<style>

</style>
