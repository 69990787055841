<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="m-12 mt-2 flex flex-col w-auto">
    <div
      class="w-full flex flex-row justify-between p-4 px-0 border-b pb-6
      border-gray-300"
    >
      <breadcrumbs :links="links"></breadcrumbs>
    </div>

    <div class="flex flex-row items-start">
      <steps :steps="steps" :current-step="currentStep"></steps>

      <dataset-form
        :repo="repo"
        :dataset="dataset"
        v-on:created="datasetCreated"
      ></dataset-form>
    </div>
  </div>
</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Steps from "@/components/Steps.vue";
import DatasetForm from "@/views/Datasets/DatasetForm.vue";
import UploadDataset from "@/views/Datasets/UploadDataset.vue";
import AnnotateDataset from "@/views/Datasets/AnnotateDataset.vue";
import { reportError } from "@/utils.js";

export default {
  components: {
    Breadcrumbs,
    Steps,
    DatasetForm,
    UploadDataset,
    AnnotateDataset,
  },

  props: ["username", "repoName", "datasetName"],

  data() {
    return {
      steps: [
        {
          title: "Dataset details",
          description: "Describe what this dataset is all about.",
        },
        {
          title: "Connect data",
          description: "Upload files or link to streaming source.",
        },
        {
          title: "Annotate dataset",
          description: "Describe data columns, define units, etc.",
        },
      ],
      currentStep: 0,
    };
  },

  watch: {},

  methods: {
    datasetCreated() {
      this.$router.push({
        name: "ConnectData",
        params: {
          username: this.username,
          repoName: this.repoName,
          datasetName: this.dataset.name,
        },
      });
    },
  },

  computed: {
    dataset() {
      return this.$store.state.datasets.current;
    },

    links() {
      return [
        {
          name: this.$store.getters['users/nickname'] + "'s repositories",
          route: {
            name: "Repos",
            params: { username: this.username },
          },
        },
        {
          name: this.repoName,
          route: {
            name: "Repo",
            params: { username: this.username, name: this.name },
          },
        },
        {
          name: "create dataset",
          route: {
            name: "AddDataset",
            params: { username: this.username, name: this.name },
          },
        },
      ];
    },

    repo() {
      return this.$store.state.repos.current;
    },

    // datasetId() {
    //   return this.$route.query.datasetId;
    // },
  },

  mounted() {
    // GET THE REPO
    let details = { username: this.username, repoName: this.repoName };
    this.$store.dispatch("repos/getRepo", details);
  },
};
</script>

<style></style>
