<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
	<div class="flex flex-col earth-background">
		<div class="form parent">
			<div class="left_panel">
				<ul>
					<li>About</li>
					<li>Location</li>
					<li>Change Subscription</li>
					<li>option four</li>
				</ul>
			</div>

			<div class="right_panel">
				<edit-profile> </edit-profile>
			</div>
		</div>
	</div>
</template>

<script>
	import EditProfile from "@/views/sandbox/components/edit_profile.vue";
	import Breadcrumbs from "@/components/Breadcrumbs.vue";
	import NavbarDash from "@/components/NavbarDash.vue";

	export default {
		components: { NavbarDash, Breadcrumbs, EditProfile },

		props: {},

		async mounted() {},
		data() {
			return {};
		},

		watch: {},
		methods: {},

		computed: {},
	};
</script>

<style>
	.right_panel {
		width: 100%;
		min-width: 30%;

	}

	.left_panel {
		width: 200px;
	}

	.left_panel li {
		margin: 10px;
		width: 200px;
		color: #767676;
		text-decoration: underline;
		cursor: pointer;
	}

	li:hover {
		color: #111;
	}

	.parent {
		display: flex;

	}

	@media screen and (max-width: 881px) {
		.parent {
			display: flex;
			flex-wrap: wrap;
		}
	}

</style>
