// Copyright 2019-2021 Michigan Aerospace Corporation
// All Rights Reserved
// Author: Chad Lewis, David Vasseliou, Leslie Borst

const initialState = {
  message: '',
  type: '', // success, warning, error
  visible: false
}
const getters = {}
const mutations = {
  setModal(state, { message, type, visible }) {
    state.message = message,
    state.type = type,
    state.visible = visible
  },
  clearModal(state) {
    state.message = ''
    state.type = ''
    state.visible = false
  }
}
const actions = {
  showModal(context, { message, type, duration = 5000 }) {
    context.commit('setModal', { message, type, visible: true })
    setTimeout(() => {
      context.dispatch('hideModal', {})
    }, duration)
  },
  showSuccess(context, { message, duration }) {
    context.dispatch('showModal', { message, duration, type: 'success' })
  },
  showError(context, { message, duration }) {
    context.dispatch('showModal', { message, duration, type: 'error' })
  },
  showWarning(context, { message, duration }) {
    context.dispatch('showModal', { message, duration, type: 'warning' })
  },
  hideModal(context) {
    context.commit('clearModal')
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
