<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
<div class="editor">
  <div class="search">
    <input class="search_input" placeholder="Search"/>
    <img class="search_image" src="/images/search.png"/>
  </div>
  <div class="or">
    <p><span>________________</span>OR<span>________________</span></p>
  </div>
  
  <div class="placeholder" @click="click">
    <div class="center">
      <div v-if="uploadStart">
        <div class="progress-text">Please do not close the sidebar while uploading.</div>
        <div id="progressbar">
          <div :style="'width:' + progress + '%;'"></div>
        </div>
        <div class="progress-text">{{progress}}%</div>
      </div>
      <div v-else>
        <img ref="img" style="margin-bottom: 20px" src="/images/button-image5.png" />
        <div class="text">
          Drag an Image to Upload or Click Here
        </div>
      </div>
    </div>
    <input @change="filesChanged" ref="file_input" class="file_input" type="file"/>
  </div>
  <div class="delete" @click="delete_element">Delete</div>
</div>

</template>

<script>
import SettingsIcon from "@/views/Narrator/SettingsIcon.vue"
import { APIPREFIX } from "@/api.js";


export default {
  components: {},

  props: ["content"],

  data() {
    return {
      text:"",
      files:[],
      progress: 0,
      uploadStart: false
    };
  },

  watch: {
    files(after,before){
      console.log("I HAVE FILES", after)
    }
  },

  methods: {
    delete_element(){
      $events.$emit("delete", this.content);
    },
    click(){
      this.$refs.file_input.click()
    },
    filesChanged(ev){
      console.log(this.$refs.file_input.files)
      console.log(ev)
      if(this.$refs.file_input.files.length>0)
        this.upload(this.$refs.file_input.files[0])
    },
    async upload(file){

      let ret = await $samsa.sign_s3(file.name,file.type);
      this.uploadFile(file, ret.data, ret.url);


      /*
      var self = this;
      var url = APIPREFIX + "/upload" //"http://localhost:5000/upload"
      console.log("Uploading to url:", url)
      var formData = new FormData();
      formData.append("file", file);
      axios.post(url, formData, { headers: {'Content-Type': 'multipart/form-data'}}).then(function(ret){
        console.log("RETURNED: ", ret.data.url)
        self.$refs.img.src = ret.data.url;
        self.content.url = ret.data.url;
        window.$events.$emit("image_added", self.content.url)
        window.$events.$emit("close_edit")
      });
      */

    },
    uploadFile(file, s3Data, url){
      var self = this;
      var xhr = new XMLHttpRequest();
      xhr.open("POST", s3Data.url);

      var postData = new FormData();
      for(var key in s3Data.fields){
        postData.append(key, s3Data.fields[key]);
      }
      postData.append('file', file);
      
      xhr.upload.onprogress = function(evt) {
        self.uploadStart = true;

        if(evt.lengthComputable) {
          self.progress = Math.floor((evt.loaded / evt.total) * 100);
        }
      }
      xhr.upload.onloadend = function() {
        self.uploadStart = false;
      }
      xhr.onreadystatechange = function() {
        if(xhr.readyState === 4){
          if(xhr.status === 200 || xhr.status === 204){
            self.content.url = url;
            window.$events.$emit("image_added", url)
            window.$events.$emit("close_edit")
          }
          else{
            alert("Could not upload file.");
          }
       }
      };
      xhr.send(postData);
    }
  },

  computed: {
    
  },

  mounted() {

  },
};
</script>

<style scoped lang="scss">

.delete{
  display: inline-block;
  text-align: center;
  line-height: 1em;
  font-size: 1em;
  width: auto;
  height: auto;
  margin: 20px;
  background-color: #d20000;
  color: #ffffff;
  border: 0;
  border-radius: 5px;
  padding: 1em 4em;
  transition: all .3s ease-in;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 1em 0;
  }
  &:hover{
    background-color: #fff;
    color: #d20000;
    cursor: pointer;
    transition: all .3s ease-in;
  }
}

.search{
  display:flex;
  flex-direction: row;
}

.search_image{
  height:40px;
  margin-left: 5px;
  background-color: white;
  border-radius: 10px;
}

.search_input{
  width:100%;
  margin-bottom: 5px;
  border-radius: 10px;
}

.file_input{
  display: none;
}

.text{
  color:white;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.editor{
  width:100%;
  height:100%;
  padding: 20px;
  text-align: center;
  span {
    font-size: .5em;
    vertical-align: super;
    padding: 0 5px;
  }
  .or {
    margin-top: 1em;
  }
}

img{
  pointer-events: none;
  max-width:200px;
  max-height: 143px;
  display:block;
  margin-left: auto;
  margin-right: auto
}

.center{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;

  #progressbar {
      background-color: $white;
      border: 1px solid $black;
      border-radius: 13px;
      padding: 3px;
    }
      
    #progressbar > div {
      background-color: $blue;
      height: 20px;
      border-radius: 10px;
    }
    .progress-text {
      font-weight: 600; 
      font-size: 18px;
      text-align: center;
    }

}

.placeholder{
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  overflow: hidden;
  width: 100%;
  img {
    width: 20%;
  }
}



</style>
