<template>
  <div class="input-container item">
    <label :for="id" class="input-contianer__label">
        <span>{{ labelText }}</span>
    </label>

    <select 
      :id="id" 
      @input="updateText($event.target.value)"
       class="input-contianer__selection">
      <option 
        v-for="(option, index) in options" 
        :key="index" 
        :value="option.value"
        >{{ option.option }}</option>
    </select>
    <i class="fas fa-chevron-down"></i>
</div>


</template>

<script>


export default {
  components: {

  },
  props: ["labelText", "id", "options"],

  data() {
    return{
      selectedItem: ""
    }
  },

  watch: {

  },

  methods: {
    updateText(text) {
      console.log(text);
      this.selectedItem = text;
      this.$emit('input', this.selectedItem);
    }
  },

  computed: {

  },

  mounted() {

  },
};

</script>

<style scoped lang="scss">
.input-container {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    position: relative;
    label {
      height: 40px;
    }
    select {
      border: 1px solid;
      border-color: #ccc #e0e0e0 #e0e0e0 #ccc;
      padding: 5px 10px;
      border-radius: 5px;
      appearance: none;
      background: $blue;
      color: $white;
      outline: 0;
      box-shadow: none !important;
      height: 40px;
    }
    .fa-chevron-down {
      position: absolute;
      right: 10px;
      bottom: 1.5vh;
      color: #fff;
    }
}


</style>
