<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <main class="max-w-lg mx-auto ml-12 pt-4 pb-12 px-4 lg:pb-16">
    <div class="space-y-6">
      <div class="space-y-1">
        <h1 class="text-lg leading-6 font-medium text-gray-900">
          Upload dataset files to
          <span class="text-blue-500" v-if="dataset"> {{ dataset.name }}</span>
        </h1>
        <p class="text-sm leading-5 text-gray-500">
        Now let's upload an example file for this dataset. Each dataset
        consists of a homogeneous collection of files — each file must contain
        the same sort of information.
        </p>
      </div>
    </div>
    <div class="flex justify-start">
      <span class="ml-0 mt-8 inline-flex rounded-md shadow-sm">
        <button
          @click="uploadFiles"
          class="inline-flex justify-center py-2 px-4 border border-transparent
          text-sm leading-5 font-medium rounded-md text-white bg-light-blue-500
          hover:bg-light-blue-400 focus:outline-none
          focus:border-light-blue-600 focus:shadow-outline-blue
          active:bg-light-blue-600 transition duration-150 ease-in-out"
        >
          Upload files
        </button>
      </span>
    </div>
  </main>
</template>

<script>
// import Component from "../component_location"
import {openFilePicker, reportError, EventBus} from '@/utils.js';

export default {
  components: {},

  props: ['dataset'],

  data() {
    return {
      uploading: false,
    };
  },

  watch: {},

  methods: {
    uploadFiles() {
      this.uploading = true;
      const client = openFilePicker();
      var options = {
        accept: ['text/*', 'image/*', 'video/*'],
        onUploadDone: this.filesUploaded,
        onCancel: e => {
          this.uploading = false;
        },
        maxFiles: 1,
        transformations: {crop: false, circle: false, rotate: false},
      };
      client.picker(options).open();
    },

    filesUploaded(fileInfo) {
      var filesUploaded = fileInfo.filesUploaded;
      // Remove the originalFile embedded document business — redundant information.
      filesUploaded.forEach(file => {
        delete file.originalFile;
      });

      let details = {datasetId: this.datasetId, file: filesUploaded[0]}
      this.$store.dispatch('datasets/addInitialFile', details)
    },

    complete() {
      this.$emit('uploaded')
    }
  },

  computed: {
    datasetId() {
      return this.dataset.Oid
    },
  },

  mounted() {
    // Listen to EventBus for dataset-created event.
    EventBus.$on("upload-complete", this.complete);
  },
};
</script>

<style></style>
