<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
    <div ref="parent">
    <!--<dashboard ref="mydashboard" :uppy="uppy" :plugins="['Webcam']" ></dashboard>-->

    </div>
</template>

<script>


    export default {
        name: 'App',
        components: {

        },
        data () {
            return {}
        },

        mounted() {


        },
        computed: {
            uppy: function(){
                console.log("UPPY COMPUTED")

            }

        },
        beforeDestroy () {
            //this.uppy.close()
        }

    }

</script>

<style lang="scss">


</style>
