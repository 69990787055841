<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div ref="background" @mousemove="mousemove" class="background">
    <svg @click="click" id="graph" width="100px" height="100px" style="position:absolute;top:100px; z-index:10" :style="{top:y}">

    <!-- pattern -->
    <defs>
      <pattern id="background" x="0px" y="0px" height="100%" width="100%"
               viewBox="0 0 100 100">
        <image x="0" y="0" width="100px" height="100px" xlink:href="/images/background.png"></image>
      </pattern>
    </defs>


    <circle id="sd" class="medium" cx="40px" cy="50px" r="30px"  stroke="#3498db" stroke-width="5px" />
    <circle id="sd" class="medium" cx="40px" cy="50px" r="25px" fill="url(#background)" stroke-width="1px" stroke="#3498db" />
    <line id="sd" class="medium" x1="0" y1="50px" x2="10px" y2="50px" stroke="#3498db" stroke-width="5px" />
  </svg>
  </div>
</template>

<script>

export default {
  components: {},

  props: ['type','scroll'],

  data() {
    return {
      y:0
    };
  },

  watch: {

  },

  methods: {
    click(ev){
      ev.preventDefault();
      window.$events.$emit("add_background",this.y)
    },
    mousemove(ev){

    }
  },

  computed: {
    data() {

    },
  },

  mounted() {
    var self = this;
    document.body.onmousemove = function(ev){
      if(ev.clientY < 100){

      }
      else
      {
        self.y = ev.clientY + self.scroll - 125;
      }
    }
  },
};
</script>

<style scoped>


.background-actual
{
  position:absolute;
  top:0;
  height:100%;
  width:100%;
  z-index: 2;
}

.background{
  pointer-events:all;
  position:absolute;
  top:0;
  height:100%;
  width:25px;
  z-index: 2;
}

svg{
  z-index: 2;
  pointer-events: click;
  fill:white;
}

svg:hover{
  fill:#AFAFAF;
}


</style>
