<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>

  <div id="image" class="parent" d>
    <!--<settings-icon @edit="edit"></settings-icon>-->
    <div @click="click" class="placeholder" v-if="content.url==null || content.url==''">
      <img  class="image_placeholder" src="/images/button-image5.png"></img>
    </div>
    <div v-else-if="content.position!='m'" class="image_actual" :style="{backgroundImage:'url(' + content.url+ ')'}"></div>
    <div v-else class="image_actual cover" :style="{backgroundImage:'url(' + content.url+ ')'}"></div>
  </div>

</template>

<script>


import SettingsIcon from "@/views/Narrator/SettingsIcon.vue"
export default {
  components: {SettingsIcon},

  props: {content:{default:null},view:{default:false}},

  data() {
    return {
      text:"Hello World. This is pretty Cool. With <p> longer text will it get shorter and shorter"
    };
  },

  watch: {

  },

  methods: {
    click(){
      console.log("Image Click!")
    },
    edit(){
      window.$events.$emit("edit",this.content)
    },
  },

  computed: {


  },

  mounted() {


  },
};
</script>

<style scoped>

.image_content{

}


.placeholder img{
  pointer-events: none;
  transition: ease all .5s;
  max-width:200px;
  max-height: 143px;
  display:block;
  margin-left: auto;
  margin-right: auto
}


.image_placeholder{

  display:block;
}

.parent{
  position:absolute;
  pointer-events: all;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
}

.image_actual{
  padding:20px;
  position:relative;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 25px;
  overflow: hidden;
  width:100%;
  height:100%;
  background-size:contain;
  background-position:center center;
  background-repeat: no-repeat;
  border-radius: 30px;
}

.cover{
  background-size:cover;
  border-radius: 0px;
  padding:0px;
}

.placeholder{
  pointer-events: all;
  padding:20px;
  position:relative;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  /*background-color: #3498db55;*/
  background-color: #599dcb54;
  border: 1px solid #dbdbdb;
  border-radius: 25px;
  overflow: hidden;
  width:100%;
  height:100%;
}

</style>
