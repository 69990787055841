<template>
  <div class="fixed z-10 inset-0 overflow-y-autos" v-if="openWarning">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20
      text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
      >&#8203;
      <div
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4
        text-left overflow-hidden shadow-xl transform transition-all sm:my-8
        sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12
            w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <!-- Heroicon name: exclamation -->
            <svg
              class="h-6 w-6 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667
                1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77
                1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              {{ title }}
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                {{ body }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <c-button size='small' :loading="loading" variant='danger' @click="$emit('proceed')"> {{ buttonText }} </c-button>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <c-button variant='secondary' @click="$emit('cancel')"> Cancel </c-button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from './CButton.vue';

export default {
  components: {
    CButton,
  },

  props: {
    openWarning: { type: Boolean, default: false },
    title: { type: String, default: "Careful!" },
    body: {
      type: String,
      default: "Are you sure you want to do this? It cannot be undone.",
    },
    buttonText: { type: String, default: "Delete" },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {};
  },

  watch: {},

  methods: {

  },

  computed: {},

  mounted() {},
};
</script>

<style></style>
