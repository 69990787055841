<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="flex flex-col home athletes" id = "blurry">

    <div id="header-video">
      <div id="overlay"></div>
      <video autoplay muted loop id="myVideo" poster="/images/a-poster.jpg">
        <source src="/images/Football-Intro.mp4" type="video/mp4">
        <source src="/images/Football-Intro.webm" type="video/webm">
        <source src="/images/Football-Intro.ogv" type="video/ogv">
        Your browser does not support HTML5 video.
      </video>
      <div class="inner-padding">
        <div class="header-content">
          <h1>Athletes' Stories</h1>
          <p>Competition goes beyond the field or court to get to the next level. If you aren’t seen, how do you get noticed? Your story shows recruiters and coaches more than what they can see from your stats.</p>
          <router-link to="/portal" class="button red" name="athletes-get-started">GET STARTED</router-link>
        </div>
      </div>
      <div class="header-scroll"><a href="#athletes-hero-new"><img src="/images/arrow.png" /></a></div>
    </div>

    <div id="athletes-hero-new" class="version two">
      <h2>Give Them More</h2>
      <p>Be more than your stats. Share your story and create a better athletic resume for recruiters. Show who you are and what you can do.</p>
      <router-link to="/portal" class="button red" name="athletes-watch">WATCH IT</router-link>
    </div>

    <div id="features" class="newer">
      <div class="inner-features">
        <h2>Features</h2>
        <p>Easily build your story from your phone or mobile device with features designed specifically for athletes and parents to give coaches what they are looking for.</p>
        <div class="left-features">
          <div class="feature-section">
            <img class="m_feature" src="/images/media2.jpg" />
            <div class="top-title">
              <h4>Media-Rich Stories</h4>
              <p>Make your story come to life with pictures, video, audio, text and more!</p>
            </div>
            <img src="/images/media2.jpg" />
          </div>
          <div class="feature-section">
            <img class="m_feature" src="/images/upload2-bl.jpg" />
            <div class="top-title">
              <h4><nobr>Upload or Embed</nobr></h4>
              <p>Upload your media from mobile and desktop or embed from other recruiting sites.</p>
            </div>
            <img src="/images/upload2-bl.jpg" />
          </div>
          <div class="feature-section">
            <img class="m_feature" src="/images/data2.jpg" />
            <div class="top-title">
              <h4>Data and Visualization</h4>
              <p>Use our data management tools to connect your stats and performance to a compelling story.</p>
            </div>
            <img src="/images/data2.jpg" />
          </div>
          <div class="feature-section">
            <img class="m_feature" src="/images/analytics.jpg" />
            <div class="top-title">
              <h4>Coach and Recruiter Analytics</h4>
              <p>Use our analytics toolkit to choose the athletes’ stories which are the best fit with your program..</p>
            </div>
            <img src="/images/analytics.jpg" />
          </div>
        </div>
        <img class="phone-image" src="images/iphone.png" />
        <div class="right-features">
          <div class="feature-section">
            <img src="/images/text2-bl.jpg" />
            <div class="top-title">
              <h4><nobr>Story Builder Tools</nobr></h4>
              <p>Easily build your story with our pre-made templates and prompt questions which can help you tell a better story.</p>
            </div>
          </div>
          <div class="feature-section">
            <img src="/images/editing2-bl.jpg" />
            <div class="top-title">
              <h4>Easy Editing</h4>
              <p>Go back and add or edit any element from any time in your story.</p>
            </div>
          </div>
          <div class="feature-section">
            <img src="/images/communicate2-bl.jpg" />
            <div class="top-title">
              <h4>Communicate</h4>
              <p>Connect with coaches and recruiters directly through our database.</p>
            </div>
          </div>
          <div class="feature-section">
            <img src="/images/share2-bl.jpg" />
            <div class="top-title">
              <h4>Share</h4>
              <p>Easily share your story via social media, email, or your custom URL.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="bottom-section">
      <!--<div class="inner">
        <h2>Testimonials</h2>
        <p>What are recruiters and coaches saying about Athletes Stories<sub>&trade;</sub></p>
      </div>
      <carousel></carousel>-->
      <div class="home-form-content">
        <h2>Got Questions?</h2>
        <p>Don’t be shy. We are here to answer your questions 24/7.</p>
        <div class="fl-col fl-node-5f17109620af6 form-container home" data-node="5f17109620af6">
          <div class="fl-col-content fl-node-content">
            <div class="fl-module fl-module-contact-form fl-node-5f17109f37f03" data-node="5f17109f37f03">
              <div class="fl-module-content fl-node-content">
                <form class="fl-contact-form" >
                  <input type="hidden" name="fl-layout-id" value="508" />
                  <div class="fl-input-group fl-name">
                    <label for="fl-name">Name</label>
                    <span class="fl-contact-error">Your Name</span>
                    <input type="text" id="fl-name" name="fl-name" value="" placeholder="Your name" v-model="name" />
                  </div>
                  <div class="fl-input-group fl-email">
                    <label for="fl-email">Email</label>
                    <span class="fl-contact-error">Your Email</span>
                    <input type="email" id="fl-email" name="fl-email" value="" placeholder="Your email" v-model="email"/>
                  </div>
                  <div class="fl-input-group fl-subject">
                    <label for="fl-subject">Subject</label>
                    <span class="fl-contact-error">Subject</span>
                    <input type="text" id="fl-subject" name="fl-subject" value="" placeholder="Subject" v-model="subject"/>
                  </div>
                  <div class="fl-input-group fl-message">
                    <label for="fl-message">Message</label>
                    <span class="fl-contact-error">Message</span>
                    <textarea id="fl-message" name="fl-message" placeholder="Your message" v-model="message"></textarea>
                  </div>
                  <div @click="send" class="fl-button-wrap fl-button-width-auto fl-button-left">
                    <a href="#" target="_self" class="button fl-button" role="button" name="contact-form-send">
                      <span class="fl-button-text">SEND</span>
                    </a>
                  </div>
                  <span class="fl-success-none" style="display:none;">Message Sent!</span>
                  <span class="fl-send-error" style="display:none;">Message failed. Please try again.</span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";
import Carousel from "@/components/carousel.vue";
import Vue from 'vue';


export default {
  components: { Breadcrumbs, NavbarDash, Carousel },

  props: ["username"],

  data() {
    return {
      html: "",
      monitorImage: require("../assets/images/meaningful-section/Image.png"),
      selected: 'images',
      slideIndex: 1,
      active: false,
    };
  },

  watch: {},

  methods: {

    getImage(type){
      if (type == 'images') {
        this.monitorImage = require("../assets/images/meaningful-section/Image.png");
        this.selected = 'images';
      }
      else if (type == 'videos') {
        this.monitorImage = require("../assets/images/meaningful-section/Video.png");
        this.selected = 'videos';
      }
      else if (type == 'text') {
        this.monitorImage = require("../assets/images/meaningful-section/Text.png");
        this.selected = 'text';
      }
      else if (type == 'data') {
        this.monitorImage = require("../assets/images/meaningful-section/Data.png");
        this.selected = 'data';
      }
      else if (type == 'audio') {
        this.monitorImage = require("../assets/images/meaningful-section/Audio.png");
        this.selected = 'audio';
      }
    },

  getStarted() {

    if (this.isSignedIn()) {
      this.$router.push({
          name: "Narratives",
          params: { username: this.$store.state.users.user.username },
        });
    }
    else {
      this.login("login");
    }
  },
  isSignedIn() {
    return this.$store.state.users.user.username != undefined;
  },

  login(prompt) {
      this.$auth.loginWithRedirect({ prompt });
    },
  showSlides() {
    this.active = !this.active;
  },
  },
  computed: {
    xml() {
      return this.$markdown.render(this.html);
    },
  },

  mounted() {},
};
</script>

<style scoped >
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.flexbox {
  display: -webkit-flex;
  display: none;
  margin-bottom: 8vh;
}
.active, .dot:hover {
  background-color: green;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  animation-name: fade;
  animation-duration: 2s;
}

@-webkit-keyframes fade {
  from {opacity: .1}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .1}
  to {opacity: 1}
}

.flexbox .col {
  flex: 1;
}
/*.earth-background {
  background: repeating-linear-gradient(#2c3e50, rgba(0, 0, 0, 0.75) 70%, #2c3e50, rgba(0, 0, 0, 0.75) 60%) , url("/assets/images/young-diverse.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}*/

[class^="icon"] {
  width: 5vh;
  height: 5vh;
  position: relative;
  top: 1.5vh;
  margin-right: 1vh;
  fill: #fff;
}

nav {
  float: left;
  width: 100%;
  text-align: center;
  height: 10vh;
  padding: 1vh;
}
.box {
   display: flex;
   align-items:center;
   max-width: 45%;
   padding: 10%;

}
.footer-box {
  display: flex;
  align-items:center;


}
nav a{
    color: #282828;
    font-weight: bold;
    padding: 30px;
    text-align: center;

}
.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 4vh;

}
.third-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: white;
  padding: 0vh  0vh 14vh 0vh ;

}
.second-row {
  padding: 10vh;
  background-color: white;
  text-align: center;
}

.column {
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.column-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex:1;
}
.hero {
  margin-top: 20vh;
  max-width: 90%;
  min-width: 70%;
}

.logow {
  display: block;
  margin: auto;
  max-width: 50%;

}
.monitor {
  display: block;
  max-width: 90%;
}
.footer-icon {
  max-width: 10%
}
a:active {
  color: red;
  background-color: transparent;
}
a:hover {
  color: green;
  background-color: transparent;
}
.large {
  font-size:97px;
  font-family: "Poppins";
  text-align: left;
  color: white;
  line-height: 102px;
  font-weight: 600;

}
.medium {
  font-size:4vh;
  font-family: "Poppins";
  text-align: left;
  line-height: 4vh;
  font-weight: bold;


}
.small {
  font-size:2.1vh;
  font-family: "Poppins";
  text-align: left;
  line-height: 2.5vh;
  font-weight: 500;

}

.paragraph {
  font-size:24px;
  color: white;
  font-family: "Poppins";
  font-weight: 300;
  text-align: left;
  line-height: 33px;
  margin-top: 3vh;
}

.text-column {
   margin-left: 20%;
   margin-top: 11vh;
   word-break:break-all;

}


.title {
  font-family: "Poppins";
  color: black;
  font-size:5vh;
  z-index : 0;
}
.main-title {
  font-size:8vh;
  font-weight: bold;
  font-family: "Poppins";
  color: black;
}
.text
{
  display: inline-block;
}
 .button {
  background-color: #47aa42;
  border: none;
  color: white;
  padding: 2vh 3vh 2vh 3vh;
  text-decoration: none;
  margin: 2.5vh 0.2vh;
  border-radius: 3vh;
  outline: none;
  font-size: 1.5vh;
  display:flex;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
  }
.button:hover {
  color: black;
}
#textButton {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
    outline: none;
    font-weight: 600;
}
#textButton:hover {
  color: green;
}
.active {
  color: green;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 6px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.active, .dot:hover {
  background-color:  #47aa42;
}
@media (max-width: 1900px) {
	.large {
		font-size: 75px;
    line-height: 80px;
	}
  .paragraph {
    font-size: 22px;
    line-height: 30px;
  }
}
@media (max-width: 1500px) {
	.large {
		font-size: 53px;
    line-height: 60px;
  }
  .paragraph {
    font-size: 18px;
    line-height: 25px;
  }
}
@media (max-width: 1200px) {
	.large {
		font-size: 45px;
    line-height: 50px;
	}
  .paragraph {
    font-size: 13px;
    line-height: 20px;
  }
}
@media (max-width: 900px) {
	.large {
		font-size: 30px;
    line-height: 34px;
	}
  .paragraph {
    font-size: 9px;
    line-height: 15px;
  }
}
@media (max-width: 600px) {
	.large {
		font-size: 19px;
    line-height: 25px;
	}
  .paragraph {
    font-size: 7px;
    line-height: 10px;
  }
}

</style>
