<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="flex flex-col home athletes" id = "blurry">

    <div id="header-video">
      <div id="overlay"></div>
      <video autoplay muted loop id="myVideo" poster="/images/a-poster.jpg">
        <source src="/images/Football-Intro.mp4" type="video/mp4">
        <source src="/images/Football-Intro.webm" type="video/webm">
        <source src="/images/Football-Intro.ogv" type="video/ogv">
        Your browser does not support HTML5 video.
      </video>
      <div class="header-content">
        <h1>CREATE. COMMUNICATE. CAPTIVATE.</h1>
        <p>Springmatter no-code software empowers users to easily tell a personalized and meaningful story in a beautiful way. This multi-media "narrative" is published instantly to a webpage, which can be shared via email or social media, magnifying user's ability to share, collaborate, and educate.</p>
        <a href="" class="button red">GET STARTED</a>
      </div>
      <div class="header-scroll"><a href="#home-hero"><img src="/images/arrow.png" /></a></div>
    </div>

    <div id="home-hero">
      <div class="left">
        <h4>Start with a template.</h4>
        <p>Select from our large collection of great looking templates to get started quickly.</p>

        <h4>Add your content or use ours.</h4>
        <p>Upload your personalized multi-media or select from our huge library of stock images, videos, and audio.</p>

        <h4>Review your impact.</h4>
        <p>Once you share your Narrative via email or social media, our custom analytics allow you to measure your viewer's usage and engagement.</p>
      </div>
      <div class="right">
        <img src="/images/devices.png" />
      </div>
    </div>

    <div id="meaningful">
      <div class="inner-content">
        <h2>Start Something Meaningful.</h2>
        <h3>Tell your story to the world.</h3>
        <p><span class="bold">Stories connect better...</span><br />
        Stories emotionally connect people and create brand loyalty.</p>

        <p>For people, businesses and organizations, telling a compelling story is crucial to setting yourself apart from competitors. Our team of software, sensor, analysis, marketing and storytelling experts built a powerful software platform to help your story make an impact.</p>
        <div class="button-pair">
          <a href="" class="button white">DEMO</a>
          <a href="" class="button white">GET STARTED</a>
        </div>
      </div>

    </div>

    <div id="athletes-center">
      <div class="left">
        <img src="/images/basketball-guy.jpg" />
      </div>
      <div class="right">
        <h3>Athletes</h3>
        <p>Competition goes beyond the field or court to get to the next level. If you aren't seen, how do you get noticed? Your story shows recruiters and coaches more than what they can see from your stats.</p>
        <p>Be more than your stats. Share your story and create a better athletic resume for recruiters.</p>
        <ul>
          <li>Upload your own videos and photos.</li>
          <li>Upload interviews from coaches, teachers &amp; more!</li>
          <li>Commentate your own highlights.</li>
          <li>Link to articles, news.</li>
          <li>Share highlights, videos from any age.</li>
          <li>Link to any social media profile.</li>
          <li>Add personal info (quotes, etc.)</li>
          <li>Embed video from other sources (Hudl, Youtube, Vimeo)</li>
          <li>Upload stats.</li>
          <li>Share awards &amp; accomplishments.</li>
          <li>Start story from any age.</li>
          <li>Share your academic highlights.</li>
          <li>Easily share with coaches.</li>
        </ul>
      </div>
    </div>

    <div id="parents">
      <div class="left">
        <h3>Parents</h3>
        <p>Give your athlete an advantage over the competition. Create a more complete resume for coaches &amp; recruiters from your phone!</p>
        <ul>
          <li>Start story from any age.</li>
          <li>Upload your own videos and photos from your phone.</li>
          <li>Upload interviews from coaches, teachers &amp; more!</li>
          <li>Link to articles, news.</li>
          <li>Share highlights, videos from any age.</li>
          <li>Embed video from other sources (Hudl, Youtube, Vimeo)</li>
          <li>Upload stats.</li>
          <li>Share awards &amp; accomplishments.</li>
          <li>Easily share with coaches.</li>
        </ul>
      </div>
      <div class="right">
        <img src="/images/parents.jpg" />
      </div>
    </div>

    <div id="features">
      <div class="inner-features">
        <h3>Features</h3>
        <p>Easily build your story from your phone, tablet or desktop with features designed specifically to create beautiful stories quickly with no coding or design experience.</p>
        <div class="feature-section">
          <div class="top-title">
            <img src="/images/upload.png" />
            <h4>Upload</h4>
          </div>
          <p>Upload pictures and video directly from your mobile device or desktop computer.</p>
        </div>
        <div class="feature-section">
          <div class="top-title">
            <img src="/images/share.png" />
            <h4>Share</h4>
          </div>
          <p>Easily share your story via social media, email and your own custom URL.</p>
        </div>
        <div class="feature-section">
          <div class="top-title">
            <img src="/images/links.png" />
            <h4>Add Links</h4>
          </div>
          <p>Link to articles, press, other profiles or anything you want!</p>
        </div>
        <div class="feature-section">
          <div class="top-title">
            <img src="/images/media.png" />
            <h4>Media-Rich Stories</h4>
          </div>
          <p>Make your story come to life with pictures, video, audio, text and more!</p>
        </div>
        <div class="feature-section">
          <div class="top-title">
            <img src="/images/data-r.png" />
            <h4>Powerful Data &amp; Analytics</h4>
          </div>
          <p>Analytics and data on the performance of your stories to see what connects with your audience.</p>
        </div>
        <div class="feature-section">
          <div class="top-title">
            <img src="/images/text-r.png" />
            <h4>Story Builder Templates</h4>
          </div>
          <p>Easily build your story with our pre-made templates, and prompt questions to help you tell a better story.</p>
        </div>
        <div class="feature-section">
          <div class="top-title">
            <img src="/images/edit.png" />
            <h4>Easy Editing</h4>
          </div>
          <p>Go back and add or edit any element from any time in your story.</p>
        </div>
        <div class="feature-section">
          <div class="top-title">
            <img src="/images/integration.png" />
            <h4>Integrations</h4>
          </div>
          <p>Integrate your story with e-commerce platforms, websites and more.</p>
        </div>
        <div class="feature-section">
          <div class="top-title">
            <img src="/images/embed.png" />
            <h4>Embed Content</h4>
          </div>
          <p>Embed video, photos from other sources and websites.</p>
        </div>
        <div class="feature-section">
          <div class="top-title">
            <img src="/images/communicate.png" />
            <h4>Communicate</h4>
          </div>
          <p>Connect with coaches and recruiters directly through our database.</p>
        </div>
      </div>
    </div>

    <div id="platforms">
      <h3>Flexible Story Platforms</h3>
      <p>Stories take many forms and shapes. Our platform is flexible to provide storytelling opportunities for all including:</p>
      <div class="category athletes">
        <p class="detail">Show you are more than your stats.</p>
        <div class="cat-group">
          <img src="/images/athletes.jpg">
          <p class="caption">ATHLETES &amp; RECRUITERS</p>
        </div>
      </div>
      <div class="category job-seekers">
        <p class="detail">Show you are more than your resume.</p>
        <div class="cat-group">
          <img src="/images/job-seekers.jpg">
          <p class="caption">JOB SEEKERS &amp; EMPLOYERS</p>
        </div>
      </div>
      <div class="category life-story">
        <p class="detail">Pass on family history beyond a family tree.</p>
        <div class="cat-group">
          <img src="/images/life-story.jpg">
          <p class="caption">LIFE STORY &amp; MEMORIALS</p>
        </div>
      </div>
      <div class="category small-business">
        <p class="detail">Build brand loyalty and compete with big industry.</p>
        <div class="cat-group">
          <img src="/images/small-business.jpg">
          <p class="caption">SMALL BUSINESSES</p>
        </div>
      </div>
      <div class="category scientists">
        <p class="detail">Enhance your analysis and turn data into stories.</p>
        <div class="cat-group">
          <img src="/images/scientists.jpg">
          <p class="caption">SCIENTISTS &amp; ENGINEERS</p>
        </div>
      </div>
      <div class="category agriculture">
        <p class="detail">Enhance your analysis and turn data into stories.</p>
        <div class="cat-group">
          <img src="/images/agriculture.jpg">
          <p class="caption">FARMERS &amp; AGRICULTURE</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";
import Vue from 'vue';


export default {
  components: { Breadcrumbs, NavbarDash },

  props: ["username"],

  data() {
    return {
      html: "",
      monitorImage: require("../assets/images/meaningful-section/Image.png"),
      selected: 'images',
      slideIndex: 1,
      active: false,
    };
  },

  watch: {},

  methods: {

    getImage(type){
      if (type == 'images') {
        this.monitorImage = require("../assets/images/meaningful-section/Image.png");
        this.selected = 'images';
      }
      else if (type == 'videos') {
        this.monitorImage = require("../assets/images/meaningful-section/Video.png");
        this.selected = 'videos';
      }
      else if (type == 'text') {
        this.monitorImage = require("../assets/images/meaningful-section/Text.png");
        this.selected = 'text';
      }
      else if (type == 'data') {
        this.monitorImage = require("../assets/images/meaningful-section/Data.png");
        this.selected = 'data';
      }
      else if (type == 'audio') {
        this.monitorImage = require("../assets/images/meaningful-section/Audio.png");
        this.selected = 'audio';
      }
    },

  getStarted() {

    if (this.isSignedIn()) {
      this.$router.push({
          name: "Narratives",
          params: { username: this.$store.state.users.user.username },
        });
    }
    else {
      this.login("login");
    }
  },
  isSignedIn() {
    return this.$store.state.users.user.username != undefined;
  },

  login(prompt) {
      this.$auth.loginWithRedirect({ prompt });
    },
  showSlides() {
    this.active = !this.active;
  },
  },
  computed: {
    xml() {
      return this.$markdown.render(this.html);
    },
  },

  mounted() {},
};
</script>

<style scoped >
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.flexbox {
  display: -webkit-flex;
  display: none;
  margin-bottom: 8vh;
}
.active, .dot:hover {
  background-color: green;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  animation-name: fade;
  animation-duration: 2s;
}

@-webkit-keyframes fade {
  from {opacity: .1}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .1}
  to {opacity: 1}
}

.flexbox .col {
  flex: 1;
}
/*.earth-background {
  background: repeating-linear-gradient(#2c3e50, rgba(0, 0, 0, 0.75) 70%, #2c3e50, rgba(0, 0, 0, 0.75) 60%) , url("/assets/images/young-diverse.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}*/

[class^="icon"] {
  width: 5vh;
  height: 5vh;
  position: relative;
  top: 1.5vh;
  margin-right: 1vh;
  fill: #fff;
}

nav {
  float: left;
  width: 100%;
  text-align: center;
  height: 10vh;
  padding: 1vh;
}
.box {
   display: flex;
   align-items:center;
   max-width: 45%;
   padding: 10%;

}
.footer-box {
  display: flex;
  align-items:center;


}
nav a{
    color: #282828;
    font-weight: bold;
    padding: 30px;
    text-align: center;

}
.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 4vh;

}
.third-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: white;
  padding: 0vh  0vh 14vh 0vh ;

}
.second-row {
  padding: 10vh;
  background-color: white;
  text-align: center;
}

.column {
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.column-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex:1;
}
.hero {
  margin-top: 20vh;
  max-width: 90%;
  min-width: 70%;
}

.logow {
  display: block;
  margin: auto;
  max-width: 50%;

}
.monitor {
  display: block;
  max-width: 90%;
}
.footer-icon {
  max-width: 10%
}
a:active {
  color: red;
  background-color: transparent;
}
a:hover {
  color: green;
  background-color: transparent;
}
.large {
  font-size:97px;
  font-family: "Poppins";
  text-align: left;
  color: white;
  line-height: 102px;
  font-weight: 600;

}
.medium {
  font-size:4vh;
  font-family: "Poppins";
  text-align: left;
  line-height: 4vh;
  font-weight: bold;


}
.small {
  font-size:2.1vh;
  font-family: "Poppins";
  text-align: left;
  line-height: 2.5vh;
  font-weight: 500;

}

.paragraph {
  font-size:24px;
  color: white;
  font-family: "Poppins";
  font-weight: 300;
  text-align: left;
  line-height: 33px;
  margin-top: 3vh;
}

.text-column {
   margin-left: 20%;
   margin-top: 11vh;
   word-break:break-all;

}


.title {
  font-family: "Poppins";
  color: black;
  font-size:5vh;
  z-index : 0;
}
.main-title {
  font-size:8vh;
  font-weight: bold;
  font-family: "Poppins";
  color: black;
}
.text
{
  display: inline-block;
}
 .button {
  background-color: #47aa42;
  border: none;
  color: white;
  padding: 2vh 3vh 2vh 3vh;
  text-decoration: none;
  margin: 2.5vh 0.2vh;
  border-radius: 3vh;
  outline: none;
  font-size: 1.5vh;
  display:flex;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
  }
.button:hover {
  color: black;
}
#textButton {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
    outline: none;
    font-weight: 600;
}
#textButton:hover {
  color: green;
}
.active {
  color: green;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 6px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.active, .dot:hover {
  background-color:  #47aa42;
}
@media (max-width: 1900px) {
	.large {
		font-size: 75px;
    line-height: 80px;
	}
  .paragraph {
    font-size: 22px;
    line-height: 30px;
  }
}
@media (max-width: 1500px) {
	.large {
		font-size: 53px;
    line-height: 60px;
  }
  .paragraph {
    font-size: 18px;
    line-height: 25px;
  }
}
@media (max-width: 1200px) {
	.large {
		font-size: 45px;
    line-height: 50px;
	}
  .paragraph {
    font-size: 13px;
    line-height: 20px;
  }
}
@media (max-width: 900px) {
	.large {
		font-size: 30px;
    line-height: 34px;
	}
  .paragraph {
    font-size: 9px;
    line-height: 15px;
  }
}
@media (max-width: 600px) {
	.large {
		font-size: 19px;
    line-height: 25px;
	}
  .paragraph {
    font-size: 7px;
    line-height: 10px;
  }
}

</style>
