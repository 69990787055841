// Copyright 2019-2021 Michigan Aerospace Corporation
// All Rights Reserved
// Author: Chad Lewis, David Vasseliou, Leslie Borst

import router from "@/router/index.js";
import { reportError } from "@/utils.js";

const state = {
  user: {},
  token: "",
  isAuthenticated: false, // is user signed in? No, by default.
};

const getters = {
  nickname(state){
    return state.user.nickname;
  }
};

const mutations = {
  signIn(state, user) {
    // User has been verified serverside; sign in to app clientside.
    if (user.username) {
      state.isAuthenticated = true;
      state.user = user;
      state.token = user.token;
      localStorage.setItem("plait-token", user.token);
    }
  },

  signOut(state) {
    // Sign user out of the app; remove token from local storage.
    state.user = null;
    state.token = null;
    state.isAuthenticated = false;
    localStorage.setItem("plait-token","");
  },
};

const actions = {
  async verifyAuthenticated(context) {
    // Verify that the user is authenticated using the token.
    var token = localStorage.getItem("plait-token");
    let res = await $samsa.verifyAuthenticated.post(token);
    console.log("VERIFY: ", res)
    context.commit("signIn", res.data.verifyAuthenticated);
    //  .post(token)
    //  .then((res) => {
    //    context.commit("signIn", res.data.verifyAuthenticated);
    //  })
    //  .catch((err) => {
        // If the user is not valid; go to sign-in page.
    //    context.commit("signOut");
    //  });
  },

  signIn(context, loginInfo) {
    $samsa.signIn
      .post(loginInfo.email, loginInfo.password)
      .then((res) => {
        var user = res.data.signIn;
        context.commit("signIn", user);
        router.push({
          name: "Dashboard",
          params: { username: user.username },
        });
      })
      .catch((err) => {});
  },

  signOut(context) {
    context.commit("signOut");
  },

  signUp(context, user) {
    $samsa.createUser
      .post(user)
      .then((res) => {
        let user = res.data.createUser;
        context.commit("signIn", user);
        router.push({
          name: "Narratives",
          params: { username: user.username },
        });
      })
      .catch((err) => {
        reportError(err);
      });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
