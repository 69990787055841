<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="flex flex-col earth-background" id = "blurry">

    <div class="content about main our-teams">
      <transition name="fade" appear>
        <div class="header-section">
          <div class="header-content">
            <h1 class="hero">OUR TEAM</h1>
            <p>We know it’s our differences that make us stronger and we strive to bring diverse experiences to everything we do.</p>
            <a href="#about-section" class="scroll-arrow">&#8595;</a>
          </div>
        </div>
      </transition>

      <div id="about-section" class="about people">
        <div class="casting right">
          <div class="profile-copy">
            <p>As an engineer and CEO, I aspired to develop ideas that would provide people with a chance to make a difference in the world. For more than twenty-five years, I led entrepreneurial ventures and advanced technology development, from company creation and team building to product development and licensing. Since 2004, I've been responsible for leading all day-to-day operations of the company, financial oversight (P&L), strategic planning, business development, and operations. Thankfully, my eyes have been opened along the way through my family's experiences in raising kids who live in the margins. It was through our advocacy work that I witnessed the transformative power of storytelling – this became the motivation behind Springmatter. It is both my passion and responsibility to foster an inclusive culture that allows people to live and work authentically while building products that bring us closer together.</p>
          </div>
          <div class="profile-images">
            <img src="/images/team/ptchoryk.jpeg" />
            <h2>Pete Tchoryk <span>CEO</span></h2>
            <a href="https://www.linkedin.com/in/petertchoryk/" target="_blank" name="linkedin-peter"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
        </div>
          <div class="casting left">
            <div class="profile-images">
              <img src="/images/team/chad_lewis.jpg" />
              <h2>Chad Lewis <span>Director of Software Development</span></h2>
              <a href="https://www.linkedin.com/in/chad-lewis-19b0a76/" target="_blank" name="linkedin-chad"><img class="social li" src="/images/LI-In-Bug.png" /></a>
            </div>
            <div class="profile-copy">
              <p>Growing up with two entrepreneurial parents instilled in me an enthusiasm for being creative and taking chances. When they bought the family an Apple IIe back in 1984, I started programming in BASIC and never looked back. I had found my passion. Building tools and applications that helped improve people's lives became a love of mine. My career started at an environment laboratory working with the information management system that aggregated the data from analytical instruments. I became obsessed with improving software that people use in their day-to-day lives while making the data they work with more accessible and easier to understand. As the director of software development at SpringMatter, I bring with me a love for software and a need to build applications that help people understand each other better.</p>
            </div>
        </div>
        <div class="casting right fan">
          <div class="profile-copy">
            <p>As someone who has lived in multiple countries, speaks numerous languages, and has worked in multiple industries, my diverse background led me to SpringMatter as the VP of Growth Projects where I am responsible for providing a meaningful storytelling solution for our customers. Before joining the Springmatter team, I was a consultant supporting the DOE, providing advanced research program management and development leadership, focused on technology maturation and commercialization. I gained my passion for technology, product development, and leadership in the aerospace industry, where we incubated and commercialized advanced sensors for many applications. Regardless of where I am, the language I speak, or the stage of my career, the challenge of communicating effectively in our digital world is a common denominator. I am here at SpringMatter to help offer such a solution for all.</p>
          </div>
          <div class="profile-images">
            <img src="/images/team/mike-fan.jpg" />
            <h2>Mike Fan <span>VP of Growth Projects</span></h2>
            <a href="https://www.linkedin.com/in/mike-f-33685937" target="_blank" name="linkedin-mike-fan"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
        </div>
        <div class="casting left">
          <div class="profile-images">
            <img src="/images/team/doug_bright.jpeg" />
            <h2>Douglas Bright <span>Director of Marketing</span></h2>
            <a href="https://www.linkedin.com/in/dgbright/" target="_blank" name="linkedin-doug"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
          <div class="profile-copy">
            <p>Douglas Bright took his passion for storytelling, writing, and graphic design and used that approach to build his career as a digital marketing specialist. After working in several industries including professional sports, music, fitness, advertising, liquor distribution, retail, property management and others, Douglas took his experiences and started his own marketing agency. For the last 10 years, Douglas has been working with businesses of all sizes, from startups to enterprise, improving their digital presence and telling their stories.</p>
          </div>
        </div>
        <div class="casting right matt">
          <div class="profile-copy">
            <p>As a physicist with a PhD from the University of Michigan, I began my career at General Dynamics Advanced Information Systems, focusing on the application of machine learning techniques to defense and intelligence problems. I served as principal investigator for the $20M DARPA Retriever program as well as other electronic warfare and machine learning programs for the Navy, NSA, and various U.S. Government agencies. I currently apply machine learning technology to problems such as clean energy, early-stage disease detection, and precision ecology. Over the last decade there have been dramatic advances in machine learning and artificial intelligence, and I am excited to bring these advances to a wider audience via Springmatter and its clients.</p>
          </div>
          <div class="profile-images">
            <img src="/images/team/matt_lewis.jpeg" />
            <h2>Matthew J. Lewis, Ph.D. <span>Vice President</span></h2>
            <a href="https://www.linkedin.com/in/matthewjameslewis/" target="_blank" name="linkedin-matt"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
        </div>
        <div class="casting left">
          <div class="profile-images">
            <img src="/images/team/yma-johnson.jpg" />
            <h2>Yma Johnson <span>Lead Storyteller</span></h2>
            <a href="https://www.linkedin.com/in/john-austin-063b93bb" target="_blank" name="linkedin-john-austin"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
          <div class="profile-copy">
            <p>Yma Johnson is the Lead Storyteller for Springmatter and Michigan Aerospace Corporation. She joined the company in 2019 as Director of Community Outreach before being promoted to her current position. Ms. Johnson began her career as a journalist in Puerto Rico more than two decades ago. She has written for various publications and won multiple awards for her fiction. Her creative work has appeared in anthologies and literary magazines in the United States and abroad. Ms. Johnson is charged with sharing the company's mission and history as well as supporting inclusive company culture. She is an activist on behalf of transgender youth at the local, state, and national levels. As a grant writer, Ms. Johnson has raised $7.5+ million for organizations that protect the environment and support BIPOC communities. She plays a major role in advancing the company's commitment to diversity, equity, and inclusion and its initiatives to diversify the tech industry. Ms. Johnson holds a master’s degree in creative writing from Eastern Michigan University and a bachelor’s degree in psychology from the University of Michigan.</p>
          </div>
        </div>
        <div class="casting right david">
          <div class="profile-copy">
            <p>For as long as I can remember, I’ve always been intrigued with the mash-up of visual arts and technology and its many uses commercially. It only made sense to pursue a Bachelor of Fine Arts degree from Wayne State University in Interdisciplinary Electronic Art which helped pave my way to be ‘artsy’ and a ‘techy’ simultaneously. Luckily, when I graduated in 2007, everyone needed to hire web developers in droves, and I was shown the ropes in front-end development while quickly learning how much fun it is. Professionally, my role became more technical over the years while still using my Fine Arts roots in design and creative elements of web building which I find very rewarding. It’s a thrill to be able to apply over a decade of my experience in UI development with an organization like Springmatter.</p>
          </div>
          <div class="profile-images">
            <img src="/images/team/dvasseliou.jpeg" />
            <h2>David Vasseliou <span>UI/UX Engineer</span></h2>
            <a href="https://www.linkedin.com/in/david-vasseliou/" target="_blank" name="linkedin-matt"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
        </div>
        <div class="casting left">
          <div class="profile-images">
            <img src="/images/team/leslie_borst2.jpg" />
            <h2>Leslie Borst <span>UI/UX Engineer</span></h2>
            <a href="https://www.linkedin.com/in/leslieaborst/" target="_blank" name="linkedin-leslie"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
          <div class="profile-copy">
            <p>With a background in food service and the arts, I pursued a website programming degree that allowed me to be both creative and analytical. As a result, I went from a breakfast cook to a UI/UX Engineer in less than three years. While I had an interest in programming in the past, it never crossed my mind that it could be a career until I met some other developers who encouraged me to go back to school. One year before graduation, I had the opportunity of joining Grand Circus in the after-hours front-end bootcamp. When bootcamp was finished, I had made connections with employers, and was given my first role before finishing college. Almost three years later, I am honored with my role at Springmatter, where I hope to forge new skills and connections.</p>
          </div>
        </div>
        <div class="casting right darryl">
          <div class="profile-copy">
            <p>Originally I got into tech and programming born from a desire to go into video game development. As I got older, I found  what originally inspired me to pursue an education in Computer Science no longer aligned with my career interests. I took a break from school for a brief period to pursue other career interests. That time led me towards web development which allowed me to engage the creative and technical sides of my brain. In December of 2020, I completed my Bachelor’s Degree in Computer Science which opened the doors to many opportunities that  eventually led to me joining Springmatter as a Full-Stack Engineer.</p>
          </div>
          <div class="profile-images">
            <img src="/images/team/darryl_green.jpg" />
            <h2>Darryl Green Jr. <span>Full-Stack Engineer</span></h2>
            <a href="https://www.linkedin.com/in/darrylgreenjr/" target="_blank" name="linkedin-darryl"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
        </div>
        <div class="casting left">
          <div class="profile-images">
            <img src="/images/team/jane.jpg" />
            <h2>Jane Pavlich <span>Chief Operating Officer</span></h2>
            <a href="https://www.linkedin.com/in/jane-pavlich-4aaa794/" target="_blank" name="linkedin-jane"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
          <div class="profile-copy">
            <p>I have over 25 years of experience at MAC leading efforts in program management, systems engineering, product development, bid and proposals, strategic planning, financial oversight, program management and operations.  My career in LIDAR development programs has spanned ground-based stations, airborne optical air data systems, high altitude balloon platforms, wind turbine applications, and atmospheric measurements to support hypersonic vehicle testing. I have been involved in all phases of design, test, installation and verification of these LIDAR systems. I was the program manager for the Autonomous Satellite Docking System (ASDS) program for DARPA. Additional space robotics experience includes system analysis, production planning, software development, test and mission preparation for the Automated Wafer Cartridge System, a mechanism designed to transfer and process semiconductor wafers in space.</p>
          </div>
        </div>
        <h2 class="advisors">Advisors</h2>
        <div class="casting left">
          <div class="profile-images">
            <img src="/images/team/j-austin.jpg" />
            <h2>John Austin <span>Advisor</span></h2>
            <a href="https://www.linkedin.com/in/john-austin-063b93bb" target="_blank" name="linkedin-john-austin"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
          <div class="profile-copy">
            <p>John Austin is the former President of the Michigan State Board of Education. Austin also Lectures on the Economy at the University of Michigan. His series of publications on The Avenue since the 2016 Presidential election on the politics and economy in the Midwest swing states have informed the national and international understanding of this important economic and political region. Austin has been quoted and his Brookings work reported on by the New York Times, Washington Post, The Economist, Wall Street Journal, The Atlantic, the BBC, Voice of America, Financial Times, Nikkei News, Politico, and the Hill--among dozens of local, national, and international media.</p>
          </div>
        </div>
        <div class="casting right ryan">
          <div class="profile-copy">
            <p>Ryan has 20 years of investing experience from pre-seed to private equity, during which he led over $200 million worth of investments in more than 40 technology companies. Ryan holds engineering degrees from the University of Michigan and the University of Wisconsin, and earned an MBA with Distinction from the Ross School of Business at University of Michigan, where participated in the Erb Institute for Global Sustainable Enterprise.</p>
          </div>
          <div class="profile-images">
            <img src="/images/team/ryan-waddington.jpg" />
            <h2>Ryan Waddington <span>Advisor</span></h2>
            <a href="https://www.linkedin.com/in/ryanwaddington/" target="_blank" name="linkedin-ryan-waddington"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
        </div>
        <div class="casting left">
          <div class="profile-images">
            <img src="/images/team/eli_miller.jpeg" />
            <h2>Elijah Miller <span>SaaS Technology Advisor</span></h2>
            <a href="https://www.linkedin.com/in/elijahmiller/" target="_blank" name="linkedin-eli"><img class="social li" src="/images/LI-In-Bug.png" /></a>
          </div>
          <div class="profile-copy">
            <p>Eli brings over 20 year's experience creating and growing software businesses.  Eli's ability to understand both the technology-and business impact of that technology-is only rivaled by his curiosity to learn and teach.</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";

export default {
  components: { Breadcrumbs, NavbarDash },

  props: [],

  data() {
    return {
      name:"about"
    };
  },

  watch: {},

  methods: {
  },

  computed: {},


  mounted() {},
};

$( document ).ready(function() {

$(window).resize(function() {
		if ($(window).width() < 767) {
		    $('.casting.right.brett .profile-images').insertBefore('.casting.right.brett .profile-copy');
        $('.casting.right.matt .profile-images').insertBefore('.casting.right.matt .profile-copy');
        $('.casting.right.eli .profile-images').insertBefore('.casting.right.eli .profile-copy');
        $('.casting.right.david .profile-images').insertBefore('.casting.right.david .profile-copy');
        $('.casting.right.darryl .profile-images').insertBefore('.casting.right.darryl .profile-copy');
        $('.casting.right.ryan .profile-images').insertBefore('.casting.right.ryan .profile-copy');
		} else {
        $('.casting.right.brett .profile-images').insertAfter('.casting.right.brett .profile-copy');
        $('.casting.right.matt .profile-images').insertAfter('.casting.right.matt .profile-copy');
        $('.casting.right.eli .profile-images').insertAfter('.casting.right.eli .profile-copy');
        $('.casting.right.david .profile-images').insertAfter('.casting.right.david .profile-copy');
        $('.casting.right.darryl .profile-images').insertAfter('.casting.right.darryl .profile-copy');
        $('.casting.right.ryan .profile-images').insertAfter('.casting.right.ryan .profile-copy');
		}
	}).resize();

});

</script>

<style>

</style>
