<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
<div class="editor">
  <audio-component :content="content" @getSignedRequest="uploadFile"></audio-component>
  <div class="or">
    <p><span>________________</span>OR<span>________________</span></p>
  </div>
  <upload-content
    uploadText="Drag an audio file to Upload or Click Here"
    imageType="/images/button-audio5.png"
    ref="audio"
    :content="content">
  </upload-content>

  <fieldset class="audio-checks">
    <div id="radio-section-one" class="input-container" index="1" @click="radioOne">
      <input id="settings-option-2" type="radio" class="input-container__radio">
      <label for="settings-option-2" class="input-container__label">
        <i class="fas fa-check-circle"></i>
        <span>Play in background</span>
      </label>
    </div>
    <div id="radio-section-two" class="input-container" index="2" @click="radioTwo">
      <input id="settings-option-3" type="radio" name="privacySetting" class="input-container__radio">
      <label for="settings-option-3" class="input-container__label">
        <i class="fas fa-check-circle"></i>
        <span>Autoplay</span>
      </label>
    </div>
  </fieldset>
</div>

</template>

<script>
import UploadContent from '@/views/Narrator/upload-content.vue';
import AudioComponent from '@/components/audio_video_components/audio-component.vue';

export default {
  components: {UploadContent, AudioComponent},

  props: ["content"],

  data() {
    return {
      
    };
  },

  methods: {
    uploadFile (fileObject) {
      this.$refs.audio.getSignedRequest(fileObject);
    },
    radioOne(){
      var radioOne = (document.getElementById("radio-section-one"));
      var radioTwo = (document.getElementById("radio-section-two"));
      console.log("Changed_radio:", radioOne)
      radioOne.classList.add("radio-toggle");
      radioTwo.classList.remove("radio-toggle");
    },

    radioTwo(){
      var radioOne = (document.getElementById("radio-section-one"));
      var radioTwo = (document.getElementById("radio-section-two"));
      console.log("Changed_radio:", radioTwo)
      radioTwo.classList.add("radio-toggle");
      radioOne.classList.remove("radio-toggle");
    }
  },

  computed: {

  },

  mounted() {
    
  }
};

</script>

<style scoped lang="scss">

.audio-checks {
  width: 78%;
  color: #fff;
  margin-top: 1em;
  .input-container {
    margin-bottom: 0.5em;
    input {
      display: none;
    }
    span {
      margin-left: 1em;
    }
  }
  #radio-section-one {
    .fas.fa-check-circle {
      color: #fff;
    }
  }
  #radio-section-two {
    .fas.fa-check-circle {
      color: #fff;
    }
  }
  #radio-section-one.radio-toggle {
    .fas.fa-check-circle {
      color: #000;
    }
  }
  #radio-section-two.radio-toggle {
    .fas.fa-check-circle {
      color: #000;
    }
  }
}

  .editor{
    width: 100%;
    height: 100%;
    padding: 10px;
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    
  .or {
    font-size: 22px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 25px;
    color:#000;
  }

  .or p {
      font-size: 0.825em;
      span {
        font-size: 0.5em;
        vertical-align: super;
        padding: 0 5px;
      }
    }


</style>
