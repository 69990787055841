<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>

  <div @click="click"  style="position:absolute;top:0;right:0; z-index:10; pointer-events: all;">
    <svg  id="graph" width="50px" height="50px"  >

    <!-- pattern -->
    <defs>
      <pattern id="image" x="0px" y="0px" height="100%" width="100%"
               viewBox="0 0 100 100">
        <image x="0" y="0" width="100px" height="100px" xlink:href="/images/settings.png"></image>
      </pattern>
    </defs>

    <circle id="sd" class="medium" cx="25px" cy="25px" r="18px"  stroke="#3498db" stroke-width="3px" />
    <circle id="sd" class="medium" cx="25px" cy="25px" r="15px" fill="url(#image)"  />


  </svg>
  </div>

</template>

<script>

export default {
  components: {},

  props: [],

  data() {
    return {

    };
  },

  watch: {

  },

  methods: {
    click(){
      this.$emit("edit")
    },
  },

  computed: {
    data() {

    },
  },

  mounted() {

  },
};
</script>

<style scoped>
div{

  display: inline-block;

}

svg{
  fill:transparent;

}

svg:hover{
  fill:#AFAFAF;
}

</style>
