<template>

<div class="nav-link-item" style="width:auto;">
   <a class="container" href="#" @click="show" @mouseenter="show">
     <slot>
     <div>{{title}}</div>
     <i :class="{ 'fa-caret-up': showDropDown, 'fa-caret-down': !showDropDown }" class="fa" aria-hidden="true"></i>
     </slot>

   </a>

  <transition name="slide-fade">
    <div class="w4 mt1 dd-item" v-if="showMenu" @mouseleave="hide">
      <div style="background-color:transparent"><br/></div>
      <ul class="menu list pl0 pa0 ma0">
        <li v-for="link in links" class="list">
        <router-link
                     :to="{ name: link.url, params: { username: username } }"
                    class="px-3 py-2 text-sm font-medium leading-5
                    text-white focus:outline-none
                    transition duration-150 ease-in-out
                    block lg:inline-block lg:border-none border-b-2 border-gray-500"
                    style = "font-size: 18px"
                    >{{link.name}}</router-link>

        </li>
      </ul>
    </div>
  </transition>
 </div>
</template>

<script>
import { user } from "@/scripts/Authentication.js";
// import Component from "../component_location"

export default {
  components: {},

  props: ["title","links"],

  data() {
    return {
      showDropDown: false,
      can_hide: false,
    }
  },

  watch: {},

  methods: {
  isMobile() {
   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     return true
   } else {
     return false
   }
   },
    show(){
      var self = this;
      this.can_hide = true;
      //window.setTimeout(function(){self.can_hide=true},400)
      this.$root.$emit("hide",this)
      this.showDropDown=true;

    },
    hide(){
      if(this.can_hide)
        this.showDropDown=false;

    }
  },

  mounted() {
    var self = this;
    this.$root.$on("hide",function(e){
      if(e!=self)
        self.showDropDown = false;
    })

  },
  computed:{
  username() {
    if(user)
      return user.username;
    else
      return null;
  },
  showMenu:function(){
    return (this.isMobile() || this.showDropDown)
  }
  }
};
</script>

<style scoped>


.container{
  display:flex;
}
.mt1 {
  margin-top: .25rem;
}

.pointer:hover {
  cursor: pointer;
}
.hover-bg-moon-gray:hover {
  background-color: #ccc;
}
.hover-bg-moon-gray:focus {
  background-color: #ccc;
}
.menu {
  background-color: #f4f4f4;
  border-style: solid;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-width: .125rem;
  border-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.8);
  border-radius: .25rem;
}
.menu li {
  border-style: solid;
  border-width: 1px;
  border-left-width: 0;
  border-top-width: 0;
  border-right-width: 0;
}
.menu li:not(:last-child) {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.3);
}
.dd-link {
  font-size: .875rem;
  padding: .5rem;
  text-decoration: none;
  transition: color 0.15s ease-in;
  opacity: 1;
  transition: opacity 0.15s ease-in;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(0, 0, 0, 0.8);
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s ease;
}
.slide-fade-enter {
  transform: translateY(-50px);
  opacity: 0;
}
.slide-fade-leave-to {
 transform: translateY(-50px);
  opacity: 0;
}

</style>
