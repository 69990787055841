<template>
    <div class="record-container">
        <input v-if="microphoneAccessible" id="videoFile" type="file" class="hidden" accept="video/*" capture="">
        <div v-else class="cant-record">
            <p>Unable to capture your microphone. Try a different Browser. ex. Chrome</p>
        </div>
        <div ref="audio_container"></div>
        <div class="record-container__buttons">
            <button v-if="!isRecording" id="btn-start-recording" @click="start_recording" class="record"><i class="fas fa-circle"></i> <span>Record</span></button>
            <button v-else id="btn-stop-recording" @click="stop_recording" class="stop"><i class="fas fa-stop"></i> <span>Stop</span></button>
        </div>
    </div>
</template>

<script>
var isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);

export default {
  components: {},

  props: ["content"],

  data() {
    return {
      text:"",
      files:[],
      audio: null,
      recorder: null,
      microphoneAccessible: true,
      isRecording:false,

    };
  },

  watch: {
    files(after,before){
      console.log("I HAVE FILES", after)
    }
  },

  methods: {

    reset_audio(){

      if(this.audio!=null){
        this.audio.remove()
        this.audio = null
      }

      //<audio ref="audio" controls autoplay playsinline></audio>
      this.audio = document.createElement("audio");
      this.audio.setAttribute("controls",true)
      this.audio.setAttribute("autoplay",true)
      this.audio.setAttribute("playsinline",true)
      this.$refs.audio_container.appendChild(this.audio)

    },
    stopRecordingCallback: function() {
      console.log("Playing")
      this.reset_audio()
      let blob = this.recorder.getBlob();
      let url = URL.createObjectURL(blob);

      // generating a random file name
      var fileName = "video1.mp3";

      // we need to upload "File" --- not "Blob"
      var fileObject = new File([blob], fileName, {
          type: 'audio/mp3'
      });

      this.$emit("getSignedRequest", fileObject);

      this.audio.src = url;
      this.audio.muted = false;
      this.audio.play();

    },
    start_recording: async function(){
      await this.initializeMicrophone()
      console.log("Microphone:", this.microphone)
      this.audio.muted = true;
      this.audio.srcObject = this.microphone;

      this.isRecording = true;
      this.recorder.startRecording();
    },
    stop_recording: function() {
      this.isRecording = false;
      this.recorder.stopRecording(this.stopRecordingCallback);

    },
    captureMicrophone: async function(){

      this.microphone = await navigator.mediaDevices.getUserMedia({
       audio: isEdge ? true : {
           echoCancellation: false
       }
       })
       console.log("Setting Microphone:", this.microphone)
    },

    initializeMicrophone: async function(mic) {

      await this.captureMicrophone();
      if(this.microphone){
        var options = {
          type: 'audio',
          numberOfAudioChannels: isEdge ? 1 : 2,
          checkForInactiveTracks: true,
          bufferSize: 16384
        };
        options.sampleRate = 44100;
        options.bufferSize = 4096;
        options.numberOfAudioChannels = 2;
        this.recorder = RecordRTC(this.microphone, options);
      }
      else{
        console.log("Microphone Not Found")
      }
    }
  },

  computed: {

  },

  mounted() {
    this.reset_audio()
    if(this.content.url != null){
      this.audio.src = this.content.url;
    }
  }
};

</script>

<style scoped lang="scss">

    .record-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 25px;

      &__buttons {
        margin-top: 20px;

        .record {
          color: $green;
          border: 1px solid grey;
          border-radius: 5px;
          font-size: 22px;
          padding: 3px 5px;
          margin-right: 5px;

          &:hover {
            color: darken($green, 10%);
            border-color: darken(grey, 10%);
            transition: all .3s;
          }
        }

        .stop {
          color: $red;
          border: 1px solid grey;
          border-radius: 5px;
          font-size: 22px;
          padding: 3px 5px;

          &:hover {
            color: darken($red, 10%);
            border-color: darken(grey, 10%);
            transition: all .3s;
          }
        }
      }
    }

    .cant-record {
      text-align: center;
      color: grey;
      font-style: italic;
    }

    #btn-start-recording {
      padding: 0;
      color: #d20000;
      border: 0;
      margin: 0;
      transition: all 0.3s ease-in;
      outline: 0;
      &:hover {
        color: #fff;
        transition: all 0.3s ease-in;
      }
      i {
        font-size: 1.5em;
      }
      span {
        display: block;
        font-size: 0.65em;
      }
    }
    #btn-stop-recording {
      padding: 0;
      color: #d20000;
      border: 0;
      outline: 0;
      &:hover {
        color: #fff;
        transition: all 0.3s ease-in;
      }
      i {
        font-size: 1.5em;
        display: block;
      }
      span {
        font-size: 0.65em;
        vertical-align: text-top;
      }
    }

</style>
