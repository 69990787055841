<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>

  <div class="parent">
    <div class="placeholder" v-if="content.url==null">
      <img class="video_placeholder" src="/images/button-video5.png"></img>
    </div>
    <div v-else class="video_actual" >
      <iframe v-if="embeded"
        ref="video"
        :src="embededUrl"
        class="rounded-md object-cover h-40 h-full w-full"
      >
      </iframe>
      <video
        v-else
        ref="video"
        :src="content.url"
        class="rounded-md object-cover h-40 h-full w-full"
        no-controls
      />
      <div class="button_background">
        <button v-if="!playing" @click="play" class="play"><i class="fas fa-play"></i> <span>Play</span></button>
        <button v-else class="stop" @click="stop"><i class="fas fa-stop"></i> <span>Stop</span></button>
      </div>
    </div>

  </div>

</template>
<script>


import SettingsIcon from "@/views/Narrator/SettingsIcon.vue"
export default {
  components: {SettingsIcon},

  props: {content:{default:null},view:{default:false}},

  data() {
    return {
      playing:false,
      embededUrl:"",
    };
  },

  watch: {

  },

  methods: {
    play(){

      if (this.embeded){
        this.embededUrl = this.content.url + "?modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=1"
        this.playing = true;
      }
      else{
        this.playing = true;
        this.$refs.video.play()
      }
    },
    stop(){
      if (this.embeded){
        this.embededUrl = this.content.url + "?modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=0"
        this.playing = false;
      }
      else{
        this.playing = false;
        this.$refs.video.pause()
      }
    },
    edit(){
      window.$events.$emit("edit",this.content)
    },
    ended(){
      this.playing = false;
    }
  },

  computed: {
    embeded(){
      if(this.content.url.includes("embed"))
        return true;
      else {
        return false
      }
    }

  },

  mounted() {
    this.$refs.video.addEventListener("ended", this.ended, false);
    this.embededUrl = this.content.url + "?modestbranding=1&autohide=1&showinfo=0&controls=0"
    console.log("Viewing: ",this.embededUrl  )
  },
};
</script>

<style scoped>

.image_content{

}


.placeholder img{
  pointer-events: none;
  transition: ease all .5s;
  max-width:200px;
  max-height: 143px;
  display:block;
  margin-left: auto;
  margin-right: auto
}


.video_placeholder{

  display:block;
}

.parent{
  position:absolute;
  pointer-events: all;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  z-index: 1;
}

.video_actual{
  padding:20px;
  position:relative;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 25px;
  overflow: hidden;
  width:100%;
  height:100%;
  background-size:contain;
  background-position:center center;
  background-repeat: no-repeat;
  border-radius: 30px;
  pointer-events: none;
}

.video_actual button{

}

.button_background{
  pointer-events: all;
  border-radius: 5px;
  background-color: #FFF7;
  padding-left:10px;
  padding-top:3px;
  height:30px;
  width:80px;
  position: absolute;
  left:40px;
  bottom:40px;
}

.cover{
  background-size:cover;
  border-radius: 0px;
  padding:0px;
}

.placeholder{
  pointer-events: all;
  padding:20px;
  position:relative;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  /*background-color: #3498db55;*/
  background-color: #599dcb54;
  border: 1px solid #dbdbdb;
  border-radius: 25px;
  overflow: hidden;
  width:100%;
  height:100%;
}

</style>
