<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>

<div id="text" class="parent" :style="{marginLeft:x}">
  <!--<settings-icon @edit="edit"></settings-icon>-->
  <div v-if="content.text == null || content.text.length == 0" @click="click" class="placeholder" >
    <img  class="image_placeholder" src="/images/button-text5.png">
  </div>
  <div v-else @click="click" ref="content" :style="{opacity:opacity}" class="text_content fit ql-editor" v-html="content.text">

  </div>

</div>


</template>

<script>
import SettingsIcon from "@/views/Narrator/SettingsIcon.vue"


export default {
  components: {SettingsIcon},
  props: {scrollTop:{type:Number},view:{default:false}, content:{type:Object, default:function(){return {type:null}}}},

  data() {
    return {
      x:0,
      opacity:"100%",
    };
  },

  updated: function(){
      if(this.$refs.content)
        textFit(this.$refs.content)
  },
  watch: {
      scrollTop:function(after, before){
        var node = this.$el.parentNode;
        var height = document.getElementById("narrative").getBoundingClientRect().height
        var rect = this.$el.getBoundingClientRect()
        var t1 = rect.y + rect.height;
        var t2 = (height - rect.y + 100) / height ;

        if(t2 > .25 && t2 < .75){
          this.x = 100 - ((t2-.25)/.5 )*100 + "%";
          this.opacity = ((t2-.5)/.25 )*100 + "%";
        }
        else{
          this.x = 0
        }
        console.log("text Scroll: ", t2, this.opacity)
      },
    "content.text":function(){
      if(this.$refs.content)
        textFit(this.$refs.content)
    }
  },
  methods: {
    click(){

    },
    edit(){
        window.$events.$emit("edit",this.content)
      //textFit(document.getElementsByClassName('fit'));
    },
    fit(){
      if(this.$refs.content!=null)
        textFit(this.$refs.content)
      //console.log("fitting: ",this.$refs.content)
      //this.fitties = fitty('.fit',{maxSize:200});


    }
  },

  computed: {


  },

  mounted() {

    var self = this;

    //this.content.$on("delete", function(){
    //  self.$el.remove();
    //  window.$events.$emit("close_edit")

    //})

    self.fit()
    window.onresize = function(){
      window.clearTimeout(self.clear)
      self.clear = window.setTimeout(function(){
        self.fit();
      },200)
    };
  },
};
</script>

<style scoped>
.text_content{
  display: flex;
  align-items: center;
}

.placeholder img{
  pointer-events: none;
  transition: ease all .5s;
  max-width:200px;
  max-height: 143px;
  display:block;
  margin-left: auto;
  margin-right: auto
}

.placeholder{
  pointer-events: none;
  padding:20px;
  position:relative;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  /*background-color: #3498db55;*/
  background-color: #599dcb54;
  border: 1px solid #dbdbdb;
  border-radius: 25px;
  overflow: hidden;
  width:100%;
  height:100%;
}

.image_placeholder{

  display:block;
}

div{
  pointer-events: none;
}


.textFitted{
  display: flex;
}

.edit .parent{
  pointer-events: all;
}

.view .parent{
  pointer-events: none;
}

.parent{
  position:absolute;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  z-index: 2;
}

.edit .fit{
  pointer-events: all;
}

.fit{
  width:100%;
  height:100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}



</style>
