<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
    <div class="card">
        <div class="images-container" @mouseover="hover= true" @mouseleave="hover = false">
            <img v-if="narrative.thumbnail" class="card__img" :style="{ 'background-image': 'url(' + narrative.thumbnail + ')' }" />
            <img v-else class="card__img" style="background-image:url('/images/image_placeholder.jpeg')" />
            <div class="action-buttons" v-if="hover">
                <div @click="delete_narrative"><i class="fas fa-trash delete-this"></i></div>
                <div @click="go"><i class="fas fa-edit open-this"></i></div>
                <div @click="edit_info"><i class="fas fa-cog edit-this"></i></div>
                <div @click="view"><i class="fas fa-eye view-this"></i></div>
                <a href="mailto:?subject=Here%20Is%20My%20Story&body=Replace%20this%20with%20a%20link%20to%20your%20narrative"><i class="fas fa-envelope mail-this"></i></a>
            </div>
        </div>
        <ul class="tag-list">
            <li class="tag-list__item">
                <span>{{ narrative.tags }}</span>
            </li>
        </ul>
        <h2>{{ narrative.name }}</h2>
        <p class="card__desc">{{ narrative.description }}</p>
    </div>
</template>

<script>
// import Component from "../component_location"

export default {
  components: {},

  props: [
      "narrative"
  ],

  data() {
    return {
        hover: false
    };
  },
  methods: {
    go(){
      this.$emit("go", this.narrative);
    },
    view(){
      this.$emit("view", this.narrative);
    },
    delete_narrative(){
      this.$emit("delete", this.narrative);
    },
    edit_info(){
        this.$emit("edit", this.narrative);
    }
  }

};
</script>

<style lang="scss" scoped>

    .card {
        width: 30%;
        padding: 15px;
        margin: 25px 20px;
        display: flex;
        flex-direction: column;

        .images-container {
            width: 100%;
            height: 300px;
            position: relative;
            z-index: 1;

            .card__img {
                width: 100%;
                height: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                pointer-events: none;
            }

            .action-buttons {
                position: absolute;
                pointer-events: all;
                bottom: 0;
                background: rgba(255, 255, 255, .8);
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                z-index: 2;
                pointer-events: all;

                .delete-this {
                    color: #B7343C;
                    font-size: 24px;
                    margin: 5px;
                    &:hover {
                        cursor: pointer;
                    }
                }

                .open-this {
                    font-size: 24px;
                    color: $blue;
                    margin: 5px;
                    &:hover {
                        cursor: pointer;
                    }
                }

                .edit-this {
                    font-size: 24px;
                    color: rgb(126, 124, 124);
                    margin: 5px;
                    &:hover {
                        cursor: pointer;
                    }
                }

                .view-this {
                    font-size: 24px;
                    color: #2ecc71;
                    margin: 5px;
                    &:hover {
                        cursor: pointer;
                    }
                }

                .mail-this {
                    font-size: 24px;
                    color: $green;
                    margin: 5px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }


        h2 {
            color: #46a740;
            font-weight: 700;
        }

        &__desc {
            width: 100%;
            text-align: justify;
        }

        .tag-list {
            display: flex;
            flex-direction: row;
            padding: 5px 0;
            color: $blue;
            font-size: .8em;
            font-weight: 800;
            text-align: left;

            &__item {
                padding-right: 5px;
            }
        }
    }

</style>
