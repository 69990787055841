//Copyright 2019-2021 Michigan Aerospace Corporation
//All Rights Reserved
//Author: Chad Lewis, David Vasseliou, Leslie Borst

import {getInstance} from '@/auth/index.js';
const APIPREFIX = process.env.VUE_APP_APIPREFIX;
console.log("API.JS")

async function constructRequest(url, method) {
  var token = "1234"
  var request = {};
  request.url = (await APIPREFIX) + url;
  request.method = method;
  request.headers = {Authorization: token};
  return request;
}

export {APIPREFIX, constructRequest};
