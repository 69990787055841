<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <div class="portal">
    <aside class="portal__sidebar">
        <ul class="portal__sidebar--nav">
            <li class="nav-item" v-for="(item, index) in sidebarNav" :key="index">
                <div class="nav-item__section hover-select" :class="{activeNav: item.isSelected}" >
                    <span v-html='item.icon'></span>
                    <div class="nav-item__section--name" @click="portalNav(index)">
                        <span> {{ item.name }} </span>
                        <div v-if="(tagList.length > 0 && item.name == 'Tags') || (folders.length > 0 && item.name == 'Folders')">
                            <span key="fas fa-caret-up" v-if="item.isSelected"><i class="fas fa-caret-up"></i></span>
                            <span key="fas fa-caret-down" v-else ><i class="fas fa-caret-down"></i></span>
                        </div>
                    </div>
                    <div class="actions">
                        <div class="add-folder" v-if="(folders.length >= 0 && item.name == 'Folders')" @click="openInput(item.name)">
                            <i class="fas fa-plus"></i>
                        </div>
                    </div>
                </div>
                <ul class="folder-list" v-if="tagList.length > 0 && item.name == 'Tags' && item.isSelected">
                    <li class="folder-list__item" v-for="(tag, idx) in tagList" :key="idx">
                        <div class="folder-list__item--section hover-select" :class="{activeNav: tag.isSelected}" @click="folderNav(item.name, tagList, idx);">
                            <div class="name">
                                <i class="fas fa-hashtag"></i>
                                <span> {{ tag.folder }}</span>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="folder-list" v-if="folders.length > 0 && item.name == 'Folders' && item.isSelected">
                    <li class="folder-list__item" v-for="(folder, idx) in folders" :key="idx">
                        <div class="folder-list__item--section hover-select" :class="{activeNav: folder.isSelected}">
                            <div class="name" @click="folderNav(item.name, folders, idx)">
                                <i class="fas fa-folder"></i>
                                <span> {{ folder.name }}</span>
                            </div>
                            <div class="remove-folder" @click="removeFolder(folder)">
                                <i class="fas fa-trash"></i>
                            </div>
                        </div>
                    </li>
                </ul>
                <div v-if="showInput && item.name == 'Folders'" class="folder-item">
                    <input class="folder-item__input" type="text" placeholder="Folder Name" v-model="newFolder" @keyup.enter="addFolder(item.name)"/>
                    <div class="folder-item__actions">
                        <button class="folder-item__actions--add" @click="addFolder(item)">Add</button>
                        <button @click="cancelFolder()" class="folder-item__actions--cancel">Cancel</button>
                    </div>
                </div>
                <ul class="folder-list" v-if="orgs.length > 0 && item.name == 'Organizations'">
                    <li class="folder-list__item" v-for="(org, idx) in orgs" :key="idx">
                        <div class="folder-list__item--section hover-select" :class="{activeNav: org.isSelected}" @click="folderNav(item.name, orgs, idx);">
                            <div class="name">
                                <i class="fas fa-building"></i>
                                <router-link :to="{ name: 'OrgPortal', params: { org: org.name } }"> {{ org.name }}</router-link>
                            </div>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </aside>
    <main class="portal__main">
        <section class="info-bar">
            <h1 class="info-bar__title">{{ title }} <span v-if="subtitle"> - {{ subtitle }}</span></h1>
            <div class="info-bar__search">
                <i class="fas fa-search"></i>
                <input type="text" placeholder="Search" v-model="searchText" />
                <button @click="create_narrative" class="info-bar__button">New Narrative <i class="fas fa-plus"></i></button>
            </div>
        </section>
        <section class="scroll-wrapper" >
            <narrative-card
                :narrative="narrative"
                @delete="delete_narrative"
                @go="edit_narrative"
                @edit="edit_info"
                @view="view_narrative"
                v-for="(narrative, index) in filterNarratives" :key="index" >
            </narrative-card>
        </section>
        <modal :show="showModal" @close="showModal = false">
            <slot name="body">
                <narrative-settings-form
                    title="Narrative Settings"
                    description="Change the Settings for your Narrative."
                    submit-button-text="Submit New Settings"
                    :loading="loading"
                    :username="username"
                    :narrative="chosenNarrative"
                    :isNewNarrative="false"
                    @save="save_settings"
                    @cancel="cancel">
                </narrative-settings-form>
            </slot>
        </modal>
    </main>
  </div>
</template>

<script>

import NarrativeCard from "@/views/Portal/NarrativeCard.vue";
import Modal from '@/views/Narrator/modal.vue';
import NarrativeSettingsForm from '@/components/NarrativeSettingsForm.vue';

import { user } from "@/scripts/Authentication.js";

export default {
  components: { NarrativeCard, Modal, NarrativeSettingsForm},

  props: [],

  mounted(){

    console.log("Current User is:", user);
    this.load_narratives()
  },
  data() {
    return {
        title: "Get Inspired",
        subtitle: "",
        showInput: false,
        showModal: false,
        loading: false,
        chosenNarrative: {name:"", description:"", tags:"", privacySetting:"public", backgrounds:[], tiles:[], thumbnail:null},
        sidebarNav: [
            {
                name: "Get Inspired",
                icon: '<i class="fas fa-lightbulb"></i>',
                isSelected: true
            },
            /*{
                name: "Browse Templates",
                icon: '<i class="fas fa-columns"></i>',
                isSelected: false
            },*/
            {
                name: "All Narratives",
                icon: '<i class="fas fa-tasks"></i>',
                isSelected: false,
            },
            {
                name: "Tags",
                icon: '<i class="fas fa-tags"></i>',
                isSelected: false
            },
            /*{
                name: "Folders",
                icon: '<i class="fas fa-archive"></i>',
                isSelected: false
            },
            {
                name: "Organizations",
                icon: '<i class="fas fa-city"></i>',
                isSelected: false
            },
            {
                name: "My Assets & Media",
                icon: '<i class="fas fa-images"></i>',
                isSelected: false
            }*/
        ],
        newFolder: "",
        folders: [],
        orgs: [],
        // tagList: [],
        searchText: '',
        narratives: [],
        navFilter: "",
        tagSearch: ""

    };
  },
  watch:{
    user:function(){
      let user = user;

    }
  },
  methods: {
    cancel: function() {
        this.showModal = false;
    },
    create_narrative: function(){
      this.$router.push({name:"Narrator"})
    },
    save_settings: async function(narrative){
        this.showModal = false;
        let index = this.narratives.indexOf(this.chosenNarrative)
        this.$set(this.narratives, index, narrative);
        console.log(narrative);

        let user_id = $data.user.username;

        let ret = await $samsa.save_narrative(narrative);

        let folders = await $samsa.get_folders(user_id);

        this.folders = folders;
    },
    load_narratives: async function(){
        let user_id = $data.user.username;

        let ret = await $samsa.load_narratives(user_id);

        this.narratives = ret;

        let folders = await $samsa.get_folders(user_id);

        this.folders = folders;
    },

    edit_narrative: function(narrative){
      this.$router.push({name:"EditNarrator","params":{narrativeid:narrative.narrative_id}})
    },
    view_narrative: function(narrative){
      console.log("Viewing:", narrative)
      this.$router.push({name:"View","params":{narrativeid:narrative.narrative_id}})
    },

    delete_narrative: function(narrative){

      const index = this.narratives.indexOf(narrative);
      if (index > -1) {
        this.narratives.splice(index, 1);
      }
      $samsa.delete_narrative(narrative);
    },
    edit_info: function(narrative) {
        console.log(narrative);
        this.chosenNarrative = narrative;
        this.showModal = true;

    },
    portalNav: function(index) {
    console.log(index);
        this.sidebarNav = this.sidebarNav.map((navItem, idx) =>  {
            if(index === idx) {
                if(navItem.name == "All Narratives") {
                    this.navFilter = "";
                    this.searchText = "";
                    this.title = navItem.name
                    this.subtitle = "";
                } else if(navItem.name == "Tags" || navItem.name == "Folders") {

                    navItem.isSelected = !navItem.isSelected;
                    this.navFilter = "";
                    this.searchText = "";
                    this.title = "";
                    this.subtitle = "";

                } else {
                    navItem.isSelected = !navItem.isSelected;
                    this.title = navItem.name;
                    this.subtitle = "";
                }
            }else {
                navItem.isSelected = false;
            }
            return navItem;
        });
    },
    folderNav: function(navItem, list, index) {

        list = list.map((folder, idx) =>  {
            if(index === idx) {
                folder.isSelected = !folder.isSelected;
                this.subtitle = folder.folder;
                this.title = navItem;
                this.navFilter = folder.folder;

            }else {
                folder.isSelected = false;
            }

            return folder
        });
    },

    openInput: function() {
        this.showInput = true;
    },

    addFolder: async function(item) {
        let owner_id = this.$store.state.users.user.username;
        let ret = await $samsa.create_folder(this.newFolder, owner_id);
        item.isSelected = true;
        this.newFolder = "";
        this.showInput = false;
        let folders = await $samsa.get_folders(owner_id);

        this.folders = folders;
    },

    cancelFolder: function() {
        this.newFolder = "";
        this.showInput = false;
    },

    removeFolder: async function(folder){
      console.log(folder);
      const index = this.narratives.indexOf(folder);
      console.log(index);
      if (index > -1) {
        this.folders.splice(index, 1);
      }

      $samsa.delete_folder(folder);
      let owner_id = this.$store.state.users.user.username;
      let folders = await $samsa.getFolders(owner_id);

        this.folders = folders;
    }
  },
  computed: {
    tagList: function () {
        var taglist = []
        const {narratives} = this;
        this.narratives.forEach(item => {
        item.tags = item.tags.split(" ").join("");
        let tag = item.tags.split(',');

        taglist = taglist.concat(tag);
        });

        taglist = [...new Set(taglist)]
        taglist= taglist.filter(nav=> nav);

        let list = [];

        taglist.forEach((group) => {
            list.push({folder: group, isSelected: false});
        });

        return list

    },
    username:function(){
      return $data.user.username;
    },
    filterNarratives: function() {
        const {narratives, navFilter, searchText} = this;
        let folder = narratives.filter(({ tags }) => tags.toLowerCase().includes(navFilter.toLowerCase()));
        let search = narratives.filter(({ name }) => name.toLowerCase().includes(searchText.toLowerCase()));

        if(navFilter == "") {
            return search
        }else if(searchText == "") {
            return folder
        } else {
            return search.toLowerCase().filter(value => folder.toLowerCase().includes(value.toLowerCase()));
        }

    },

  },

};
</script>

<style lang="scss">

    .portal {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex: 1 0 auto;
        height: 100%;
        overflow: hidden;

        &__sidebar {
            width: 18%;
            border-right: 2px solid $blue;
            padding: 15px;

            &--nav {
                width: 100%;
                padding-left: 30px;
                display: flex;
                flex-direction: column;

                .nav-item {
                    font-size: 1.2em;
                    font-weight: 600;
                    color: $green;

                    &__section {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        &--name {
                            padding-left: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 80%;

                        }

                        .actions {
                            display: flex;
                            align-items: center;
                            width: 10%;

                            .add-folder {
                                color: $blue;

                                &:hover {
                                    color: darken($blue, 10%);
                                    cursor: pointer;
                                    transition: all .2s;
                                }
                            }

                        }
                    }

                    .folder-list {
                        font-size: 1em;
                        font-weight: 600;
                        padding-left: 25px;
                        color: #4B4F58;

                        &__item {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;

                            &--section {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;

                                .remove-folder {
                                    color: $red;

                                    &:hover {
                                        color: lighten($red, 10%);
                                        cursor: pointer;
                                        transition: all .2s;
                                    }
                                }
                            }
                        }
                    }

                    .folder-item {

                        &__actions {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            font-size: .8em;
                            margin-top: 10px;
                            &--add {
                                margin-right: 10px;
                                color: $white;
                                background: $green;
                                border: 1px solid $green;
                                padding: 0px 5px;
                                border-radius: 2px;

                                &:hover {
                                    background: darken($green, 10%);
                                    cursor: pointer;
                                    transition: all .2s;
                                }
                            }

                            &--cancel {
                                color: $red;
                                text-decoration: underline;

                                &:hover {
                                    color: lighten($red, 10%);
                                    cursor: pointer;
                                    transition: all .2s;
                                }
                            }
                        }

                        &__input {
                            margin-top: 5px;
                        }
                    }
                }
            }
        }

        .hover-select {
            padding: 10px 10px 3px 10px;
            border-bottom: 1px solid transparent;
            &:hover {
                border-bottom: 2px solid $blue;
                cursor: pointer;
            }
        }

        .activeNav {
            border-bottom: 2px solid $green;
        }

        &__main {
            width: 82%;
            padding: 25px 100px 25px 25px;
            height: 100%;
            overflow: hidden;

            .info-bar {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                justify-content: space-between;

                &__title {
                    font-size: 1.5em;
                    font-weight: 700;
                    color: $blue;
                }

                &__button {
                    background: $green;
                    color: #ffffff;
                    padding: 10px 20px;
                    border-radius: 25px;
                }

                &__search {

                    .fa-search {
                        font-size: 24px;
                        color: #4B4F58;
                    }
                    input {
                        width: 270px;
                        margin: 0px 10px 0px 5px;
                    }
                }
            }

            .scroll-wrapper {
                overflow: auto;
                height: 98%;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
            }
        }
    }

</style>
