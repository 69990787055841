<template>
    <button class="submit-button"><slot></slot></button>
</template>

<script>


export default {
  components: {

  },
  props: [],

  data() {
    return {}
  },

  watch: {

  },

  methods: {

  },

  computed: {

  },

  mounted() {

  },
};

</script>

<style scoped lang="scss">
.submit-button {
  background: $blue;
  color: $white;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  font-size: 1.1em;
}
</style>
