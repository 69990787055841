<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
  <main class="max-w-lg mx-auto ml-12 pt-4 pb-12 px-4 lg:pb-16">
    <form v-on:submit.prevent="createDataset">
      <div class="space-y-6">
        <div class="space-y-1">
          <h1 class="text-lg leading-6 font-medium text-gray-900">
            Dataset details
          </h1>
          <p class="text-sm leading-5 text-gray-500">
            Let’s get started by filling in the information below to create your
            new dataset.
          </p>
        </div>

        <div class="space-y-1">
          <label
            for="dataset_name"
            class="block text-sm font-medium leading-5 text-gray-700"
          >
            Name
            <div
              class="inline-block"
              v-if="isValidDatasetName && dataset.name.length > 2"
            >
              <span class="tex-gray-500">
                —
              </span>
              <span class="text-blue-600">
                {{ cleanedName }}
              </span>
              <span class="text-gray-500">
                is available.
              </span>
            </div>
            <span
              v-if="!isValidDatasetName && dataset.name.length > 0"
              class="text-red-500"
              >This dataset name is not available.</span
            >
          </label>

          <div class="rounded-md shadow-sm">
            <input
              id="datasetName"
              v-model="dataset.name"
              class="form-input block w-full transition duration-150
              ease-in-out sm:text-sm sm:leading-5"
              spellcheck="false"
              value=""
            />
          </div>
        </div>

        <div class="space-y-1">
          <label
            for="description"
            class="block text-sm font-medium leading-5 text-gray-700"
          >
            Description
          </label>
          <div class="rounded-md shadow-sm">
            <textarea
              id="description"
              v-model="dataset.description"
              rows="3"
              class="form-textarea block w-full transition duration-150
              ease-in-out sm:text-sm sm:leading-5"
            ></textarea>
          </div>
        </div>

        <fieldset>
          <legend class="text-sm leading-5 font-medium text-gray-900">
            Type
          </legend>

          <div class="mt-1 bg-white rounded-md shadow-sm -space-y-px">
            <!-- On: "bg-light-blue-50 border-light-blue-200 z-10", Off: "border-gray-200" -->
            <div class="relative border rounded-tl-md rounded-tr-md p-4 flex">
              <div class="flex items-center h-5">
                <input
                  id="settings-option-0"
                  value="FILE"
                  v-model="dataset.datasetType"
                  name="datasetType"
                  type="radio"
                  class="form-radio h-4 w-4 text-light-blue-600 transition
                  duration-150 ease-in-out cursor-pointer"
                  checked
                />
              </div>
              <label
                for="settings-option-0"
                class="ml-3 flex flex-col cursor-pointer"
              >
                <!-- On: "text-light-blue-900", Off: "text-gray-900" -->
                <span class="block text-sm leading-5 font-medium">
                  File-based
                </span>
                <!-- On: "text-light-blue-700", Off: "text-gray-500" -->
                <span class="block text-sm leading-5">
                  Upload data files directly to the platform.
                </span>
              </label>
            </div>

            <!-- On: "bg-light-blue-50 border-light-blue-200 z-10", Off: "border-gray-200" -->
            <div class="relative border border-gray-200 p-4 flex">
              <div class="flex items-center h-5">
                <input
                  id="settings-option-1"
                  v-model="dataset.datasetType"
                  value="SENSOR"
                  type="radio"
                  class="form-radio h-4 w-4 text-light-blue-600 transition
                  duration-150 ease-in-out cursor-pointer"
                />
              </div>
              <label
                for="settings-option-1"
                class="ml-3 flex flex-col cursor-pointer"
              >
                <!-- On: "text-light-blue-900", Off: "text-gray-900" -->
                <span class="block text-sm leading-5 font-medium">
                  Sensor-based
                </span>
                <!-- On: "text-light-blue-700", Off: "text-gray-500" -->
                <span class="block text-sm leading-5">
                  Stream data continuously to the platform from a sensor or an
                  IOT device.
                </span>
              </label>
            </div>

            <!-- On: "bg-light-blue-50 border-light-blue-200 z-10", Off: "border-gray-200" -->
            <div
              class="relative border border-gray-200 rounded-bl-md rounded-br-md p-4 flex"
            >
              <div class="flex items-center h-5">
                <input
                  id="settings-option-2"
                  value="API"
                  v-model="dataset.datasetType"
                  type="radio"
                  class="form-radio h-4 w-4 text-light-blue-600 transition
                  duration-150 ease-in-out cursor-pointer"
                />
              </div>
              <label
                for="settings-option-2"
                class="ml-3 flex flex-col cursor-pointer"
              >
                <!-- On: "text-light-blue-900", Off: "text-gray-900" -->
                <span class="block text-sm leading-5 font-medium">
                  API-based
                </span>
                <!-- On: "text-light-blue-700", Off: "text-gray-500" -->
                <span class="block text-sm leading-5">
                  Consume data from a 3rd party API.
                </span>
              </label>
            </div>
          </div>
        </fieldset>

        <div class="flex justify-end">
          <span class="ml-3 inline-flex rounded-md shadow-sm">
            <button
              type="submit"
              class="inline-flex justify-center py-2 px-4 border
                border-transparent text-sm leading-5 font-medium rounded-md
                text-white bg-light-blue-500 hover:bg-light-blue-400
                focus:outline-none focus:border-light-blue-600
                focus:shadow-outline-blue active:bg-light-blue-600 transition
                duration-150 ease-in-out"
            >
              Continue
            </button>
          </span>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
// import Component from "../component_location"
import { cleanName, debounce, EventBus } from "@/utils.js";

export default {
  components: {},

  props: ["dataset", "repo"],

  data() {
    return {};
  },

  watch: {},

  methods: {

    complete() {
      this.$emit('created')
    },

    createDataset() {
      if (this.isValidDatasetName) {
        let details = {
          repoId: this.repo.Oid,
          dataset: this.dataset,
        };
        this.$store.dispatch("datasets/create", details);
        // this.$samsa
        //   .createDataset(this.repo.Oid, this.dataset)
        //   .then((res) => {
        //     this.$store.commit("setDataset", res.data);
        //     this.$emit("created");
        //   })
        //   .catch((err) => {
        //     EventBus.$emit("error", err.request.response);
        //   });
      }
    },
  },

  computed: {
    isValidDatasetName() {
      return this.$store.state.datasets.validName;
    },

    cleanedName() {
      return cleanName(this.dataset.name);
    },
  },

  mounted() {
    // Hooks to check the dataset name.
    var validateName = debounce(() => {
      this.dataset.name = cleanName(this.dataset.name);
      let details = { repoId: this.repo.Oid, datasetName: this.dataset.name };
      this.$store.dispatch("datasets/checkName", details);
    }, 1000);
    const datasetName = document.querySelector("#datasetName");
    datasetName.addEventListener("keyup", validateName);
    validateName();

    // Listen to EventBus for dataset-created event.
    EventBus.$on("dataset-created", this.complete);
  },
};
</script>

<style>
.panel {
  @apply relative border rounded-tl-md rounded-tr-md p-4 flex;
}
.activepanel {
  @apply bg-light-blue-50 border-light-blue-200 z-10;
}
</style>
