<template>
  <div>
    <div class="input-container" v-for="(label, index) in labels" :key="index">
      <input
        :id="id + index"
        type="checkbox"
        class="input-container__checkbox"
        :name="name"
        :checked="isChecked(label.value)"
        @input="updateText($event, label.value)"
      />
      <label :for="id + index" class="input-container__label">
        <span>{{ label.label }}</span>

        <i class="fas fa-check-square"></i>
      </label>
    </div>
  </div>
</template>

<script>


export default {
  components: {

  },
  props: {"labels":{default: []}, "name":{default: ""}, "id":{default: ""}, "value":{default: []}},

  data() {
    return{
    checkedValue:[]
    }
  },

  watch: {

  },

  methods: {
    isChecked(value){
      console.log("Checking: ", value)
      return this.checkedValue.indexOf(value) >= 0;
    },
    updateText(evt, value) {
      console.log("CHECKBOX", evt.target.checked);
      if (evt.target.checked) {
        this.checkedValue.push(value);
      } else {
        const index = this.checkedValue.indexOf(value);
        if (index > -1) {
          this.checkedValue.splice(index, 1);
        }
      }
       this.$emit('input', this.checkedValue);

    }
  },

  computed: {

  },

  mounted() {
    this.checkedValue =  this.value.slice(0);
    console.log(this.checkedValue)
  },
};

</script>

<style scoped lang="scss">
.input-container {

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $greyFont;
    border: 1px solid;
    border-color: #ccc #e0e0e0 #e0e0e0 #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 0;
  }

  &__checkbox {
    position: fixed;
    opacity: 0;
    width: 0;
  }

}

.input-container__checkbox:checked + .input-container__label {
  background: $blue;
  color: $white;
  border-color: lighten($blue, 25%);
}

</style>
