// Copyright 2019-2021 Michigan Aerospace Corporation
// All Rights Reserved
// Author: Chad Lewis, David Vasseliou, Leslie Borst

import Vue from "vue";

console.log("AUTHENTICATION.JS")

async function verifyAuthenticated(context) {
  console.log("VERIFY AUTHENTICATION")
  // Verify that the user is authenticated using the token.
  let user = $data.user;
  try{
    let res = null;
    let token = null;

    if(user != null){
      token = user.token
    }
    else
      token = localStorage.getItem("springmatter-token");

    if(token != null)
      res = await $samsa.verify_authenticated(token);

    if(res){
      let user = res;
      if(user != null){
        $data.$set($data,"user",user)
        localStorage.setItem("springmatter-token", user.token);
      }
    }
  }
  catch(e){
    console.log("Not Auth:", e)
  }
  return user;
}

async function signIn(loginInfo) {
  let res = await $samsa.sign_in(loginInfo.email, loginInfo.password)
  if(res){
    let user = res;
    $data.$set($data,"user",user)
    localStorage.setItem("springmatter-token", user.token);
  }
  else{
    user = null
    localStorage.setItem("springmatter-token", "");
  }
  return res;
}



function signOut(state) {
  // Sign user out of the app; remove token from local storage
  $data.$set($data,"user",null)
  localStorage.setItem("springmatter-token","");

}

async function signUp(user) {
    let res = await $samsa.create_user(user)
    user = res;
    console.log("UserToken:" , user.token)
    localStorage.setItem("springmatter-token", user.token);
}

async function saveUser(user) {
  let res = await $samsa.save_user(user)
  if(res){
    user = res;
  }
}


export {signIn, signUp, signOut, saveUser, verifyAuthenticated};
