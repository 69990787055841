//Copyright 2019-2021 Michigan Aerospace Corporation
//All Rights Reserved
//Author: Chad Lewis, David Vasseliou, Leslie Borst

// Utility functions for grabbing data from a specified dataset — will grab all
// columns or whatever is in the descriptors array.
import { snakeToCamel } from "@/utils.js";

function getQuery(dataset, page, perPage) {
  // This returns a pretty-formatted GraphQL query...
  var query = `{\n\tselect(page: ${page}, perPage: ${perPage})`;
  query += "\n\t\t{";
  dataset.descriptors.forEach((desc) => {
    query += "\n\t\t\t" + `${snakeToCamel(desc.name.toLowerCase())}`;
  });
  query += "\n\t\t}\n}";
  return query;
}

function getData(dataset, page, perPage) {
  var query = getQuery(dataset, page, perPage);
  var data = { query: query };
  var url = dataset.apiUrl;
  var q = { url: url, method: "post", data: data };
  return axios(q); // returns a promise :)
}

export { getQuery, getData };
