
var _audio_library = {}
var _currently_playing = null

//this.audio = new Audio(this.content.url);
//this.audio.loop = true;
//this.audio.addEventListener("ended", this.ended, false);

function ended(){
  console.log("Done!")
  _currently_playing = null;
}

function load(url){
  if(_audio_library[url] == null){
    console.log("Loaded", url)
    _audio_library[url] = new Audio(url);
    _audio_library[url].loop = true
    _audio_library[url].addEventListener("ended", ended, false);
  }
  else{
    console.log("Already Loaded", url)
  }

}

function play(url){
  if(_audio_library[url] != null){
    if(_audio_library[url] == _currently_playing)
      return

    if(_currently_playing!=null){
      _currently_playing.pause()
      _currently_playing = null
    }


    _currently_playing = _audio_library[url]
    _currently_playing.play()
    if(!_currently_playing.paused){
      console.log("PLAYING!")
    }
    else {
      console.log("NOT PLAYING!")
      _currently_playing = null
    }


  }
}


export {play, load};
