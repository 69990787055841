<template>
  <component
    :is="tag"
    :type="type"
    v-on="$listeners"
    :disabled="disabled || loading"
    :class="[btnClass, colorVariants]"
    :variant="variant"
    :variant-type="variantType"
    :size="size"
    :href="to"
  >
    <svg v-if="loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    <slot />
  </component>

</template>

<script>
  export default {
    props: {
      tag: {
        type: String,
        default: "button"
      },
      variant: {
        type: String,
        default: "primary"
      },
      variantType: {
        type: String,
        default: "normal"
      },
      size: {
        type: String,
        default: "small"
      },
      rounded: {
        type: String,
        default: "medium"
      },
      type: {
        type: String,
        default: ""
      },
      to: {
        type: String
      },
      loading:{
        type: Boolean,
        default: false
      },
      disabled:{
        type: Boolean,
        default: false
      }
    },

    computed: {
      btnClass() {
        const disabled = this.disabled || this.loading
        return {
          "cursor-not-allowed": disabled,
          "text-sm base-button inline-flex align-middle align-items-center justify-center font-medium focus:outline-none border-2 px-4 py-2": true,
          "rounded-lg": this.rounded === "medium",
          "rounded-full": this.rounded === "large",
          "px-6 py-3": this.size == "normal",
          "px-4 py-2": this.size == "small"
        };
      },

      colorVariants() {
        const disabled = this.disabled || this.loading;
        switch (this.variant) {
          case "primary":
              switch (this.variantType) {
                  case "normal":
                      switch (disabled) {
                          case true:
                            return "border-blue-300 bg-blue-300 text-white";
                          default:
                            break;
                      }
                      return "border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white";
                  case "outline":
                      return "border-gray-200 text-blue-500 hover:text-blue-700";
                  default:
                      break;
              }
              break;
            case "danger":
              switch (this.variantType) {
                case "normal":
                    switch (disabled) {
                        case true:
                            return "border-red-300 bg-red-300 text-white";
                        default:
                            break;
                    }
                    return "border-red-600 bg-red-600 hover:bg-red-700 hover:border-red-700 text-white";
                    break;
                case "outline":
                    return "border-gray-200 text-red-500 hover:text-red-600";
                default:
                    break;
              }
              break;

            case "warning":
              switch (this.variantType) {
                case "normal":
                  return "border-orange-600 bg-orange-600 hover:bg-orange-700 hover:border-orange-700 text-white";
                default:
                    break;
              }
              break;

            case "success":
              switch (this.variantType) {
                case "normal":
                  switch (disabled) {
                    case true:
                      return "border-green-300 bg-green-300 text-white";
                    default:
                        break;
                  }
                  return "border-green-600 bg-green-600 hover:bg-green-700 hover:border-green-700 text-white";
                case "outline":
                  return "border-2 border-gray-200 text-green-500 hover:text-green-700";
                default:
                  break;
              }
              break;
            case "secondary":
                switch (this.variantType) {
                  case "normal":
                    return "border border-gray-300 text-gray-500 text-gray-700 shadow-sm hover:text-gray-500";
                  default:
                    break;
                }
              break;

            default:
              break;
        }
      }
    }
};
</script>

<style>

</style>
