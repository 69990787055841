<!--
Copyright 2019-2021 Michigan Aerospace Corporation
All Rights Reserved
Author: Chad Lewis, David Vasseliou, Leslie Borst
-->
<template>
    <div class="flex flex-col earth-background" id = "blurry">

        <div class="content about main faq">
          <transition name="fade" appear>
            <div class="header-section">
                <h1>Frequently Asked Questions</h1>
            </div>
          </transition>
          <section>
              <ul class="faq-list">
                  <li v-for="(faq, index) in faqs" :key="index" class="faq-list__item" :class="faq.open ? 'is-open' : 'is-closed'">
                      <h3 class="faq-list__item--header" @click="toggleAccordion(index)" >{{ faq.question }}</h3>
                      <p class="faq-list__item--answers">
                          {{ faq.answer }}
                      </p>
                  </li>
              </ul>
          </section>
        </div>
    </div>

</template>

<script>
// import Component from "../component_location"
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavbarDash from "@/components/NavbarDash.vue";

export default {
    components: { Breadcrumbs, NavbarDash },

    props: [],

    data() {
        return {

            faqs: [
                {
                    question: "Question one?",
                    answer: "Aenean condimentum bibendum felis nec lobortis. Proin at magna egestas, tempus risus eget, efficitur risus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent at neque ut libero condimentum tristique.",
                    open: false
                },
                {
                    question: "Question two?",
                    answer: "Proin ex nunc, convallis nec mi quis, vulputate ornare nibh. Ut ullamcorper ultrices ante nec dapibus.",
                    open: false
                },
                {
                    question: "Question three?",
                    answer: "Aenean condimentum bibendum felis nec lobortis. Proin at magna egestas, tempus risus eget, efficitur risus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent at neque ut libero condimentum tristique.",
                    open: false
                },
                {
                    question: "Question four?",
                    answer: "Proin et dolor rutrum, porttitor nunc a, auctor justo. Mauris porttitor fermentum magna ut dignissim. Vestibulum magna erat, finibus sit amet fermentum quis, sollicitudin vel velit. Morbi ac facilisis eros. In at ante dignissim, ultricies leo sodales, posuere mi.",
                    open: false
                },
                {
                    question: "Question five?",
                    answer: "Proin ex nunc, convallis nec mi quis, vulputate ornare nibh. Ut ullamcorper ultrices ante nec dapibus.",
                    open: false
                }

            ]
        };
    },

    watch: {},

    methods: {
        toggleAccordion: function(index) {

            this.faqs = this.faqs.map((faq, item) =>  {
                console.log(item);

                if(index === item) {
                    faq.open = !faq.open;
                }else {
                    faq.open = false;
                }
                return faq;
            });

        }
    },

    computed: {

    },

    mounted() {},
};

$( document ).ready(function() {


});

</script>

<style lang="scss">

    .faq-list {
        width: 80%;
        margin: 25px auto;

        &__item {
            padding: 5px 5px 10px 5px;

            &--header {
                font-weight: 600;
                background: #47aa42;
                color: #ffffff;
                width: 100%;
                padding: 5px;
                margin-bottom: 10px;

                &:hover {
                    cursor: pointer;
                    background: #378433;
                    transition: 0.2s all;
                }
            }

            &--answers {
                max-height: 100px;
                overflow: hidden;
                transition: 0.5s ease all;
            }
        }

    }

    .is-closed .faq-list__item--answers {
        max-height: 0;
    }

</style>
